import { ConfiguracionApiActivosFijos } from "./ConfiguracionApiActivosFijos"

export const GrupoContableApi =
{
    getGrupoContable:function()
    {
        return ConfiguracionApiActivosFijos.get("grupoContable")
    },
    getGrupoContableId:function(id: number)
    {
        return ConfiguracionApiActivosFijos.get(`grupoContable/${id}`)
    },
    createGrupoContable: function(data: any){
        return ConfiguracionApiActivosFijos.post("grupoContable", data);
    },
    updateGrupoContable: function(id: number, data: any){
        return ConfiguracionApiActivosFijos.put(`grupoContable/${id}`, data);
    },
    deleteGrupoContable: function(id: number){
        return ConfiguracionApiActivosFijos.delete(`grupoContable/${id}`);
    }
}