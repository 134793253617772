import BaseMagaya from "./basedatosmagaya/BaseMagaya"
import CuentaContableMagaya from "./cuentacontablemagaya/CuentaContableMagaya"
import EstadoIntegracionFactura from "./estadointegracionfactura/EstadoIntegracionFactura"
import EstadoIntegracionPrefactura from "./estadointegracionprefactura/EstadoIntegracionPrefactura"
import EstadoIntegracionProyecto from "./estadointegracionproyecto/EstadoIntegracionProyecto"
import GrupoServicioContable from "./gruposerviciocontable/GrupoServicioContable"
import Plataforma from "./plataforma/Plataforma"
import Sistema from "./sistema/Sistema"
import TipoCuentaMagaya from "./tipocuentamagaya/TipoCuentaMagaya"

export const CatalogoIntegrador = () => {
  return (
    <section className="content">
    <div className="container-fluid">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Catálagos de Integrador</h3>
          <div className="card-tools">
          </div>
        </div>
        <div className="card-body">
          <div className="p-tabview p-component">
            <div className=".p-tabview-nav p-0 pt-1">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-one-tab"
                role="tablist"
              >
                <li className="li.p-highlight">
                  <a
                    className="nav-link active"
                    id="custom-tabs-one-sistema-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-sistema"
                    role="tab"
                    aria-controls="custom-tabs-one-sistema"
                    aria-selected="true"
                    style={{color:'#101D61'}}
                  >
                    Sistema
                  </a>
                </li>
                <li className="li.p-highlight">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-plataforma-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-plataforma"
                    role="tab"
                    aria-controls="custom-tabs-one-plataforma"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Plataforma
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-estadointegracionproyecto-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-estadointegracionproyecto"
                    role="tab"
                    aria-controls="custom-tabs-one-estadointegracionproyecto"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Estado Integración Proyecto
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-estadointegracionprefactura-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-estadointegracionprefactura"
                    role="tab"
                    aria-controls="custom-tabs-one-estadointegracionprefactura"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Estado Integración Pre-factura
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-tipocuenta-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-tipocuenta"
                    role="tab"
                    aria-controls="custom-tabs-one-tipocuenta"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Tipo de cuenta magaya
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-basedatos-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-basedatos"
                    role="tab"
                    aria-controls="custom-tabs-one-basedatos"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Base de datos magaya
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-gruposerviciocontable-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-gruposerviciocontable"
                    role="tab"
                    aria-controls="custom-tabs-one-gruposerviciocontable"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Grupo Servicio Contable
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-factura-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-factura"
                    role="tab"
                    aria-controls="custom-tabs-one-factura"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Estado Integración Factura
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-cuentacontablemagaya-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-cuentacontablemagaya"
                    role="tab"
                    aria-controls="custom-tabs-one-cuentacontablemagaya"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Cuenta Contable Magaya
                  </a>
                </li>

              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-one-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-one-tipocuenta"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-tipocuenta-tab"
                >
                  <TipoCuentaMagaya/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-sistema"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-sistema-tab"
                >
                  <Sistema/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-plataforma"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-plataforma-tab"
                >
                  <Plataforma/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-estadointegracionproyecto"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-estadointegracionproyecto-tab"
                >
                  <EstadoIntegracionProyecto/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-basedatos"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-basedatos-tab"
                >
                  <BaseMagaya/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-gruposerviciocontable"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-gruposerviciocontable-tab"
                >
                  <GrupoServicioContable/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-estadointegracionprefactura"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-estadointegracionprefactura-tab"
                >
                  <EstadoIntegracionPrefactura/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-factura"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-factura-tab"
                >
                 <EstadoIntegracionFactura/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-cuentacontablemagaya"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-cuentacontablemagaya-tab"
                >
                  <CuentaContableMagaya/>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
    </div>
  </section>
  )
}
