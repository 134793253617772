import { api } from "../integradorcontabilidad/ApiConfiguracionIntegradorContabilidad"

export const CabeceraApi = {
    getCabecera:function()
    {
        return api.get("/api/cabecera")
    },
    getCabeceraId:function(id: number)
    {
        return api.get(`/api/cabecera/${id}`)
    },
    createCabecera: function(data: any){
        return api.post("/api/cabecera", data);
    },
    updateCabecera: function(id: number, data: any){
        return api.put(`/api/cabecera/${id}`, data);
    },
    deleteCabecera: function(id: number){
        return api.delete(`/api/cabecera/${id}`);
    }
}