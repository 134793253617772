import { api } from "./ApiConfiguracionEstadisticas"
export const LineaNegocioApi =
{
    getLineaNegocio:function()
    {
        return api.get("lineadenegocio")
    },
    getLineaNegocioById:function(id: number)
    {
        return api.get(`lineadenegocio/${id}`)
    },
    createLineaNegocio: function(data: any){
        return api.post("lineadenegocio", data);
    },
    updateLineaNegocio: function(id: number, data: any){
        return api.put(`lineadenegocio/${id}`, data);
    },
    deleteLineaNegocio: function(id: number){
        return api.delete(`lineadenegocio/${id}`);
    }
}
