import { ConfiguracionApiActivosFijos } from "./ConfiguracionApiActivosFijos"

export const FamiliaActivoApi =
{
    getFamiliaActivo:function()
    {
        return ConfiguracionApiActivosFijos.get("familiaActivo")
    },
    getFamiliaActivoId:function(id: number)
    {
        return ConfiguracionApiActivosFijos.get(`familiaActivo/${id}`)
    },
    createFamiliaActivo: function(data: any){
        return ConfiguracionApiActivosFijos.post("familiaActivo", data);
    },
    updateFamiliaActivo: function(id: number, data: any){
        return ConfiguracionApiActivosFijos.put(`familiaActivo/${id}`, data);
    },
    deleteFamiliaActivo: function(id: number){
        return ConfiguracionApiActivosFijos.delete(`familiaActivo/${id}`);
    }
}