import React, { useEffect, useState } from 'react';
import FileUploader from '@app/pages/banca/documentos/FileUploader';
import ReporteBones from '@app/pages/banca/documentos/reportebones/ReporteBones';
import ReportePichincha from '@app/pages/banca/documentos/reportespichincha/ReportePichincha';
import ReporteProdubanco from '@app/pages/banca/documentos/reportesprodubanco/ReporteProdubanco';
import ReportePacifico from '@app/pages/banca/documentos/reportepacifico/ReportePacifico';




interface Tab {
  id: number;
  title: string;
  content: JSX.Element;
}

const TabComponent: React.FC = () => {
  
  const [tabs, setTabs] = useState<Tab[]>([
    { id: 1, title: 'Cargado de Archivos', content: <FileUploader /> },
    { id: 2, title: 'Informe final', content: <ReporteBones /> },
    { id: 3, title: 'Movimientos Pichincha', content: <ReportePichincha onDataChange={function (data: ReportePichincha): void {
      throw new Error('Function not implemented.');
    } } /> },
    {id: 4, title: 'Movimientos Produbanco', content: <ReporteProdubanco />},
    {id: 5, title: 'Movimientos Pacífico ', content: <ReportePacifico />},
  ]);
  const [activeTab, setActiveTab] = useState<number | null>(null);


  const handleTabClick = (tabId: number) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
    }
  };
  useEffect(() => {
    
    if (tabs.length > 0) {
      setActiveTab(tabs[0].id);
    }
  }, [tabs]);
  return (
    <div>
      <ul className="nav nav-tabs">
        {tabs.map((tab) => (
          <li
            className={`nav-item ${tab.id === activeTab ? 'active' : ''}`}
            key={tab.id}
            onClick={() => handleTabClick(tab.id)}
          >
            <a
              className="nav-link"
              data-toggle="tab"
              href={`#custom-tabs-one-tab-${tab.id}`}
              role="tab"
              aria-selected={tab.id === activeTab ? 'true' : 'false'}
              style={{ color: '#101D61' }}
            >
              {tab.title}
            </a>
          </li>
        ))}
      </ul>

      <div className="tab-content">
        {tabs.map((tab) => (
          <div
            className={`tab-pane fade ${
              tab.id === activeTab ? 'show active' : ''
            }`}
            id={`custom-tabs-one-tab-${tab.id}`}
            role="tabpanel"
            aria-labelledby={`custom-tabs-one-tab-${tab.id}`}
            key={tab.id}
            style={{  overflow:'auto' }}
          >
              {activeTab === tab.id ? tab.content : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabComponent;
