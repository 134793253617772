import React, {useState} from 'react';
import {Box, Button, styled} from '@material-ui/core';
import {
  DataGrid,
  GridValueGetterParams,
  GridColDef,
  GridRowId
} from '@material-ui/data-grid';
import Notification from './Notification';
import {ReporteProdubancoApi} from '@app/api/bancos/ReporteProdubancoApi';
import moment from 'moment';
import SaveIcon from '@mui/icons-material/Save';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {ReportePichinchaApi} from '@app/api/bancos/ReportePichinchaApi';
import {ReportePacificoApi} from '@app/api/bancos/ReportePacificoApi';
import {RegistroApi} from '@app/api/bancos/RegistroApi';
import swal from 'sweetalert';
import Swal from 'sweetalert2';


interface DataRow {
  [key: string]: string | number;
}
interface Registro {
  nombredearchivo: string;
}
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function FileUploader() {
  const [data, setData] = useState<DataRow[]>([]);
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [notification, setNotification] = useState<string>('');
  const [selectedFileName, setSelectedFileName] = useState('');
  const registroData: Registro = {
    nombredearchivo: selectedFileName
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = handleFileRead;
      reader.readAsText(file, 'UTF-8');
      setSelectedFileName(file.name);
    }
  };
  const localeText = {
    // Textos de la barra de herramientas (toolbar)
    toolbarDensity: 'Densidad',
    toolbarDensityLabel: 'Densidad',
    toolbarDensityCompact: 'Compacta',
    toolbarDensityStandard: 'Estándar',
    toolbarDensityComfortable: 'Confortable',
    toolbarFilters: 'Filtros',
    toolbarExport: 'Exportar',
    toolbarColumns: 'Columnas',
    toolbarColumnsLabel: 'Seleccionar columnas',
    toolbarFiltersLabel: 'Mostrar filtros',
    toolbarFiltersTooltipHide: 'Ocultar filtros',
    toolbarFiltersTooltipShow: 'Mostrar filtros',
    toolbarFiltersTooltipActive: (count: number) =>
      count > 1 ? `${count} filtros activos` : `${count} filtro activo`,
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Buscar…',
    toolbarQuickFilterLabel: 'Buscar',
    toolbarQuickFilterDeleteIconLabel: 'Limpiar',

    // Export selector toolbar button text
    toolbarExportLabel: 'Exportar',
    toolbarExportCSV: 'Descargar como CSV',
    toolbarExportPrint: 'Imprimir',
    toolbarExportExcel: 'Descargar como Excel',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Columna de búsqueda',
    columnsPanelTextFieldPlaceholder: 'Título de columna',
    columnsPanelDragIconLabel: 'Reordenar columna',
    columnsPanelShowAllButton: 'Mostrar todo',
    columnsPanelHideAllButton: 'Ocultar todo',

    // Filter panel text
    filterPanelAddFilter: 'Agregar filtro',
    filterPanelRemoveAll: 'Remover todos',
    filterPanelDeleteIconLabel: 'Borrar',
    filterPanelLogicOperator: 'Operador lógico',
    filterPanelOperator: 'Operadores',
    filterPanelOperatorAnd: 'Y',
    filterPanelOperatorOr: 'O',
    filterPanelColumns: 'Columnas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Valor de filtro',

    // Filter operators text
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'es igual',
    filterOperatorStartsWith: 'comienza con',
    filterOperatorEndsWith: 'termina con',
    filterOperatorIs: 'es',
    filterOperatorNot: 'no es',
    filterOperatorAfter: 'es posterior',
    filterOperatorOnOrAfter: 'es en o posterior',
    filterOperatorBefore: 'es anterior',
    filterOperatorOnOrBefore: 'es en o anterior',
    filterOperatorIsEmpty: 'está vacío',
    filterOperatorIsNotEmpty: 'no esta vacío',
    filterOperatorIsAnyOf: 'es cualquiera de'
  };
  const handleFileRead = (event: ProgressEvent<FileReader>) => {
    const content = event.target?.result as string;
    const header = content
      .slice(0, content.indexOf('\r\n'))
      .split(';')
      .map((column) => column.trim().replace(/[.\s]/gi, ''));
    const rows = content.slice(content.indexOf('\n') + 1).split('\n');
    if (!header) {
      swal('Advertencia: El archivo CSV está vacío.', '', 'info');
      setSelectedRows([]);
      return;
    }
    const columnMappings: {[key: string]: string} = {
      FECHA: 'fecha',
      REFERENCIA: 'referencia',
      REFERENCIA2: 'referencia2',
      DESCRIPCION: 'descripcion',
      '+/-': 'masmenos',
      SALDOCONTABLE: 'saldocontable',
      SALDODISPONIBLE: 'saldodisponible',
      VALOR: 'valor',
      OFICINA: 'oficina',
      CODTRANSACCION: 'codtransaccion',
      Fecha: 'fecha',
      Codigo: 'codigo',
      Concepto: 'concepto',
      Tipo: 'tipo',
      Documento: 'documento',
      Oficina: 'oficina',
      Monto: 'monto',
      Saldo: 'saldo',
      Estado: 'estado',
      Fechacontable: 'fechacontable',
      Lugar: 'lugar',
      Caja: 'caja',
      Nut: 'nut',
      Valor: 'valor',
      Numero: 'numero',
      CoNCepto: 'concepto',
      SaldoDespMov: 'saldodespmov',
      Descripcion: 'descripcion',
      FechaReal: 'fechareal'
    };
    const missingColumns = header.filter((column) => !columnMappings[column]);
    if (missingColumns.length > 0) {
      Swal.fire(
        'Advertencia:',
        `El archivo CSV no es correcto para subirlo.`,
        'warning'
      )
      return;
    }
    const modifiedHeader = header.map(
      (column) => columnMappings[column] || column
    );

    const parsedData: {[key: string]: any}[] = [];
    rows.forEach((row, index) => {
      if (row.trim() !== '') {
        const values = row.split(';');
        if (values.length === header.length) {
          const dataRow: {[key: string]: any} = {id: `${index + 1}`};
          modifiedHeader.forEach((column, columnIndex) => {
            dataRow[column] = values[columnIndex];
          });
          parsedData.push(dataRow);
        } else {
          Swal.fire({
            title: 'Advertencia',
            text: `La fila ${
              index + 1
            } tiene un número incorrecto de columnas.`,
            icon: 'error',
          })
        }
      }
    });
    setData(parsedData);
    //setSelectedRows([]);
    setSelectedColumns([]);
    const allRowIds = rows.map((_, index) => `${index + 1}`);
    setSelectedRows(allRowIds);
    Swal.fire({
      title: 'Archivo CSV cargado correctamente',
      text: '',
      icon: 'success',
    })
  };

  const processSelectedFile = () => {
    const fileMappings: {[key: string]: {[key: string]: string}} = {
      'log-produbanco-1110201.csv': {
        banco: 'Produbanco',
        empresa: 'Logistics',
        cuenta: '1110201',
        cuentaban: '02052019930'
      },
      'exp-produbanco-1110206.csv': {
        banco: 'Produbanco',
        empresa: 'Express',
        cuenta: '1110206',
        cuentaban: '02052010593'
      },
      'adu-produbanco-1110201.csv': {
        banco: 'Produbanco',
        empresa: 'Aduanas',
        cuenta: '1110201',
        cuentaban: '02005231600'
      },
      'tra-produbanco-1110201.csv': {
        banco: 'Produbanco',
        empresa: 'Trans',
        cuenta: '1110201',
        cuentaban:'02005216113'
      },
      'exp-pichincha-1110208.csv': {
        banco: 'Pichincha',
        empresa: 'Express',
        cuenta: '1110208',
        cuentaban : '3248532604'
      },
      'adu-pichincha-1110202.csv': {
        banco: 'Pichincha',
        empresa: 'Aduanas',
        cuenta: '1110202',
        cuentaban:'2100152806',
      },
      'log-pichincha-1110202.csv': {
        banco: 'Pichincha',
        empresa: 'Logistics',
        cuenta: '1110202',
        cuentaban :'3498536604'
      },
      'tra-pichincha-1110204.csv': {
        banco: 'Pichincha',
        empresa: 'Trans',
        cuenta: '1110204',
        cuentaban :'2100192738'
      },
      'exp-pacifico-1110209.csv': {
        banco: 'Pacífico',
        empresa: 'Express',
        cuenta: '1110209',
        cuentaban: '7790465'
      },
      'adu-pacifico-1110203.csv': {
        banco: 'Pacífico',
        empresa: 'Aduanas',
        cuenta: '1110203',
        cuentaban:  '7257031'
      },
       'box-pichincha-1110210.csv': {
        banco: 'Pichincha',
        empresa: 'Box-Express',
        cuenta: '1110210',
        cuentaban:  '2100299644'
      }
    };
    const fileMapping = fileMappings[selectedFileName];
    if (fileMapping) {
      return (params: any) => {
        if (params.colDef.field === 'banco') {
          return fileMapping.banco;
        } else if (params.colDef.field === 'empresa') {
          return fileMapping.empresa;
        } else if (params.colDef.field === 'cuenta') {
          return fileMapping.cuenta;
        }else if (params.colDef.field === 'cuentaban') {
          return fileMapping.cuentaban;
        }
      };
    } else {
      return (params: any) => {
        return params.row['banco'];
      };
    }
  };

  const handleRowSelection = (params: GridRowId[]) => {
    setSelectedRows(params);
  };

  const handleDataInsertionReportProdu = () => {
    const selectedData = data
      .filter((row) => selectedRows.includes(row.id))
      .map((row) => {
        if (!row.fecha || !row.masmenos) {
          return null; // Ignorar filas vacías
        }
        const moment = require('moment-timezone');
        const formattedFecha = moment.tz(row.fecha, 'MM/DD/YYYY hh:mm:ss A', 'America/Guayaquil').format('YYYY-MM-DDTHH:mm:ss');
        const valueGetter = (params: any) => {
          if (params.colDef.field === 'empresa') {
            return processFile({
              colDef: {field: 'empresa'},
              row: {empresa: row.empresa}
            });
          } else if (params.colDef.field === 'cuentaban') {
            return processFile({
              colDef: {field: 'cuentaban'},
              row: {cuenta: row.cuentaban}
            });
          } else {
            return params.row['cuenta'];
          }
        };
        const selectedColumnsData: DataRow = {
          fecha: formattedFecha,
          masmenos: row.masmenos,
          referencia: row.referencia,
          referencia2: row.referencia2,
          descripcion: row.descripcion,
          valor: row.valor,
          saldocontable: row.saldocontable,
          saldodisponible: row.saldodisponible,
          oficina: row.oficina,
          codtransaccion: row.codtransaccion,
          cuenta: '',
          empresa: ''
        };
        selectedColumnsData.cuenta = valueGetter({
          colDef: {field: 'cuentaban'}
        });
        selectedColumnsData.empresa = valueGetter({
          colDef: {field: 'empresa'}
        });
        selectedColumns.forEach((column) => {
          selectedColumnsData[column] = row[column];
        });
        return selectedColumnsData;
      })
      .filter((row) => row !== null); // Filtrar filas vacías ignoradas
    // Check for duplicates
    const uniqueSelectedData = [];
    const seenRows = new Set();

    for (const row of selectedData) {
      const rowKey = JSON.stringify(row);
      if (!seenRows.has(rowKey)) {
        uniqueSelectedData.push(row);
        seenRows.add(rowKey);
      }
    }

    if (uniqueSelectedData.length === 0) {
      Swal.fire({
        title: 'No hay datos únicos para guardar',
        text: '',
        icon: 'error',

      })
      return;
    }

    ReporteProdubancoApi.createReporteProdubanco(selectedData)
      .then((response) => {
        if (response.status === 201) {
          // La solicitud se realizó correctamente
          Swal.fire({
            title: 'Guardado exitoso en Produbanco',
            text: '',
            icon: 'success',
            });
        } else {
          // Hubo un error en la solicitud
          if (response.status === 400) {
            // Error 400: Bad Request
            const errorMessage = response.data.error; // Asumiendo que el mensaje de error está en la propiedad 'error'
            Swal.fire({
              title: 'Error de conflicto',
              text: errorMessage,
              icon: 'error',
              })
          } else if (response.status === 409) {
            // Error 409: Conflict
            const errorMessage = response.data.error; // Asumiendo que el mensaje de error está en la propiedad 'error'
            Swal.fire({
              title: 'Error de conflicto',
              text: errorMessage,
              icon: 'error',
              })
          } else {
            // Otros errores sin mensaje específico
            Swal.fire({
              title: 'Error en la solicitud',
              text: '',
              icon: 'error',
              })
          }
        }
      })
      .catch((error) => {
        // Manejar el error de la solicitud
        console.error(
          'Error al enviar los datos a la API:',
          error.response.data
        );

        if (error.response) {
          // Si la respuesta contiene datos, podemos acceder a ellos
          const {mensaje} = error.response.data;
          Swal.fire({
            title: 'Error en la solicitud',
            text: mensaje,
            icon: 'error',
          })
        } else {
          // Si la respuesta no contiene datos (puede ser un error de red, etc.)
          Swal.fire(
            'Error al enviar los datos a la API',
            '',
            'error'
          );
        }
      });
  };

  const handleDataInsertionReportPic = () => {
    const selectedData = data
      .filter((row) => selectedRows.includes(row.id))
      .map((row) => {
        if (!row.fecha || !row.documento) {
          return null;
        }
        const formattedFecha = moment(row.fecha, 'DD/MM/YYYY').toISOString();
        const valueGetter = (params: any) => {
          if (params.colDef.field === 'empresa') {
            return processFile({
              colDef: {field: 'empresa'},
              row: {empresa: row.empresa}
            });
          } else if (params.colDef.field === 'cuentaban') {
            return processFile({
              colDef: {field: 'cuentaban'},
              row: {cuenta: row.cuentaban}
            });
          } else {
            return params.row['cuenta'];
          }
        };
        const selectedColumnsData: DataRow = {
          fecha: formattedFecha,
          codigo: row.codigo,
          concepto: row.concepto,
          tipo: row.tipo,
          documento: row.documento,
          oficina: row.oficina,
          monto: row.monto,
          saldo: row.saldo,
          cuenta:'',
          empresa:'',
        };
        selectedColumnsData.cuenta = valueGetter({
          colDef: {field: 'cuentaban'}
        });
        selectedColumnsData.empresa = valueGetter({
          colDef: {field: 'empresa'}
        });
        selectedColumns.forEach((column) => {
          selectedColumnsData[column] = row[column];
        });
        return selectedColumnsData;
      })
      .filter((row) => row !== null); // Filtrar filas vacías ignoradas
    // Check for duplicates
    const uniqueSelectedData = [];
    const seenRows = new Set();

    for (const row of selectedData) {
      const rowKey = JSON.stringify(row);
      if (!seenRows.has(rowKey)) {
        uniqueSelectedData.push(row);
        seenRows.add(rowKey);
      }
    }

    if (uniqueSelectedData.length === 0) {
      Swal.fire({
        title: 'No hay datos únicos para guardar',
        text: '',
        icon: 'error',

      })
      return;
    }

    ReportePichinchaApi.createReportePichincha(selectedData)
      .then((response) => {
        if (response.status === 201) {

          Swal.fire({
            title: 'Guardado exitoso en Pichincha',
            text: '',
            icon: 'success',
            });
        } else {
          // Hubo un error en la solicitud
          if (response.status === 400) {
            // Error 400: Bad Request
            const errorMessage = response.data.error; // Asumiendo que el mensaje de error está en la propiedad 'error'
            Swal.fire({
              title: 'Error al guardar en Pichincha',
              text: errorMessage,
              icon: 'error',
              });
          } else if (response.status === 409) {
            // Error 409: Conflict
            const errorMessage = response.data.error; // Asumiendo que el mensaje de error está en la propiedad 'error'
            Swal.fire({
              title: 'Error de conflicto',
              text: errorMessage,
              icon: 'error',
              })
          } else {
            // Otros errores sin mensaje específico
            Swal.fire({
              title: 'Error en la solicitud',
              text: '',
              icon: 'error',
              })
          }
        }
      })
      .catch((error) => {
        // Manejar el error de la solicitud
        console.error(
          'Error al enviar los datos a la API:',
          error.response.data
        );

        if (error.response) {
          // Si la respuesta contiene datos, podemos acceder a ellos
          const {mensaje} = error.response.data;
          Swal.fire({
            title: 'Error en la solicitud',
            text: mensaje,
            icon: 'error',
          })
        } else {
          // Si la respuesta no contiene datos (puede ser un error de red, etc.)
          Swal.fire(
            'Error al enviar los datos a la API',
            '',
            'error'
          );
        }
      });
    //handleDataInsertionBonesPic();
  };
  const handleDataInsertionReportPac = () => {
    const selectedData = data
      .filter((row) => selectedRows.includes(row.id))
      .map((row) => {
        if (!row.tipo || !row.lugar) {
          return null; // Ignorar filas vacías
        }
        const formattedFecha2 = moment(row.fechacontable, 'YYYY/MM/DD').format('YYYY-MM-DDTHH:mm:ss');
        const formattedFecha3 = moment(row.fechareal, 'D/M/YYYY hh:mm:ss').format('YYYY-MM-DDTHH:mm:ss');   
        const valueGetter = (params: any) => {
          if (params.colDef.field === 'empresa') {
            return processFile({
              colDef: {field: 'empresa'},
              row: {empresa: row.empresa}
            });
          } else if (params.colDef.field === 'cuentaban') {
            return processFile({
              colDef: {field: 'cuentaban'},
              row: {cuenta: row.cuentaban}
            });
          } else {
            return params.row['cuenta'];
          }
        };
        const selectedColumnsData: DataRow = {
          fechacontable: formattedFecha2,
          lugar: row.lugar,
          tipo: row.tipo,
          nut: row.nut,
          valor: row.valor,
          numero: row.numero,
          concepto: row.concepto,
          saldodespmov: row.saldodespmov,
          descripcion: row.descripcion,
          fechareal: formattedFecha3,
          cuenta: '',
          empresa: ''
        };
        selectedColumnsData.cuenta = valueGetter({
          colDef: {field: 'cuentaban'}
        });
        selectedColumnsData.empresa = valueGetter({
          colDef: {field: 'empresa'}
        });
        selectedColumns.forEach((column) => {
          selectedColumnsData[column] = row[column];
        });
        return selectedColumnsData;
      })
      .filter((row) => row !== null); // Filtrar filas vacías ignoradas
    // Check for duplicates
    const uniqueSelectedData = [];
    const seenRows = new Set();

    for (const row of selectedData) {
      const rowKey = JSON.stringify(row);
      if (!seenRows.has(rowKey)) {
        uniqueSelectedData.push(row);
        seenRows.add(rowKey);
      }
    }

    if (uniqueSelectedData.length === 0) {
      Swal.fire({
        title: 'No hay datos únicos para guardar',
        text: '',
        icon: 'error',
      })
      return;
    }

    ReportePacificoApi.createReportePacifico(selectedData)
      .then((response) => {
        if (response.status === 201) {
          // La solicitud se realizó correctamente
          Swal.fire({
            title: 'Guardado exitoso en Pacífico',
            text: '',
            icon: 'success',
          })
        } else {
          // Hubo un error en la solicitud
          if (response.status === 400) {
            // Error 400: Bad Request
            const errorMessage = response.data.error; // Asumiendo que el mensaje de error está en la propiedad 'error'
            Swal.fire({
              title: 'Error en la solicitu',
              text: errorMessage,
              icon: 'error',
            })
          } else if (response.status === 409) {
            // Error 409: Conflict
            const errorMessage = response.data.error; // Asumiendo que el mensaje de error está en la propiedad 'error'
            Swal.fire({
              title: 'Error de conflicto',
              text: errorMessage,
              icon: 'error',
            })
          } else {
            // Otros errores sin mensaje específico
            Swal.fire({
              title: 'Error en la solicitud',
              text: '',
              icon: 'error',
            })
          }
        }
      })
      .catch((error) => {
        // Manejar el error de la solicitud
        console.error(
          'Error al enviar los datos a la API:',
          error.response.data
        );

        if (error.response) {
          // Si la respuesta contiene datos, podemos acceder a ellos
          const {mensaje} = error.response.data;
          Swal.fire({
            title: 'Error en la solicitud',
            text: `Se encontró que ${mensaje}`,
            icon: 'error',
          })
        } else {
          // Si la respuesta no contiene datos (puede ser un error de red, etc.)
          Swal.fire(
            'Error en la solicitud',
            'Error al enviar los datos a la API',
            'error'
          )
        }
      });
    //handleDataInsertionBonesPac();
  };

  const processFile = processSelectedFile();

  const handleSave = () => {
    const valueGetter = processSelectedFile();

    try {
      const banco = valueGetter({colDef: {field: 'banco'}});
      switch (banco) {
        case 'Produbanco':
          RegistroApi.createRegistro(registroData);
          handleDataInsertionReportProdu();
          //swal('Se guardó Produbanco en reporte general', '', 'success');

          break;

        case 'Pichincha':
          RegistroApi.createRegistro(registroData);
          handleDataInsertionReportPic();
          //swal('Se guardó Pichincha en reporte general', '', 'success');
          break;

        case 'Pacífico':
          RegistroApi.createRegistro(registroData);
          handleDataInsertionReportPac();
          // swal('Se guardó Pacífico en reporte general', '', 'success');
          break;

        default:
          Swal.fire(
            'Banco desconocido',
            'No se encontró el banco en la base de datos',
            'info'
          )
          break;
      }
    } catch (error) {

      Swal.fire(
        'Este es un mensaje de información.',
        'No hay seleccionado algún archivo o en su defecto es incorrecto el archivo para ser guardado.',
        'info'
      )
    }
  };

 
  const columns: GridColDef[] =
    data.length > 0
      ? [
          ...Object.keys(data[0]).map((key) => ({
            field: key,
            headerName: key,
            editable: false,
            sortable: true,
            width: 150
          })),
          {
            field: 'banco',
            headerName: 'Banco',
            editable: false,
            sortable: true,
            width: 150,
            valueGetter: (params: GridValueGetterParams) => {
              const value = processSelectedFile()(params);
              const formattedValue = String(value);
              return formattedValue;
            }
          },
          {
            field: 'empresa',
            headerName: 'Empresa',
            editable: false,
            sortable: true,
            width: 150,
            valueGetter: (params: GridValueGetterParams) => {
              const value = processSelectedFile()(params);
              const formattedValue = String(value);
              return formattedValue;
            }
          },
          {
            field: 'cuenta',
            headerName: 'Cuenta',
            editable: false,
            sortable: true,
            width: 150,
            valueGetter: (params: GridValueGetterParams) => {
              const value = processSelectedFile()(params);
              const formattedValue = String(value);
              return formattedValue;
            }
          },
          {
            field: 'cuentaban',
            headerName: 'Cuenta bancaria',
            editable: false,
            sortable: true,
            width: 250,
            valueGetter: (params: GridValueGetterParams) => {
              const value = processSelectedFile()(params);
              const formattedValue = String(value);
              return formattedValue;
            }
          }
        ]
      : [];

  return (
    <div>
      <br />
      <br />
      <h3>Cargar reporte CSV</h3>
      <Button
      component="label"
      variant="contained"
      startIcon={<CloudUploadIcon />}
      style={{background:"#F18A00", color:"white"}}
    >
      Cargar reporte
      <VisuallyHiddenInput
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
        multiple
      />
    </Button>
      &nbsp; &nbsp;
      <Button variant="contained"  onClick={handleSave} style={{background:"#212C55", color:"white"}}>
        <SaveIcon />
        &nbsp; Guardar
      </Button>
      &nbsp;
      <Notification
        message={notification}
        onClose={() => setNotification('')}
      />
      {data.length > 0 && (
        <>
          <h2>
            {' '}
            <b>Ha cargado el archivo:</b> {selectedFileName}
          </h2>

            <Box style={{height: 400, width: '100%'}}>
              <DataGrid
                rows={data}
                columns={columns}
                // checkboxSelection
                onSelectionModelChange={handleRowSelection}
                onColumnOrderChange={(params, event, details) => {
                  // Handle the column reorder event here
                  console.log('Reordered columns:', params);
                  console.log('Event:', event);
                  console.log('Details:', details);
                }}
                localeText={localeText}
              />
            </Box>

        </>
      )}
    </div>
  );
}

export default FileUploader;
