import axios, { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs'; // Librería para manejar la serialización de parámetros

const BASE_URL = 'https://carga.siatigroup.com';

// Función para actualizar el estado de la plataforma a 'listo_entrega' para una lista de PPB
export const updateShipmentStatusByPpbNumbers = async (ppbNumbers: string[]): Promise<void> => {
    try {
        const response: AxiosResponse<void> = await axios.patch<void>(`${BASE_URL}/shipments/update-status`, null, {
            // Usamos un serializador personalizado para evitar los corchetes [] en los parámetros de URL
            params: {
                ppbNumbers
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            }
        });
        if (response.status === 200) {
            console.log('Shipment status updated successfully');
        }
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            console.error('Error updating shipment status:', error.message);
        } else {
            console.error('Unexpected error:', error);
        }
    }
};
