import React from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import CuentaContableMagaya from './CuentaContableMagaya';

interface CuentaContableMagayaTableProps {
  data: CuentaContableMagaya[];
  columns: TableColumn<CuentaContableMagaya>[];
  customStyles: Record<string, any>;
}

const CuentaContableMagayaTable: React.FC<CuentaContableMagayaTableProps> = ({
  data,
  columns,
  customStyles
}) => {
  return (
    <DataTable<CuentaContableMagaya>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      customStyles={customStyles}
      noDataComponent={"No hay registros para mostrar."}
    />
  );
};

export default CuentaContableMagayaTable;
