import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Plataforma from './Plataforma';


interface PlataformaTableProps {
  data: Plataforma[];
  columns: TableColumn<Plataforma>[];
  customStyles: Record<string, any>;
}

const PlataformaTable: React.FC<PlataformaTableProps> = ({ data, columns, customStyles }) => {
  return (
    <DataTable<Plataforma>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      customStyles={customStyles}
      noDataComponent={"No hay registros para mostrar."}
    />
  );
};

export default PlataformaTable;