import { ConfiguracionApiActivosFijos } from "./ConfiguracionApiActivosFijos"

export const NivelReprocesamientoApi =
{
    getNivelReprocesamiento:function()
    {
        return ConfiguracionApiActivosFijos.get("nivelreprocesamiento")
    },
    getNivelReprocesamientoId:function(id: number)
    {
        return ConfiguracionApiActivosFijos.get(`nivelreprocesamiento/${id}`)
    },
    createNivelReprocesamiento: function(data: any){
        return ConfiguracionApiActivosFijos.post("nivelreprocesamiento", data);
    },
    updateNivelReprocesamiento: function(id: number, data: any){
        return ConfiguracionApiActivosFijos.put(`nivelreprocesamiento/${id}`, data);
    },
    deleteNivelReprocesamiento: function(id: number){
        return ConfiguracionApiActivosFijos.delete(`nivelreprocesamiento/${id}`);
    }
}