import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import BaseMagaya from './BaseMagaya';



interface BaseMagayaTableProps {
  data: BaseMagaya[];
  columns: TableColumn<BaseMagaya>[];
  customStyles: Record<string, any>;
}

const BaseMagayaTable: React.FC<BaseMagayaTableProps> = ({ data, columns, customStyles }) => {
  return (
    <DataTable<BaseMagaya>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      customStyles={customStyles}
      noDataComponent={"No hay registros para mostrar."}
    />
  );
};

export default BaseMagayaTable;