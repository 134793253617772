import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import EstadoIntegracionFactura from './EstadoIntegracionFactura';

interface EstadoIntegracionFacturaTableProps {
  data: EstadoIntegracionFactura[];
  columns: TableColumn<EstadoIntegracionFactura>[];
  customStyles: Record<string, any>;
}

const EstadoIntegracionFacturaTable: React.FC<EstadoIntegracionFacturaTableProps> = ({ data, columns, customStyles }) => {
  return (
    <DataTable<EstadoIntegracionFactura>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      customStyles={customStyles}
      noDataComponent={"No hay registros para mostrar."}
    />
  );
};

export default EstadoIntegracionFacturaTable;