import { ConfiguracionApiActivosFijos } from "./ConfiguracionApiActivosFijos"

export const MarcaApi =
{
    getMarca:function()
    {
        return ConfiguracionApiActivosFijos.get("marca")
    },
    getMarcaId:function(id: number)
    {
        return ConfiguracionApiActivosFijos.get(`marca/${id}`)
    },
    createMarca: function(data: any){
        return ConfiguracionApiActivosFijos.post("marca", data);
    },
    updateMarca: function(id: number, data: any){
        return ConfiguracionApiActivosFijos.put(`marca/${id}`, data);
    },
    deleteMarca: function(id: number){
        return ConfiguracionApiActivosFijos.delete(`marca/${id}`);
    }
}