import { ConfiguracionApiActivosFijos } from "./ConfiguracionApiActivosFijos"

export const TipoIngresoApi =
{
    getTipoIngreso:function()
    {
        return ConfiguracionApiActivosFijos.get("tipoIngreso")
    },
    getTipoIngresoId:function(id: number)
    {
        return ConfiguracionApiActivosFijos.get(`tipoIngreso/${id}`)
    },
    createTipoIngreso: function(data: any){
        return ConfiguracionApiActivosFijos.post("tipoIngreso", data);
    },
    updateTipoIngreso: function(id: number, data: any){
        return ConfiguracionApiActivosFijos.put(`tipoIngreso/${id}`, data);
    },
    deleteTipoIngreso: function(id: number){
        return ConfiguracionApiActivosFijos.delete(`tipoIngreso/${id}`);
    }
}