import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import Register from '@modules/register/Register';
import ForgetPassword from '@modules/forgot-password/ForgotPassword';
import RecoverPassword from '@modules/recover-password/RecoverPassword';
import {useWindowSize} from '@app/hooks/useWindowSize';
import {calculateWindowSize} from '@app/utils/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {setWindowSize} from '@app/store/reducers/ui';
import Dashboard from '@pages/Dashboard';
import Profile from '@pages/profile/Profile';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import "dist/css/adminlte.min.css";
import "plugins/fontawesome-free/css/all.min.css";
import { Catalogos } from './pages/estadisticas/Catalogos';
import Categoria from './pages/activosFijos/Categoria';
import { CatalogoIntegrador } from './pages/integrador/CatalogoIntegrador';
import { Catalogo } from './pages/banca/Catalogo';
import { Reportes } from './pages/banca/documentos/Reportes';
import NuevoProyectoMagaya from './pages/integrador/nuevoproyectomagaya/NuevoProyectoMagaya';
import Panel from './pages/banca/Panel';
import Reporteria from './pages/banca/Reporteria';
import Empresas from './pages/banca/empresa/Empresas';
import Bancos from './pages/banca/banco/Bancos';
import Registros from './pages/banca/documentos/Registros';
import Cuentas from './pages/banca/cuenta/Cuentas';
import Factura from './pages/integrador/reportes/Factura';
import DashboardIntegrador from './pages/integrador/DashboardIntegrador';
import { CatalogoPerfil } from './pages/perfil/Catalogo';
import { CatalogCourier } from './pages/courier/Courier';






const App = () => {
  const windowSize = useWindowSize();
  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState<string>('');
  const [imagen, setImagen] = React.useState<any>();
  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
  }, [windowSize]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="/login" element={<Login setEmail={setEmail} setImagen={setImagen} setPreferredName={function (value: string): void {
            throw new Error('Function not implemented.');
          } } setName={function (value: string): void {
            throw new Error('Function not implemented.');
          } } />} />
        </Route>
        <Route path="/register" element={<PublicRoute />}>
          <Route path="/register" element={<Register />} />
        </Route>
        <Route path="/forgot-password" element={<PublicRoute />}>
          <Route path="/forgot-password" element={<ForgetPassword />} />
        </Route>
        <Route path="/recover-password" element={<PublicRoute />}>
          <Route path="/recover-password" element={<RecoverPassword />} />
        </Route>
        <Route path="/" element={<PrivateRoute/>}>
          <Route path="/" element={<Main email={email} imagen={imagen}/>}>
            <Route path="/profile" element={<Profile  />} />
            <Route path="/catalogos" element={<Catalogos />} />
            <Route path="/catalogoActivosFijos" element={<Categoria />} />
            <Route path="/catalogoIntegrador" element={<CatalogoIntegrador/>}/>
            <Route path="/catalogoBanco" element={<Catalogo/>}/>
            <Route path="/catalogoPerfil" element={<CatalogoPerfil/>}/>
            <Route path="/catalogoReporteria" element={<Reportes/>}/>
            <Route path="/logProyectoMagaya" element={<NuevoProyectoMagaya/>}/>
            <Route path="/panel" element={<Panel/>}/>
            <Route path="/reports" element={<Reporteria/>}/>
            <Route path="/" element={<Panel />} />
            <Route path="/reports" element={<Reporteria/>}/>
            <Route path="/catalogCourier" element={<CatalogCourier/>}/>
            <Route path="/company" element={<Empresas/>}/>
            <Route path="/bank" element={<Bancos/>}/>
            <Route path="/registry" element={<Cuentas/>}/>
            <Route path="/archivo" element={<Registros/>}/>
            <Route path="/report" element={<Factura/>}/>
            <Route path="/tablero" element={<DashboardIntegrador/>}/>
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
      
    </BrowserRouter>
  );
};

export default App;
