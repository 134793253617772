import React, {useEffect, useState} from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button} from 'react-bootstrap';
import {toast} from 'react-toastify';
import DialogBox from '../../../components/DialogBox';
import { TipoActivoApi } from '@app/api/activosFijos/TipoActivoApi';
import TipoActivoTable from './TipoActivoTable';



interface TipoActivo {
  idtipoactivo: number;
  nombre: string;
  codigo: string;
  activo: boolean;
}

const TipoActivo: React.FC = () => {
  const [data, setData] = useState<TipoActivo[]>([]);
  const [editingData, setEditingData] = useState<TipoActivo | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activo, setActivo] = useState<boolean>(editingData?.activo || false);
  const [inputValue, setInputValue] = useState('');
  const handleChange = (event: { target: { value: any; }; }) => {
    const { value } = event.target;
    if (value.length <= 2) {
      setInputValue(value);
    }else {
      toast.error('El código debe tener solo dos caracteres');
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseGuardar = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    handleReset();
  };

  const handleReset = () => {
    setEditingData(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await TipoActivoApi.getTipoActivo();
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  const handleEdit = (row: TipoActivo) => {
    console.log('Editar:', row);
    setEditingData(row);
    handleOpen();
  };

  const handleDelete = (row: TipoActivo) => {
    console.log('Eliminar:', row);
    confirmAlert({
      title: 'Confirmar eliminación',
      message: `¿Estás seguro que deseas eliminar el registro ${row.nombre}?`,
      buttons: [
        {
          label: 'Sí',
          onClick: () => {
            const newData = data.filter(
              (d: TipoActivo) => d.idtipoactivo !== row.idtipoactivo
            );
            setData(newData);
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const handleSave = (row: TipoActivo) => {
    var validacionDatos='';
    if (row.idtipoactivo) {
      if(row.codigo.length!==2)
      {
        validacionDatos = 'Codigo debe ser de dos Digitos';
      }
      if(row.codigo.length===0)
      {
        validacionDatos = 'Codigo no puede estar en blanco';
      }
      if(row.nombre.length===0)
      {
        validacionDatos = 'Nombre no puede estar en blanco';
      }
      const actualizacion = data.find((element) => {
        if((element.codigo.toUpperCase() === row.codigo.toUpperCase() || element.nombre.toUpperCase() === row.nombre.toUpperCase()) && element.idtipoactivo!==row.idtipoactivo)
        {
          validacionDatos = 'Error Datos Duplicado, Valide el Catalogo';
          return true;
        }
      });
      if(validacionDatos.length===0)
      {
        TipoActivoApi.updateTipoActivo(row.idtipoactivo, row).then(() => {
          setData((prevTipoActivo) =>
          prevTipoActivo.map((u) => (u.idtipoactivo === row.idtipoactivo ? row : u))
          );
          toast.success('Actualización Exitosa');
          handleReset();
          handleCloseGuardar();
        })
        .catch((err) => {
          toast.error('Error de Comunicación, Contacte a TI');
        });
    }
    else{
      toast.error(validacionDatos);
    }
    } else {
      if(row.codigo.length!==2)
      {
        validacionDatos = 'Codigo debe ser de dos Digitos';
      }
      if(row.codigo.length===0)
      {
        validacionDatos = 'Codigo no puede estar en blanco';
      }
      if(row.nombre.length===0)
      {
        validacionDatos = 'Nombre no puede estar en blanco';
      }
      const creacion = data.find((element) => {
        if(element.codigo.toUpperCase() == row.codigo.toUpperCase() || element.nombre.toUpperCase() == row.nombre.toUpperCase())
        {
          validacionDatos = 'Error Datos Duplicado, Valide el Catalogo';
          return true;
        }
      });
      if(validacionDatos.length===0){
        TipoActivoApi.createTipoActivo(row).then((response) => {
          setData((prevTipoActivo) => [...prevTipoActivo, response.data]);
          toast.success('Creación Exitosa');
          handleReset();
          handleCloseGuardar();
        })
        .catch((err) => {
          toast.error('Error de Comunicación, Contacte a TI');
        });
      }
      else{
        toast.error(validacionDatos);
      } 
    }
  };

  const columns: TableColumn<TipoActivo>[] = [
    {name: 'Tipo de Activo', selector: (row) => row.nombre},
    {name: 'Código', selector: (row) => row.codigo},
    {
      name: 'Acciones',
      cell: (row: TipoActivo) => (
        <div>
          <button
            onClick={() => handleEdit(row)}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-pen"></i> Editar{' '}
          </button>
          &nbsp;
          <button
            onClick={() => handleDelete(row)}
            className="btn btn-danger btn-sm"
          >
            <i className="fa fa-eraser"></i> Eliminar{' '}
          </button>
        </div>
      )
    }
  ];

  return (
    <div>
      <Button variant="success" onClick={handleOpen}>
        <i className="fa fa-plus"></i> Agregar{' '}
      </Button>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <TipoActivoTable
          data={data}
          columns={columns}
          customStyles={customStyles}
        />
      )}
      <DialogBox
        open={open}
        onClose={handleClose}
        title={editingData ? 'Editar' : 'Agregar'}
        content={
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const row: TipoActivo = {
                idtipoactivo: editingData?.idtipoactivo || 0,
                nombre: formData.get('nombre') as string,
                codigo: formData.get('codigo') as string,
                activo
              };
              handleSave(row);
            }}
          >
            <div className="form-group" id="nombre">
              <label>Tipo de Activo</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Tipo de Activo</span>
                </div>
                <input
                  type="text"
                  name="nombre"
                  className="form-control "
                  defaultValue={editingData?.nombre}
                  placeholder="Ingrese el Tipo de Activo"
                />
              </div>
            </div>
            <div className="form-group" id="codigo">
              <label>Código</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text"><i className="fa fa-barcode"></i></span>
                </div>
                <input
                  type="text"
                  name="codigo"
                  className="form-control "
                  defaultValue={editingData?.codigo}
                  placeholder="Ingrese el código de activo"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group custom-control custom-checkbox">
              <input
                className="custom-control-input"
                type="checkbox"
                checked={activo}
                onChange={(e) => setActivo(e.target.checked)}
                defaultChecked={editingData?.activo}
                id="activo"
              />
              <label htmlFor="activo" className="custom-control-label">
                Activo
              </label>
            </div>

            <div className="modal-footer justify-content-between">
              <Button
                type="button"
                className="btn btn-danger"
                onClick={handleClose}
              >
                Cerrar
              </Button>
              <Button type="submit" className="btn btn-success">
                {editingData ? 'Guardar' : 'Agregar'}
              </Button>
            </div>
          </form>
        }
      />
    </div>
  );
};

const customStyles = {
  headCells: {
    style: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontFamily: 'Arial'
    }
  },
  rows: {
    style: {
      fontSize: '18px'
    }
  },
  cells: {
    style: {
      fontSize: '16px'
    }
  },
  pagination: {
    style: {
      fontSize: '14px'
    }
  }
};

export default TipoActivo;