import api from "./ConfiguracionGeneralApi";

export const PerfilApi =  {
    getPerfilId:function(id: number)
    {
        return api.get(`perfil/${id}`)
    },
    getPerfil:function()
    {
        return api.get("perfil/perfiles")
    },
    createPerfil: function(data: any){
        return api.post("perfil", data);
    },
    updatePerfil: function(idPerfil: number, data: any) {
        return api.put(`perfil/${idPerfil}`, data);
    },

}