import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { SistemaApi } from '@app/api/integrador/SistemaApi';
import SistemaTable from './SistemaTable';
import SistemaForm from './SistemaForm';
import { TableColumn } from 'react-data-table-component';

export type Sistema = {
  idsistema: number;
  idplataforma: number;
  idbasemagaya: number;
  idnetworkmagaya: number;
  servidorcomunicaciones: string;
  endpoint: string;
  usuarioapi: string;
  contraseniaapi: string;
};

const Sistema: React.FC = () => {
  const [data, setData] = useState<Sistema[]>([]);
  const [editingData, setEditingData] = useState<Sistema | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseGuardar = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    handleReset();
  };

  const handleReset = () => {
    setEditingData(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await SistemaApi.getSistema();
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  const handleEdit = (row: Sistema) => {
    console.log('Editar:', row);
    setEditingData(row);
    handleOpen();
  };

  const handleDelete = (row: Sistema) => {
    console.log('Eliminar:', row);
    confirmAlert({
      title: 'Confirmar eliminación',
      message: `¿Estás seguro que deseas eliminar el registro ${row.idsistema}?`,
      buttons: [
        {
          label: 'Sí',
          onClick: () => {
            const newData = data.filter((d: Sistema) => d.idsistema !== row.idsistema);
            setData(newData);
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const handleSaveLinea = (row: Sistema) => {
    var validacionDatos = '';
    if (row.idsistema) {
      if (row.servidorcomunicaciones.length === 0) {
        validacionDatos = 'Nombre no puede estar en blanco';
      }
      const actualizacion = data.find((element) => {
        if (
          element.servidorcomunicaciones.toUpperCase() === row.servidorcomunicaciones.toUpperCase() &&
          element.idsistema !== row.idsistema
        ) {
          validacionDatos = 'Error Datos Duplicado, Valide el Catalogo';
          return true;
        }
      });
      if (validacionDatos.length === 0) {
        SistemaApi.updateSistema(row.idsistema, row)
          .then(() => {
            setData((prevRow) => prevRow.map((u) => (u.idsistema === row.idsistema ? row : u)));
            toast.success('Actualización Exitosa');
            handleReset();
            handleCloseGuardar();
          })
          .catch((err) => {
            toast.error('Error de Comunicación, Contacte a TI');
          });
      } else {
        toast.error(validacionDatos);
      }
    } else {
      if (row.servidorcomunicaciones.length === 0) {
        validacionDatos = 'Nombre no puede estar en blanco';
      }
      const creacion = data.find((element) => {
        if (element.servidorcomunicaciones.toUpperCase() == row.servidorcomunicaciones.toUpperCase()) {
          validacionDatos = 'Error Datos Duplicado, Valide el Catalogo';
          return true;
        }
      });
      if (validacionDatos.length === 0) {
        SistemaApi.createSistema(row)
          .then((response) => {
            setData((prevData) => [...prevData, response.data]);
            toast.success('Creación Exitosa');
            handleReset();
            handleCloseGuardar();
          })
          .catch((err) => {
            toast.error('Error de Comunicación, Contacte a TI');
          });
      } else {
        toast.error(validacionDatos);
      }
    }
  };
  const columns: TableColumn<Sistema>[] = [
    {name: 'Nombre', selector: (row) => row.servidorcomunicaciones},
    {name: 'Endpoint', selector: (row) => row.endpoint},
    {name: 'Usuario Api', selector: (row) => row.usuarioapi},
    {
      name: 'Acciones',
      cell: (row: Sistema) => (
        <div>
          <button
            onClick={() => handleEdit(row)}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-pen"></i> Editar{' '}
          </button>
          &nbsp;
          <button
            onClick={() => handleDelete(row)}
            className="btn btn-danger btn-sm"
          >
            <i className="fa fa-eraser"></i> Eliminar{' '}
          </button>
        </div>
      )
    }
  ];
  return (
    <div>
      <Button variant="success" onClick={handleOpen}>
        <i className="fa fa-plus"></i> Agregar{' '}
      </Button>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <SistemaTable
          data={data}
          columns={columns}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      )}
      <SistemaForm
        editingData={editingData}
        open={open}
        onClose={handleClose}
        onSaveLinea={handleSaveLinea}
      />
    </div>
  );
};

export default Sistema;