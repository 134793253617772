import React, {useEffect, useState} from 'react';
import {TableColumn} from 'react-data-table-component';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { CuentaApi } from '@app/api/bancos/CuentaApi';
import CuentaTable from './CuentaTable';
import { BancoApi } from '@app/api/bancos/BancoApi';
import { EmpresaApi } from '@app/api/bancos/EmpresaApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';

interface Cuenta {
  idcuenta: number;
  cuentacontable: number;
  numcta: string;
  idbanco: number;
  idempresa: number;
}
interface Banco {
  idbanco: number;
  nombre: string;
  ruc: string;
}
interface Empresa {
  idempresa: number;
  nombre: string;
  ruc: string;
}
const Cuentas: React.FC = () => {
  const [data, setData] = useState<Cuenta[]>([]);
  const [bancos, setBancos] = useState<Banco[]>([]);
  const [empresas, setEmpresas] = useState<Empresa[]>([]);
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await CuentaApi.getCuenta();
      setData(response.data);
      const bancosResponse = await BancoApi.getBanco();
      setBancos(bancosResponse.data);
      const empresaReponse = await EmpresaApi.getEmpresa();
      setEmpresas(empresaReponse.data);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };
  const getCuentaEmpresa = (cuenta: Cuenta) => {
    const empresa = empresas.find((em) => em.idempresa === cuenta.idempresa);
    return empresa ? `${empresa.nombre}`: 'Sin empresa asocida'; 
  };

  const getCuentaBanco = (cuenta: Cuenta) => {
    const banco = bancos.find((b) => b.idbanco === cuenta.idbanco);
    return banco ? `${banco.nombre}` : 'Sin banco asociado';
  };

 
  const columns: TableColumn<Cuenta>[] = [
    {
      name: 'Banco',
      selector: (row) => getCuentaBanco(row), 
    },
    {name: 'Cuenta contable', selector: (row) => row.cuentacontable},
    {name: 'Número de cuenta', selector: (row) => row.numcta},
    {
      name: 'Empresa',
      selector: (row) => getCuentaEmpresa(row),
    },
  ];

  return (
    <section className="content">
    <div className="container-fluid">
     
    <div className='card card-default'>
        <div className="card-header">
       <h1> 
       <FontAwesomeIcon icon={faWallet} />  Cuentas
    </h1>
        </div>
     {loading ? (
        <p>Cargando...</p>
      ) : (
        <CuentaTable
          data={data}
          columns={columns}
          customStyles={customStyles}
        />
      )}
    </div>
    </div>
    </section>
  );
};

const customStyles = {
  headCells: {
    style: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontFamily: 'Arial'
    }
  },
  rows: {
    style: {
      fontSize: '18px'
    }
  },
  cells: {
    style: {
      fontSize: '16px'
    }
  },
  pagination: {
    style: {
      fontSize: '14px'
    }
  }
};

export default Cuentas;