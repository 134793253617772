import { api } from "./ConfiguracionBancoApi"
export const EmpresaApi =
{
    getEmpresa:function()
    {
        return api.get("empresa")
    },
    getEmpresa1:function()
    {
        return api.get("empresa/registros")
    },
    getEmpresaId:function(id: number)
    {
        return api.get(`empresa/${id}`)
    },
    createEmpresa: function(data: any){
        return api.post("empresa", data);
    },
    updateEmpresa: function(id: number, data: any){
        return api.put(`empresa/${id}`, data);
    },
    deleteEmpresa: function(id: number){
        return api.delete(`empresa/${id}`);
    }
}