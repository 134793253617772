import React, { useEffect, useState } from 'react'
import { EmpresaApi } from '@app/api/bancos/EmpresaApi';
import { TableColumn } from 'react-data-table-component';
import EmpresaTable from './EmpresaTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
interface Empresa {
    idempresa: number;
    nombre: string;
    ruc: string;
  }
const Empresas = () => {
    const [empresas, setEmpresas] = useState<Empresa[]>([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchData();
      }, []);
    
      const fetchData = async () => {
        try {
          const empresaReponse = await EmpresaApi.getEmpresa();
          setEmpresas(empresaReponse.data);
          setLoading(false);
        } catch (error) {
          console.error('Error al obtener los datos:', error);
        }
      };
      const columns: TableColumn<Empresa>[] = [
        {name: 'Empresa', selector: (row) => row.nombre },
        {name: 'Ruc', selector: (row) => row.ruc},
      ];
    
  return (
    <section className="content">
    <div className="container-fluid">
     
    <div className='card card-default'>
        <div className="card-header">
       <h1> 
    <FontAwesomeIcon icon={faBuilding} />    Empresas
    </h1>
        </div>
     {loading ? (
        <p>Cargando...</p>
      ) : (
        <EmpresaTable
          data={empresas}
          columns={columns}
          customStyles={customStyles}
        />
      )}
    </div>
    </div>
    </section>
  )
};
const customStyles = {
    headCells: {
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: 'Arial'
      }
    },
    rows: {
      style: {
        fontSize: '18px'
      }
    },
    cells: {
      style: {
        fontSize: '16px'
      }
    },
    pagination: {
      style: {
        fontSize: '14px'
      }
    }
  };

export default Empresas