import { api } from "./ApiConfiguracionEstadisticas"
export const TipoDocumentoApi =
{
    getTipoDocumento:function()
    {
        return api.get("tipodocumento")
    },
    getTipoDocumentoId:function(id: number)
    {
        return api.get(`tipodocumento/${id}`)
    },
    createTipoDocumento: function(data: any){
        return api.post("tipodocumento", data);
    },
    updateTipoDocumento: function(id: number, data: any){
        return api.put(`tipodocumento/${id}`, data);
    },
    deleteTipoDocumento: function(id: number){
        return api.delete(`tipodocumento/${id}`);
    }
}