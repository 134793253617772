import { api } from "./ConfiguracionBancoApi"
export const ReporteProdubancoApi =
{
    getReporteProdubanco1:function()
    {
        return api.get("reporteprodubanco/registros")
    },
    getReporteProdubanco2:function()
    {
        return api.get("reporteprodubanco/reportes-por-masmenos")
    },
    getReporteProdubanco:function()
    {
        return api.get("reporteprodubanco")
    },
    getReporteProdubancoId:function(id: number)
    {
        return api.get(`reporteprodubanco/${id}`)
    },
    createReporteProdubanco: function(data: any){
        return api.post("reporteprodubanco", data);
    },
    updateReporteProdubanco: function(id: number, data: any){
        return api.put(`reporteprodubanco/${id}`, data);
    },
    deleteReporteProdubanco: function(id: number){
        return api.delete(`reporteprodubanco/${id}`);
    }
}