import { api } from "../integradorcontabilidad/ApiConfiguracionIntegradorContabilidad"

export const PrefacturaApi =  {
    getPrefactura:function(id: number)
    {
        return api.get(`/api/prefactura/${id}`)
    },
    getPrefacturaId:function(id: number)
    {
        return api.get(`/api/prefactura/porEstadoIntegracion/${id}`)
    },
    updatePrefactura: function(data: any){
        return api.put("/api/prefactura/actualizarEstado/", data);
    }
}