import React from 'react'
import LineaDeNegocio from './lineadenegocio/LineaDeNegocio';
import TipoDeTramite from './tipodetramite/TipoDeTramite';
import EstadoDetramite from './estadodetramite/EstadoDeTramite';
import TipoDocumento from './tipodocumento/TipoDocumento';
import EstadoDocumento from './estadodocumento/EstadoDocumento';
import TipoDeAforo from './tipodeaforo/TipoDeAforo';
import NaturalezaFactura from './naturalezaFactura/NaturalezaFactura';


export const Catalogos = () => {
  return (
    <section className="content">
    <div className="container-fluid">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Catálagos de Estadísticas</h3>
          <div className="card-tools">
          </div>
        </div>
        <div className="card-body">
          <div className="p-tabview p-component">
            <div className=".p-tabview-nav p-0 pt-1">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-one-tab"
                role="tablist"
              >
                <li className="li.p-highlight">
                  <a
                    className="nav-link active"
                    id="custom-tabs-one-estadodetramite-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-estadodetramite"
                    role="tab"
                    aria-controls="custom-tabs-one-estadodetramite"
                    aria-selected="true"
                    style={{color:'#101D61'}}
                  >
                    Estado de Trámite
                  </a>
                </li>
                <li className="li.p-highlight">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-tipotramite-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-tipotramite"
                    role="tab"
                    aria-controls="custom-tabs-one-tipotramite"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Tipo de Trámite
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-tipodocumento-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-tipodocumento"
                    role="tab"
                    aria-controls="custom-tabs-one-tipodocumento"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Tipo de Documento
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-estadodocumento-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-estadodocumento"
                    role="tab"
                    aria-controls="custom-tabs-one-estadodocumento"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Estado de Documento
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-tipodeaforo-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-tipodeaforo"
                    role="tab"
                    aria-controls="custom-tabs-one-tipodeaforo"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Tipo de Aforo
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-lineNego-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-lineNego"
                    role="tab"
                    aria-controls="custom-tabs-one-lineNego"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Línea de Negocio
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-naturaleza-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-naturaleza"
                    role="tab"
                    aria-controls="custom-tabs-one-naturaleza"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Naturaleza de la Factura
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-one-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-one-estadodetramite"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-estadodetramite-tab"
                >
                  <EstadoDetramite/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-tipotramite"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-tipotramite-tab"
                >
                  <TipoDeTramite/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-tipodocumento"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-tipodocumento-tab"
                >
                  <TipoDocumento/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-estadodocumento"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-estadodocumento-tab"
                >
                  <EstadoDocumento/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-tipodeaforo"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-tipodeaforo-tab"
                >
                  <TipoDeAforo/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-lineNego"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-lineNego-tab"
                >
                  <LineaDeNegocio/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-naturaleza"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-naturaleza-tab"
                >
                  <NaturalezaFactura/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
    </div>
  </section>
  )
}
