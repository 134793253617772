import React, {useEffect, useState} from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button} from 'react-bootstrap';
import {toast} from 'react-toastify';
import DialogBox from '../../../components/DialogBox';
import {CuentaContableMagayaApi} from '@app/api/integrador/CuentaContableMagayaApi';
import CuentaContableMagayaTable from './CuentaContableMagayaTable';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import InputMask from 'react-input-mask';
interface CuentaContableMagaya {
  idcuentacontablemagaya: number;
  idtipocuentamagaya: number;
  idbasemagaya: number;
  anio: number;
  codigocuentacontable: string;
  nombrecuentacontable: string;
  codigoservicio: string;
  nombreservicio: string;
  observaciones: string;
}

const CuentaContableMagaya: React.FC = () => {
  const [data, setData] = useState<CuentaContableMagaya[]>([]);
  const [editingData, setEditingData] = useState<CuentaContableMagaya | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [anio, setAnio] = useState<number | undefined>(undefined);
  const handleAnioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(event.target.value, 10);
    setAnio(inputValue);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseGuardar = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    handleReset();
  };

  const handleReset = () => {
    setEditingData(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await CuentaContableMagayaApi.getCuentaContableMagaya();
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  const handleEdit = (row: CuentaContableMagaya) => {
    console.log('Editar:', row);
    setEditingData(row);
    handleOpen();
  };

  const handleDelete = (row: CuentaContableMagaya) => {
    console.log('Eliminar:', row);
    confirmAlert({
      title: 'Confirmar eliminación',
      message: `¿Estás seguro que deseas eliminar el registro ${row.nombrecuentacontable}?`,
      buttons: [
        {
          label: 'Sí',
          onClick: () => {
            const newData = data.filter(
              (d: CuentaContableMagaya) =>
                d.idcuentacontablemagaya !== row.idcuentacontablemagaya
            );
            setData(newData);
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const handleSave = (row: CuentaContableMagaya) => {
    var validacionDatos = '';
    if (row.idbasemagaya) {
      if (row.nombrecuentacontable.length === 0) {
        validacionDatos = 'Nombre no puede estar en blanco';
      }
      const actualizacion = data.find((element) => {
        if (
          element.nombrecuentacontable.toUpperCase() ===
            row.nombrecuentacontable.toUpperCase() &&
          element.idbasemagaya !== row.idbasemagaya
        ) {
          validacionDatos = 'Error Datos Duplicado, Valide el Catalogo';
          return true;
        }
      });
      if (validacionDatos.length === 0) {
        CuentaContableMagayaApi.updateCuentaContableMagaya(
          row.idcuentacontablemagaya,
          row
        )
          .then(() => {
            setData((prevRow) =>
              prevRow.map((u) =>
                u.idbasemagaya === row.idbasemagaya ? row : u
              )
            );
            toast.success('Actualización Exitosa');
            handleReset();
            handleCloseGuardar();
          })
          .catch((err) => {
            toast.error('Error de Comunicación, Contacte a TI');
          });
      } else {
        toast.error(validacionDatos);
      }
    } else {
      if (row.nombrecuentacontable.length === 0) {
        validacionDatos = 'Nombre no puede estar en blanco';
      }
      const creacion = data.find((element) => {
        if (
          element.nombrecuentacontable.toUpperCase() ==
          row.nombrecuentacontable.toUpperCase()
        ) {
          validacionDatos = 'Error Datos Duplicado, Valide el Catalogo';
          return true;
        }
      });
      if (validacionDatos.length === 0) {
        CuentaContableMagayaApi.createCuentaContableMagaya(row)
          .then((response) => {
            setData((prevData) => [...prevData, response.data]);
            toast.success('Creación Exitosa');
            handleReset();
            handleCloseGuardar();
          })
          .catch((err) => {
            toast.error('Error de Comunicación, Contacte a TI');
          });
      } else {
        toast.error(validacionDatos);
      }
    }
  };

  const columns: TableColumn<CuentaContableMagaya>[] = [
    {name: 'Año', selector: (row) => row.anio},
    {
      name: 'Código Cuenta Contable',
      selector: (row) => row.codigocuentacontable
    },
    {
      name: 'Nombre Cuenta Contable',
      selector: (row) => row.nombrecuentacontable
    },
    {name: 'Código Servicio', selector: (row) => row.codigoservicio},
    {name: 'Nombre Servicio', selector: (row) => row.nombreservicio},
    {name: 'Observaciones', selector: (row) => row.observaciones},
    {
      name: 'Acciones',
      cell: (row: CuentaContableMagaya) => (
        <div>
          <button
            onClick={() => handleEdit(row)}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-pen"></i> Editar{' '}
          </button>
          &nbsp;
          <button
            onClick={() => handleDelete(row)}
            className="btn btn-danger btn-sm"
          >
            <i className="fa fa-eraser"></i> Eliminar{' '}
          </button>
        </div>
      )
    }
  ];

  return (
    <div>
      <Button variant="success" onClick={handleOpen}>
        <i className="fa fa-plus"></i> Agregar{' '}
      </Button>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <CuentaContableMagayaTable
          data={data}
          columns={columns}
          customStyles={customStyles}
        />
      )}
      <DialogBox
        open={open}
        onClose={handleClose}
        title={editingData ? 'Editar' : 'Agregar'}
        content={
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const row: CuentaContableMagaya = {
                idcuentacontablemagaya:
                  editingData?.idcuentacontablemagaya || 0,
                idtipocuentamagaya: editingData?.idtipocuentamagaya || 0,
                idbasemagaya: editingData?.idbasemagaya || 0,
                anio: editingData?.anio || 0,
                codigocuentacontable: formData.get(
                  'codigocuentacontable'
                ) as string,
                nombrecuentacontable: formData.get(
                  'nombrecuentacontable'
                ) as string,
                codigoservicio: formData.get('codigoservicio') as string,
                nombreservicio: formData.get('nombreservicio') as string,
                observaciones: formData.get('observaciones') as string
              };
              handleSave(row);
            }}
          >
            <div className="card-body">
              <div className="p-tabview p-component">
                <div className=".p-tabview-nav p-0 pt-1">
                  <ul
                    className="nav nav-tabs"
                    id="custom-tabs-one-tab"
                    role="tablist"
                  >
                    <li className="li.p-highlight">
                      <a
                        className="nav-link active"
                        id="custom-tabs-one-code-tab"
                        data-toggle="pill"
                        href="#custom-tabs-one-code"
                        role="tab"
                        aria-controls="custom-tabs-one-code"
                        aria-selected="true"
                        style={{color: '#101D61'}}
                      >
                        Códigos
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="custom-tabs-one-parameter-tab"
                        data-toggle="pill"
                        href="#custom-tabs-one-parameter"
                        role="tab"
                        aria-controls="custom-tabs-one-parameter"
                        aria-selected="false"
                        style={{color: '#101D61'}}
                      >
                        Parámetros
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <div className="tab-content" id="custom-tabs-one-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="custom-tabs-one-code"
                      role="tabpanel"
                      aria-labelledby="custom-tabs-one-code-tab"
                    >
                      <div className="form-group" id="codigocuentacontable">
                        <label>Código Cuenta Contable</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-barcode"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            name="codigocuentacontable"
                            className="form-control "
                            defaultValue={editingData?.codigocuentacontable}
                            placeholder="Ingrese el tipo de cuenta"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group" id="codigoservicio">
                      <label>Código Servicio</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-barcode"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          name="codigoservicio"
                          className="form-control "
                          defaultValue={editingData?.codigoservicio}
                          placeholder="Ingrese el código de servicio"
                        />
                      </div>
                    </div>
                    <div className="form-group" id="anio">
                      <label>Año</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-calendar"></i>
                          </span>
                        </div>
                        <input
                          type="number"
                          name="anio"
                          className="form-control "
                          defaultValue={editingData?.anio}
                          placeholder="YYYY"
                        />
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="custom-tabs-one-parameter"
                      role="tabpanel"
                      aria-labelledby="custom-tabs-one-parameter-tab"
                    >
                      <div className="form-group" id="nombrecuentacontable">
                        <label>Nombre Cuenta Contable</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              Nombre Cuenta Contable
                            </span>
                          </div>
                          <input
                            type="text"
                            name="nombrecuentacontable"
                            className="form-control "
                            defaultValue={editingData?.nombrecuentacontable}
                            placeholder="Ingrese el nombre de cuenta"
                          />
                        </div>
                      </div>
                      <div className="form-group" id="nombreservicio">
                        <label>Nombre Servicio</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              Nombre servicio
                            </span>
                          </div>
                          <input
                            type="text"
                            name="nombreservicio"
                            className="form-control "
                            defaultValue={editingData?.nombreservicio}
                            placeholder="Ingrese el nombre de cuenta"
                          />
                        </div>
                      </div>
                      <div className="form-group" id="observaciones">
                        <label>Observaciones</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              Observaciones
                            </span>
                          </div>
                          <textarea
                            name="observaciones"
                            className="form-control "
                            defaultValue={editingData?.observaciones}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <Button
                type="button"
                className="btn btn-danger"
                onClick={handleClose}
              >
                Cerrar
              </Button>
              <Button type="submit" className="btn btn-success">
                {editingData ? 'Guardar' : 'Agregar'}
              </Button>
            </div>
          </form>
        }
      />
    </div>
  );
};

const customStyles = {
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: 'Arial'
    }
  },
  rows: {
    style: {
      fontSize: '12px'
    }
  },
  cells: {
    style: {
      fontSize: '14px'
    }
  },
  pagination: {
    style: {
      fontSize: '14px'
    }
  }
};

export default CuentaContableMagaya;
