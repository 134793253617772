import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {loginUser} from '@store/reducers/auth';
import {setWindowClass} from '@app/utils/helpers';
import {gapi} from 'gapi-script';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { Configuration, UserAgentApplication, CacheLocation } from 'msal';
import Button from 'react-bootstrap/esm/Button';

const msalConfig = {
  auth: {
    clientId: 'be8a1899-58ad-4f8f-9fe2-aa5b85531ae2',
    authority: 'https://login.microsoftonline.com/ce9326f2-0f75-4aa5-b87a-7904d106395d',
    //redirectUri: 'http://localhost:3000',
    redirectUri: 'https://test-siatimanager.siatigroup.com',
    responseType: 'd9ef7b0a-fa51-4476-952d-b2d4adcc3385'
  },
  cache: {
    cacheLocation: 'localStorage' as CacheLocation,
    storeAuthStateInCookie: true,
  },
};
const msalInstance = new UserAgentApplication(msalConfig as Configuration);
// MICROSOFT ACCOUNT FINAL

type LoginProps = {
  setEmail: (value: string) => void;
  setImagen: (value: any) => void;
  setPreferredName: (value: string) => void;
  setName: (value: string) => void;
};




const Login = ({setEmail, setImagen, setName, setPreferredName}: LoginProps) => {
  const [isAuthLoading, setAuthLoading] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const dispatch = useDispatch();
  const clientID =
    '120737355509-0ehcfic8t0h95vee04qmu332p867l9eh.apps.googleusercontent.com';
  const navigate = useNavigate();
  const [t] = useTranslation();
  const onFailure = (response: any) => {
    console.log(response);
  };
  useEffect(() => {
    const start = () => {
      gapi.auth2.init({
        client_id: clientID
      });
    };
    gapi.load('client:auth2', start);
  }, []);

  // BOTON GOOGLE

  
  // Funcion Boton inicio WINDOWS
  
  const handleMicrosoftLogin = async () => {
    try {

      const loginResponse = await msalInstance.loginPopup();
      //console.log('Datos que yo enviof:', loginResponse) // Esto es para ver que esta trayendo el response
      if (loginResponse && loginResponse.idToken) {
        const idTokenAsString: string = loginResponse.idToken.preferredName;
        const userEmail = idTokenAsString;
        const token = loginResponse.tenantId;
        //console.log('ESTO ME TRAE LA AUTENTIFICACION: ', loginResponse.idToken) // Esto es el id token
        
        // TOKERIZACION DC INICIO
        fetch('http://localhost:8080/api/usuario/tockenizacion/' + token, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${loginResponse.tenantId}` 
          },
          body: JSON.stringify({ email: userEmail }) 
        })
        .then(response => {
          //console.log('Token enviado al backend'); // esto para saber si esta enviando al backend
        })
        .catch(error => {
          console.error('Error al enviar el token al backend:', error);
        });

        // TOCKERIZACION DC FIN
        setEmail(userEmail);
        localStorage.setItem('idToken', loginResponse.idToken.preferredName);

        setIsLoggedIn(true);
        setUserData(loginResponse);


        dispatch(loginUser(loginResponse.idToken));
        //dispatch(loginUser(JSON.stringify(loginResponse.idToken)));

        toast.success('La autenticación con Microsoft es exitosa');

        navigate('/', { replace: true });
      }
    } catch (error) {
      console.error('Error durante el inicio de sesión con Microsoft:', error);
    }
  };


// Funcion Boton final WINDOWS


  setWindowClass('hold-transition login-page');
  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserData(null);
  };
  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h1">
            <img
              src="/img/SiatiGroupLogo.svg"
              alt="SiatiLogo"
              height={100}
              width={300}
            />
          </Link>
        </div>
        <div className="card-body">
          <p className="login-box-msg">{t<string>('login.label.signIn')}</p>

          <div className="social-auth-links text-center mt-2 mb-3">

            
          </div>
          {/* BOTON MICROSOFT */}
          <div 
          style={{textAlign:'center'}}
          >
            <Button className='my-button' onClick={handleMicrosoftLogin} style={{background:'##212C55', color:'white', borderRadius:'5px'}}>
            <MicrosoftIcon/> Loguéate con Microsoft
            </Button>
          </div>
          
           {/* BOTON MICROSOFT */}
          {/* <p className="mb-0">
            <Link to="/register" className="text-center">
              {t<string>('login.label.registerNew')}
            </Link>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
