import {SmallBox} from '@app/components';
import React, { useEffect, useState } from 'react';
import {ContentHeader} from '@components';
import { BancoApi } from '@app/api/bancos/BancoApi';
import { EmpresaApi } from '@app/api/bancos/EmpresaApi';
import { CuentaApi } from '@app/api/bancos/CuentaApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { RegistroApi } from '@app/api/bancos/RegistroApi';


const Panel = () => {
  const [registros, setRegistros] = useState(0);
  const [registros1, setRegistros1] = useState(0);
  const [registros2, setRegistros2] = useState(0);
  const [registros3, setRegistros3] = useState(0);
  useEffect(() => {
    BancoApi.getBanco1()
    .then(response => setRegistros(response.data))
      .catch(error => console.error(error));
  }, [])
  useEffect(() => {
    EmpresaApi.getEmpresa1()
    .then(response => setRegistros1(response.data))
      .catch(error => console.error(error));
  }, [])
  useEffect(() => {
    CuentaApi.getCuenta1()
    .then(response => setRegistros2(response.data))
      .catch(error => console.error(error));
  }, [])
  useEffect(() => {
    RegistroApi.getRegistro1()
    .then(response => setRegistros3(response.data))
      .catch(error => console.error(error));
  }, [])
  return (
    <div>
      <ContentHeader title="Panel" />

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>{registros}</h3>

                  <p>Bancos</p>
                </div>
                <div className="icon">
                  <i className="fas fa-university" />
                </div>
                <a href="/bank" className="small-box-footer">
                  Más información <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>{registros1}</h3>

                  <p>Empresas</p>
                </div>
                <div className="icon">
                  <i className="fas fa-building" />
                </div>
                <a href="/company" className="small-box-footer">
                Más información <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>{registros2}</h3>

                  <p>Cuentas</p>
                </div>
                <div className="icon">
                <FontAwesomeIcon icon={faWallet} />
                </div>
                <a href="/registry" className="small-box-footer">
                Más información <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>{registros3}</h3>

                  <p>Registros diarios </p>
                </div>
                <div className="icon">
                <FontAwesomeIcon icon={faList} />
                </div>
                <a href="/archivo" className="small-box-footer">
                Más información <i className="fas fa-arrow-circle-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Panel;
