import { api } from "./ConfiguracionBancoApi"
export const ReportePichinchaApi =
{
    getReportePichincha:function()
    {
        return api.get("reportepichincha/pichincha")
    },
    getReportePichinchaId:function(id: number)
    {
        return api.get(`reportepichincha/${id}`)
    },
    createReportePichincha: function(data: any){
        return api.post("reportepichincha", data);
    },
    updateReportePichincha: function(id: number, data: any){
        return api.put(`reportepichincha/${id}`, data);
    },
    deleteReportePichincha: function(id: number){
        return api.delete(`reportepichincha/${id}`);
    }
}