import React, {useEffect, useState} from 'react';
import swal from 'sweetalert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button, Container} from 'react-bootstrap';
import {DataGrid, GridValueFormatterParams} from '@material-ui/data-grid';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import {ReportePacificoApi} from '@app/api/bancos/ReportePacificoApi';
import {Box, CircularProgress, Tooltip} from '@material-ui/core';
import {EmpresaApi} from '@app/api/bancos/EmpresaApi';
import DataGridComponent from '@app/components/DataGridComponent';

interface ReportePacifico {
  idreportepacifico: number;
  fechacontableFormatted: string;
  lugar: string;
  tipo: string;
  nut: number;
  valor: number;
  numero: string;
  concepto: string;
  saldodespmov: number;
  descripcion: string;
  fecharealFormatted: string;
  cuenta: number;
  empresa: string;
}

const ReportePacifico: React.FC = () => {
  const [data, setData] = useState<ReportePacifico[]>([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState<ReportePacifico[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [selectedEmpresa, setSelectedEmpresa] =
    useState<string>('Todas las empresas');
  const [empresas, setEmpresas] = useState<string[]>([]);
  useEffect(() => {
    const fetchEmpresas = async () => {
      try {
        const response = await EmpresaApi.getEmpresa();
        const nombresEmpresas = response.data.map(
          (empresa: any) => empresa.nombre
        );
        setEmpresas(nombresEmpresas);
      } catch (error) {
        console.error('Error al obtener los datos de la empresa:', error);
      }
    };

    fetchEmpresas();
  }, []);
  const handleReset = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSelectedEmpresa('Todas las empresas');
  };



  useEffect(() => {
    fetchData();
  }, []);
  const handleEmpresaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedEmpresa(event.target.value);
  };
  const fetchData = async () => {
    try {
      const response = await ReportePacificoApi.getReportePacifico();
      const dataWithIds = response.data.map((row: ReportePacifico) => ({
        ...row,
        id: row.idreportepacifico
      }));
      setData(dataWithIds);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  const columns = [
    {
      field: 'fechacontableFormatted',
      headerName: 'Fecha Contable',
      width: 200,
    },
    {field: 'lugar', headerName: 'Lugar', width: 150},
    {field: 'tipo', headerName: 'Tipo', width: 110},
    {field: 'nut', headerName: 'Nut', width: 150},
    {field: 'valor', headerName: 'Valor', width: 150},
    {field: 'numero', headerName: 'Número', width: 150},
    {field: 'concepto', headerName: 'Concepto', width: 350},
    {field: 'saldodespmov', headerName: 'Saldo desp. Mov.', width: 150},
    {field: 'descripcion', headerName: 'Descripción', width: 200},
    {
      field: 'fecharealFormatted',
      headerName: 'Fecha Real',
      width: 150,
    },
    {field: 'cuenta', headerName: 'Cuenta', width: 150},
    {field: 'empresa', headerName: 'Empresa', width: 150}
  ];



  return (
    <div>
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        {loading ? (
           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
           <CircularProgress />
         </Box>
        ) : (

          <Box sx={{ height: 500, width: '100%' }}>
            <DataGridComponent
              data={data}
              columns={columns}

            />
          </Box>

        )}
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default ReportePacifico;
