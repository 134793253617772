import { api } from "./ApiConfiguracionIntegrador"

export const PlataformaApi =  {
    getPlataforma:function()
    {
        return api.get("/api/plataforma")
    },
    getPlataformaId:function(id: number)
    {
        return api.get(`/api/plataforma/${id}`)
    },
    createPlataforma: function(data: any){
        return api.post("/api/plataforma", data);
    },
    updatePlataforma: function(id: number, data: any){
        return api.put(`/api/plataforma/${id}`, data);
    },
    deletePlataforma: function(id: number){
        return api.delete(`/api/plataforma/${id}`);
    }
}
