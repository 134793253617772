import { ConfiguracionApiActivosFijos } from "./ConfiguracionApiActivosFijos"

export const MaterialApi =
{
    getMaterial:function()
    {
        return ConfiguracionApiActivosFijos.get("material")
    },
    getMaterialId:function(id: number)
    {
        return ConfiguracionApiActivosFijos.get(`material/${id}`)
    },
    createMaterial: function(data: any){
        return ConfiguracionApiActivosFijos.post("material", data);
    },
    updateMaterial: function(id: number, data: any){
        return ConfiguracionApiActivosFijos.put(`material/${id}`, data);
    },
    deleteMaterial: function(id: number){
        return ConfiguracionApiActivosFijos.delete(`material/${id}`);
    }
}