import { api } from "./ApiConfiguracionIntegrador"

export const EstadoIntegracionFacturaApi = {
    getEstadoIntegracionFactura:function()
    {
        return api.get("/api/estadointegracionfactura")
    },
    getEstadoIntegracionFacturaId:function(id: number)
    {
        return api.get(`/api/estadointegracionfactura/${id}`)
    },
    createEstadoIntegracionFactura: function(data: any){
        return api.post("/api/estadointegracionfactura", data);
    },
    updateEstadoIntegracionFactura: function(id: number, data: any){
        return api.put(`/api/estadointegracionfactura/${id}`, data);
    },
    deleteEstadoIntegracionFactura: function(id: number){
        return api.delete(`/api/estadointegracionfactura/${id}`);
    }
}