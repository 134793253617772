import React, {useEffect, useState} from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button} from 'react-bootstrap';
import {toast} from 'react-toastify';
import DialogBox from '../../../components/DialogBox';
import {MarcaApi} from '@app/api/activosFijos/MarcaApi';
import MarcaTable from './MarcaTable';

interface Marca {
  idmarca: number;
  nombre: string;
  activo: boolean;
}

const Marca: React.FC = () => {
  const [data, setData] = useState<Marca[]>([]);
  const [editingData, setEditingData] = useState<Marca | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activo, setActivo] = useState<boolean>(editingData?.activo || false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseGuardar = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    handleReset();
  };

  const handleReset = () => {
    setEditingData(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await MarcaApi.getMarca();
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  const handleEdit = (row: Marca) => {
    console.log('Editar:', row);
    setEditingData(row);
    handleOpen();
  };

  const handleDelete = (row: Marca) => {
    console.log('Eliminar:', row);
    confirmAlert({
      title: 'Confirmar eliminación',
      message: `¿Estás seguro que deseas eliminar el registro ${row.nombre}?`,
      buttons: [
        {
          label: 'Sí',
          onClick: () => {
            const newData = data.filter(
              (d: Marca) => d.idmarca !== row.idmarca
            );
            setData(newData);
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const handleSave = (row: Marca) => {
    var validacionDatos = '';
    if (row.idmarca) {
      if (row.nombre.length === 0) {
        validacionDatos = 'Marca no puede estar en blanco';
      }
      const actualizacion = data.find((element) => {
        if (
          element.nombre.toUpperCase() === row.nombre.toUpperCase() &&
          element.idmarca !== row.idmarca
        ) {
          validacionDatos = 'Error Datos Duplicado, Valide el Catalogo';
          return true;
        }
      });
      if (validacionDatos.length === 0) {
        MarcaApi.updateMarca(row.idmarca, row)
          .then(() => {
            setData((prevRow) =>
              prevRow.map((u) => (u.idmarca === row.idmarca ? row : u))
            );
            toast.success('Actualización Exitosa');
            handleReset();
            handleCloseGuardar();
          })
          .catch((err) => {
            toast.error('Error de Comunicación, Contacte a TI');
          });
      } else {
        toast.error(validacionDatos);
      }
    } else {
      if (row.nombre.length === 0) {
        validacionDatos = 'Nombre no puede estar en blanco';
      }
      const creacion = data.find((element) => {
        if (element.nombre.toUpperCase() == row.nombre.toUpperCase()) {
          validacionDatos = 'Error Datos Duplicado, Valide el Catalogo';
          return true;
        }
      });
      if (validacionDatos.length === 0) {
        MarcaApi.createMarca(row)
          .then((response) => {
            setData((prevData) => [...prevData, response.data]);
            toast.success('Creación Exitosa');
            handleReset();
            handleCloseGuardar();
          })
          .catch((err) => {
            toast.error('Error de Comunicación, Contacte a TI');
          });
      } else {
        toast.error(validacionDatos);
      }
    }
  };

  const columns: TableColumn<Marca>[] = [
    {name: 'Marca', selector: (row) => row.nombre},
    {
      name: 'Acciones',
      cell: (row: Marca) => (
        <div>
          <button
            onClick={() => handleEdit(row)}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-pen"></i> Editar{' '}
          </button>
          &nbsp;
          <button
            onClick={() => handleDelete(row)}
            className="btn btn-danger btn-sm"
          >
            <i className="fa fa-eraser"></i> Eliminar{' '}
          </button>
        </div>
      )
    }
  ];

  return (
    <div>
      <Button variant="success" onClick={handleOpen}>
        <i className="fa fa-plus"></i> Agregar{' '}
      </Button>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <MarcaTable
          data={data}
          columns={columns}
          customStyles={customStyles}
        />
      )}
      <DialogBox
        open={open}
        onClose={handleClose}
        title={editingData ? 'Editar' : 'Agregar'}
        content={
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const row: Marca = {
                idmarca: editingData?.idmarca || 0,
                nombre: formData.get('nombre') as string,
                activo
              };
              handleSave(row);
            }}
          >
            <div className="form-group" id="nombre">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Marca</span>
                </div>
                <input
                  type="text"
                  name="nombre"
                  className="form-control "
                  defaultValue={editingData?.nombre}
                  placeholder="Ingrese la Marca"
                />
              </div>
            </div>
            <div className="form-group custom-control custom-checkbox">
              <input
                className="custom-control-input"
                type="checkbox"
                checked={activo}
                onChange={(e) => setActivo(e.target.checked)}
                defaultChecked={editingData?.activo}
                id="activo"
              />
              <label htmlFor="activo" className="custom-control-label">
                Activo
              </label>
            </div>

            <div className="modal-footer justify-content-between">
              <Button
                type="button"
                className="btn btn-danger"
                onClick={handleClose}
              >
                Cerrar
              </Button>
              <Button type="submit" className="btn btn-success">
                {editingData ? 'Guardar' : 'Agregar'}
              </Button>
            </div>
          </form>
        }
      />
    </div>
  );
};

const customStyles = {
  headCells: {
    style: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontFamily: 'Arial'
    }
  },
  rows: {
    style: {
      fontSize: '18px'
    }
  },
  cells: {
    style: {
      fontSize: '16px'
    }
  },
  pagination: {
    style: {
      fontSize: '14px'
    }
  }
};

export default Marca;
