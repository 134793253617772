import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import NaturalezaFactura from './NaturalezaFactura';



interface NaturalezaFacturaTableProps {
  data: NaturalezaFactura[];
  columns: TableColumn<NaturalezaFactura>[];
  customStyles: Record<string, any>;
}

const NaturalezaFacturaTable: React.FC<NaturalezaFacturaTableProps> = ({ data, columns, customStyles }) => {
  return (
    <DataTable<NaturalezaFactura>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      noDataComponent={"No hay registros para mostrar."}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      customStyles={customStyles}
    />
  );
};

export default NaturalezaFacturaTable;