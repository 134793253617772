import React, {useEffect, useState} from 'react';
import {TableColumn} from 'react-data-table-component';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button, Modal} from 'react-bootstrap';
import {CabeceraApi} from '@app/api/integradorcontabilidad/CabeceraApi';
import FacturaTable from './FacturaTable';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import DropzoneComponent from '@app/components/DropzoneComponent';
import {ProyectoApi} from '@app/api/integradorcontabilidad/ProyectoApi';
import {PrefacturaApi} from '@app/api/integradorcontabilidad/PrefacturaApi';
import {CamposApi} from '@app/api/integradorcontabilidad/CamposAdicionales';
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper
} from '@mui/material';

interface Factura {
  idprefactura: number;
  fechaactual: string;
  proyecto: string;
  numerofactura: string;
  referencia: string;
  clienteop: string;
  paisorigen: string;
  clientevt: string;
  tipoembarque: string;
  centrocosto: string;
}
interface Header {
  idprefactura: number;
  housenumber: string;
  trafico: number;
  fechaactual: string;
  proyecto: string;
  numerofactura: string;
  referencia: string;
  clienteop: string;
  paisorigen: string;
  clientevt: string;
  tipoembarque: string;
  centrocosto: string;
  incoterm: string;
}
interface Detail {
  idcargo: number;
  idprefactura: number;
  tipocontable: string;
  codigoventa: string;
  descripcionventa: string;
  cantidadventa: number;
  preciounitarioventa: string;
  moneda: string;
  cambio: string;
  totalcosto: string;
  codigocosto: string;
  totalventa: string;
  descripcioncosto: string;
  margen: string;
}
interface Campo {
  id: number;
  idproyecto: number;
  nombreInterno: string;
  nombreVisual: string;
  valorCampo: string;
  valorCalculado: number;
}
interface Proyecto {
  idproyecto: number;
  fechacreacion: string;
  codigonaturaleza: number;
  nombrenaturaleza: string;
  nombremodalidad: string;
  nombrepuertoembarque: string;
  nombretrafico: string;
  numeroproyecto: string;
  masternumber: string;
  numerohija: number;
  nombrepaisorigen: string;
  codigopaisorigen: string;
  nombrepaisdestino: string;
  codigopaisdestino: string;
  rucclienteoperativo: number;
  rucclienteventas: number;
  cbm: number;
  nombreagente: string;
  pesototal: number;
  nombreunidadpeso: string;
  volumentotal: number;
  nombreunidadvolumen: string;
  pesofacturado: number;
  volumenpesototal: number;
  items: number;
  valorunitario: number;
  codigosucursal: string;
  nombresucursal: string;
  codigolineaservicio: number;
  lineaservicionombrecorto: string;
  lineaservicionombrelargo: string;
  contenedores20tn: number;
  contenedores40tn: number;
  contenedores45tn: number;
  contenedorestanque: number;
  numerocontenedores: number;
  teus: number;
}

const Factura: React.FC = () => {
  const separadorStyle = {
    width: '100%',
    height: '2px',
    backgroundColor: 'navy',
    margin: '20px 0',
    fontSize: '18px',
    color: 'red'
  };

  const customSwalStyles = `
  .my-swal-container {
    z-index: 2000;
  }

  .swal2-container {
    z-index: 2001;
  }

  .swal2-popup {
    position: relative;
  }
`;
  const [data, setData] = useState<Factura[]>([]);
  const [loading, setLoading] = useState(true);
  const [factura, setFactura] = useState([]);
  const [cabeceras, setCabeceras] = useState<Header[]>([]);
  const [selectedFactura, setSelectedFactura] = useState<number | null>(null);
  const [selectedFactura1, setSelectedFactura1] = useState<number | number>(0);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedCabecera, setSelectedCabecera] = useState<Header | null>(null);
  const [selectedProyecto, setSelectedProyecto] = useState<Proyecto | null>(
    null
  );
  const [selectedCamposTabla, setSelectedCamposTabla] = useState<Campo[]>([]);

  const [selectedProyectoNumber, setSelectedProyectoNumber] = useState<
    string | string
  >('');
  const [selectedProyectoId, setSelectedProyectoId] = useState<number | number>(
    0
  );
  const [detalleList, setDetalleList] = useState<Detail[]>([]);
  const [open, setOpen] = useState(false);
  const [abrir, setAbrir] = useState(false);
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  useEffect(() => {
    const updateDate = () => {
      setCurrentDate(new Date());
    };

    const interval = setInterval(updateDate, 86400000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const formattedDate = currentDate.toISOString().split('T')[0];
  const handleOpen = () => {
    setOpen(true);
  };
  const handleAbrir = () => {
    setAbrir(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCerrar = () => {
    setAbrir(false);
  };


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await CabeceraApi.getCabecera();
      setData(response.data);
      setFactura(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };
  const handleActualizarEstado = async (idprefactura: number) => {
    try {
      const styleElement = document.createElement('style');
      styleElement.textContent = customSwalStyles;
      document.head.appendChild(styleElement);
      const result = await Swal.fire({
        title: 'Actualizar Estado',
        text: 'Está seguro que desea actualizar el estado de integración?',
        icon: 'question',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: 'Aprobar',
        denyButtonText: 'Denegar',
        customClass: {
          confirmButton: 'btn btn-success mr-2',
          denyButton: 'btn btn-danger',
          popup: 'swal2-popup-custom',
          container: 'my-swal-container'
        }
      });
      if (result.isConfirmed && idprefactura !== null) {
        const EstadoPrefactura = {
          idprefactura: selectedFactura,
          idestadointegracionprefactura: 4
        };

        await PrefacturaApi.updatePrefactura(EstadoPrefactura);
        Swal.fire({
          title: 'Estado Actualizado',
          text: 'El estado de integración ha sido actualizado correctamente.',
          icon: 'success'
        }).then(() => {
          handleClose();
        });
      } else if (result.isDenied) {
        Swal.fire('Denegado', 'La actualización ha sido denegada.', 'error');
      }
    } catch (error) {
      console.error('Error al actualizar el estado:', error);
      Swal.fire('Error', 'Ocurrió un error al actualizar el estado.', 'error');
    }
  };
  useEffect(() => {
    CabeceraApi.getCabecera()
      .then((response) => {
        setCabeceras(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener las cabeceras de factura:', error);
      });
  }, []);
  const obtenerCampos = (proyectoId: number) => {
    CamposApi.getCamposId(136)
      .then((responseCampos) => {
        setSelectedCamposTabla(responseCampos.data);
      })
      .catch((error) => {
        console.error('Error al obtener los campos:', error);
      });
  };
  const handleVerProyecto = (proyectoNumber: string) => {
    handleAbrir();
    ProyectoApi.getProyectoNumero(proyectoNumber)
      .then((responseProject) => {
        setSelectedProyecto(responseProject.data);
        setSelectedProyectoId(responseProject.data.idproyecto);
        obtenerCampos(selectedProyectoId);
      })
      .catch((error) => {
        console.error('Error al obtener el detalle del proyecto:', error);
      });
  };

  const handleVerDetalle = (facturaId: number) => {
    handleOpen();
    CabeceraApi.getCabeceraId(facturaId)
      .then((response) => {
        setDetalleList(response.data.detalleList);
        setSelectedFactura(facturaId);
        setSelectedFactura1(facturaId);
        const selectedHeader = cabeceras.find(
          (header) => header.idprefactura === facturaId
        );
        setSelectedCabecera(selectedHeader || null);
        setSelectedProyectoNumber(response.data.proyecto);
      })
      .catch((error) => {
        console.error('Error al obtener el detalle de la factura:', error);
      });
  };

  const handleExportPDF = () => {
    const invoiceContent = document.getElementById('invoice-content');

    if (invoiceContent) {
      const contentWidth = invoiceContent.offsetWidth;
      const contentHeight = invoiceContent.offsetHeight;
      const canvas = document.createElement('canvas');
      canvas.width = contentWidth;
      canvas.height = contentHeight;
      const context = canvas.getContext('2d');
      html2canvas(invoiceContent, {canvas}).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (contentHeight / contentWidth) * pdfWidth;
        pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth - 20, pdfHeight - 20);
        try {
          pdf.save(`factura_magaya_#${selectedFactura}.pdf`);
        } catch (error) {
          console.error('Error al generar el PDF:', error);
          alert('Error al generar el PDF');
        }
      });
    } else {
      console.error('El contenido para el PDF no está presente.');
      alert('No hay contenido para exportar a PDF.');
    }
  };

  const columns: TableColumn<Factura>[] = [
    {name: 'Fecha', selector: (row) => formattedDate},
    {name: 'Proyecto', selector: (row) => row.proyecto},
    {name: 'Cliente', selector: (row) => row.clienteop},
    {name: 'Origen', selector: (row) => row.paisorigen},
    {name: 'Tipo', selector: (row) => row.tipoembarque},
    {
      name: 'Acciones',
      cell: (row: Factura) => (
        <div>
          <Button
            className="btn btn-primary btn-sm"
            onClick={() => handleVerDetalle(row.idprefactura)}
          >
            <i className="fa fa-eye" aria-hidden="true">
              {' '}
            </i>
            &nbsp; Ver Detalle
          </Button>
        </div>
      )
    }
  ];
  return (
    <div>
      <h1>Listado de Facturas</h1>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
        <DialogTitle>
          <h4>
            House number: <b>#{selectedCabecera?.housenumber}</b> &nbsp; &nbsp;
            <Button
              onClick={() => handleVerProyecto(selectedProyectoNumber)}
              variant="info"
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
              &nbsp; Proyecto <b>{selectedCabecera?.proyecto}</b>
            </Button>
          </h4>
        </DialogTitle>
        {selectedCabecera?.trafico === 1 && (
          <div className="row">
            <div className="col-1"></div>
            <div className="col-10">
              <div className="callout callout-danger">
                <h5>
                  <i className="fas fa-info"></i> <b>Nota:</b>
                </h5>
                Una vez aprobado este requerimiento, el sistema contable,
                procederá con la facturación automática.{' '}
              </div>
            </div>
          </div>
        )}
        {selectedCabecera?.trafico === 2 && (
          <div className="row">
            <div className="col-1"></div>
            <div className="col-10">
              <div className="callout callout-danger">
                <h5>
                  <i className="fas fa-info"></i> <b>Nota:</b>
                </h5>
                Una vez aprobado este requerimiento, el sistema contable,
                procederá con la facturación automática. Es necesario cargar los
                documentos de soporte, previo la aprobación del requerimiento.
              </div>
            </div>
          </div>
        )}

        <DialogContent>
          <section className="content">
            {selectedFactura && (
              <div>
                <div className="invoice p-3 mb-3" id="invoice-content">
                  <div className="row">
                    <div className="col-12">
                      <small className="float-right">CÓDIGO: FTE-26 </small>
                      <br />
                      <small className="float-right">VERSIÓN: 07 </small>
                      <br />
                      <small className="float-right">
                        FECHA VIGENCIA: 20-03-2018
                      </small>
                      <img
                        src="/img/logoSiatigroup.png"
                        alt="SiatiLogo-importaciones-Exportaciones"
                        height={100}
                        width={400}
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <h4>
                      <b>REQUERIMIENTO DE FACTURACIÓN IMPORT-EXPORT TGL</b>
                    </h4>
                  </div>

                  <div style={separadorStyle}></div>
                  <div className="row invoice-info">
                    <div className="col-sm-4 invoice-col">
                      <address>
                        <h5>
                          <strong>FECHA ACTUAL:</strong> {formattedDate}
                        </h5>
                        <h5>
                          <strong>PROYECTO:</strong>{' '}
                          {selectedCabecera?.proyecto}
                        </h5>

                        <h5>
                          <strong>CLIENTE OP:</strong>{' '}
                          {selectedCabecera?.clienteop}
                        </h5>

                        <h5>
                          <strong>CLIENTE VT:</strong>{' '}
                          {selectedCabecera?.clientevt}
                        </h5>

                        <h5>
                          <strong>TIPO EMBARQUE:</strong>{' '}
                          {selectedCabecera?.tipoembarque}
                        </h5>
                      </address>
                      <br />
                    </div>

                    <div className="col-sm-7 invoice-col">
                      <h5>
                        <strong>N° FACTURA:</strong>{' '}
                        {selectedCabecera?.numerofactura}
                      </h5>
                      <h5>
                        <strong>PAIS ORIGEN:</strong>{' '}
                        {selectedCabecera?.paisorigen}
                      </h5>

                      <h5>
                        <strong>INCOTERM:</strong> {''}
                        {selectedCabecera?.incoterm}
                      </h5>

                      <h5>
                        <strong>CENTRO DE COSTO:</strong>{' '}
                        {selectedCabecera?.centrocosto}
                      </h5>

                      <h5>
                        <strong>REFERENCIA:</strong>{' '}
                        {selectedCabecera?.referencia}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 table">
                      <div style={separadorStyle}></div>
                      <h5>
                        <b>1. Flete / Servicios en Origen</b>
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Código</th>
                            <th style={{width: '30%'}}>Descripción</th>
                            <th style={{width: '15%'}}>Moneda</th>
                            <th style={{width: '20%'}} className="text-right">
                              Tasa de cambio
                            </th>
                            <th style={{width: '17%'}} className="text-right">
                              Total Costos{' '}
                            </th>
                            <th style={{width: '15%'}} className="text-right">
                              Total Venta
                            </th>
                            <th style={{width: '10%'}} className="text-right">
                              Margen
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {detalleList.map((detalleItem: Detail) => {
                            if (
                              detalleItem.tipocontable === 'Flete' ||
                              detalleItem.tipocontable === 'Origen'
                            ) {
                              return (
                                <tr key={detalleItem.idcargo}>
                                  <td>{detalleItem.codigoventa}</td>
                                  <td>{detalleItem.descripcionventa}</td>
                                  <td>{detalleItem.moneda}</td>
                                  <td className="text-right">
                                    {detalleItem.cambio}
                                  </td>
                                  <td className="text-right">
                                    ${detalleItem.totalcosto}
                                  </td>
                                  <td className="text-right">
                                    ${detalleItem.totalventa}
                                  </td>
                                  <td className="text-right">
                                    ${detalleItem.margen}
                                  </td>
                                </tr>
                              );
                            }
                            return null;
                          })}
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="text-right">
                              <b>
                                $
                                {detalleList
                                  .filter(
                                    (detalleItem) =>
                                      detalleItem.tipocontable === 'Flete' ||
                                      detalleItem.tipocontable === 'Origen'
                                  )
                                  .reduce(
                                    (total, detalleItem) =>
                                      total +
                                      parseFloat(detalleItem.totalcosto),
                                    0
                                  )
                                  .toFixed(2)}
                              </b>
                            </td>
                            <td className="text-right">
                              <b>
                                $
                                {detalleList
                                  .filter(
                                    (detalleItem) =>
                                      detalleItem.tipocontable === 'Flete' ||
                                      detalleItem.tipocontable === 'Origen'
                                  )
                                  .reduce(
                                    (total, detalleItem) =>
                                      total +
                                      parseFloat(detalleItem.totalventa),
                                    0
                                  )
                                  .toFixed(2)}
                              </b>
                            </td>
                            <td className="text-right">
                              <b>
                                $
                                {detalleList
                                  .filter(
                                    (detalleItem) =>
                                      detalleItem.tipocontable === 'Flete' ||
                                      detalleItem.tipocontable === 'Origen'
                                  )
                                  .reduce(
                                    (total, detalleItem) =>
                                      total + parseFloat(detalleItem.margen),
                                    0
                                  )
                                  .toFixed(2)}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td
                              style={{
                                width: '30%',
                                fontSize: '18px',
                                color: 'red'
                              }}
                            >
                              <b>Margen </b>
                            </td>
                            <td style={{fontSize: '18px', color: 'red'}}>
                              <b>
                                {
                                  (
                                    (detalleList
                                      .filter(
                                        (detalleItem) =>
                                          detalleItem.tipocontable ===
                                            'Flete' ||
                                          detalleItem.tipocontable === 'Origen'
                                      )
                                      .reduce(
                                        (total, detalleItem) =>
                                          total +
                                          parseFloat(detalleItem.margen),
                                        0
                                      ) /
                                      detalleList
                                        .filter(
                                          (detalleItem) =>
                                            detalleItem.tipocontable ===
                                              'Flete' ||
                                            detalleItem.tipocontable ===
                                              'Origen'
                                        )
                                        .reduce(
                                          (total, detalleItem) =>
                                            total +
                                            parseFloat(detalleItem.totalventa),
                                          0
                                        )) *
                                    100
                                  ).toFixed(2) + '%' // Agregar el símbolo de porcentaje al final
                                }
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="row">
                        <div className="col-12 table">
                          <h5>
                            <b>2. Servicios Locales</b>
                          </h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="table">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Código</th>
                                <th style={{width: '30%'}}>Descripción</th>
                                <th style={{width: '15%'}}>Moneda</th>
                                <th
                                  style={{width: '20%'}}
                                  className="text-right"
                                >
                                  Tasa de cambio
                                </th>
                                <th
                                  style={{width: '15%'}}
                                  className="text-right"
                                >
                                  Total Costos{' '}
                                </th>
                                <th
                                  style={{width: '15%'}}
                                  className="text-right"
                                >
                                  Total Venta
                                </th>
                                <th
                                  style={{width: '10%'}}
                                  className="text-right"
                                >
                                  Margen
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {detalleList.map((detalleItem1: Detail) => {
                                if (
                                  detalleItem1.tipocontable === 'Local' ||
                                  detalleItem1.tipocontable === 'Destino'
                                ) {
                                  return (
                                    <tr key={detalleItem1.idcargo}>
                                      <td>{detalleItem1.codigoventa}</td>
                                      <td>{detalleItem1.descripcionventa}</td>
                                      <td>{detalleItem1.moneda}</td>
                                      <td className="text-right">
                                        {detalleItem1.cambio}
                                      </td>
                                      <td className="text-right">
                                        ${detalleItem1.totalcosto}
                                      </td>
                                      <td className="text-right">
                                        ${detalleItem1.totalventa}
                                      </td>
                                      <td className="text-right">
                                        ${detalleItem1.margen}{' '}
                                      </td>
                                    </tr>
                                  );
                                }
                                return null;
                              })}
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="text-right">
                                  <b>
                                    $
                                    {detalleList
                                      .filter(
                                        (detalleItem1) =>
                                          detalleItem1.tipocontable ===
                                            'Local' ||
                                          detalleItem1.tipocontable ===
                                            'Destino'
                                      )
                                      .reduce(
                                        (total, detalleItem) =>
                                          total +
                                          parseFloat(detalleItem.totalcosto),
                                        0
                                      )
                                      .toFixed(2)}
                                  </b>
                                </td>
                                <td className="text-right">
                                  <b>
                                    $
                                    {detalleList
                                      .filter(
                                        (detalleItem1) =>
                                          detalleItem1.tipocontable ===
                                            'Local' ||
                                          detalleItem1.tipocontable ===
                                            'Destino'
                                      )
                                      .reduce(
                                        (total, detalleItem1) =>
                                          total +
                                          parseFloat(detalleItem1.totalventa),
                                        0
                                      )
                                      .toFixed(2)}
                                  </b>
                                </td>
                                <td className="text-right">
                                  <b>
                                    $
                                    {detalleList
                                      .filter(
                                        (detalleItem1) =>
                                          detalleItem1.tipocontable ===
                                            'Local' ||
                                          detalleItem1.tipocontable ===
                                            'Destino'
                                      )
                                      .reduce(
                                        (total, detalleItem1) =>
                                          total +
                                          parseFloat(detalleItem1.margen),
                                        0
                                      )
                                      .toFixed(2)}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{fontSize: '18px', color: 'red'}}>
                                  <b>Margen </b>
                                </td>
                                <td
                                  className="text-right"
                                  style={{fontSize: '18px', color: 'red'}}
                                >
                                  <b>
                                    {(
                                      (detalleList
                                        .filter(
                                          (detalleItem1) =>
                                            detalleItem1.tipocontable ===
                                              'Local' ||
                                            detalleItem1.tipocontable ===
                                              'Destino'
                                        )
                                        .reduce(
                                          (total, detalleItem1) =>
                                            total +
                                            parseFloat(detalleItem1.margen),
                                          0
                                        ) /
                                        detalleList
                                          .filter(
                                            (detalleItem1) =>
                                              detalleItem1.tipocontable ===
                                                'Local' ||
                                              detalleItem1.tipocontable ===
                                                'Destino'
                                          )
                                          .reduce(
                                            (total, detalleItem1) =>
                                              total +
                                              parseFloat(
                                                detalleItem1.totalventa
                                              ),
                                            0
                                          )) *
                                      100
                                    ).toFixed(2) + '%'}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{fontSize: '16px', color: 'red'}}>
                                  <b>MARGEN DEL PROYECTO</b>
                                </td>
                                <td
                                  className="text-right"
                                  style={{fontSize: '16px', color: 'red'}}
                                >
                                  <b>
                                    {(
                                      (detalleList.reduce(
                                        (total, detalleItem1) =>
                                          total +
                                          parseFloat(detalleItem1.margen),
                                        0
                                      ) /
                                        detalleList.reduce(
                                          (total, detalleItem1) =>
                                            total +
                                            parseFloat(detalleItem1.totalventa),
                                          0
                                        )) *
                                      100
                                    ).toFixed(2)}
                                    %
                                  </b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <Dialog
                        open={abrir}
                        onClose={handleCerrar}
                        fullWidth
                        maxWidth="xl"
                      >
                        <DialogTitle></DialogTitle>
                        <DialogContent>
                          <section className="content">
                            <div
                              className="invoice p-3 mb-3"
                              id="invoice-content1"
                            >
                              <img
                                src="/img/logoSiatigroup.png"
                                alt="SiatiLogo-importaciones-Exportaciones"
                                height={70}
                                width={300}
                              />
                              <div className="text-center">
                                <h4>
                                  <b>
                                    INFORMACIÓN CORRESPONDIENTE AL PROYECTO:{' '}
                                    <b>{selectedProyecto?.numeroproyecto}</b>
                                  </b>
                                </h4>
                                <h5>
                                  <strong>FECHA DE CREACIÓN:</strong>{' '}
                                  {selectedProyecto?.fechacreacion ? formatDate(selectedProyecto.fechacreacion) : '-'}
                                </h5>
                              </div>

                              <div style={separadorStyle}></div>
                              <div className="row invoice-info">
                                <div className="text-center">
                                  <h5>
                                    <b>DATOS DE CLIENTE</b>
                                  </h5>
                                </div>
                                <br />
                                <div className="col-sm-4 invoice-col">
                                  <br />
                                  <h6>
                                    <strong>RUC CLIENTE OP:</strong>{' '}
                                    {selectedProyecto?.rucclienteoperativo}
                                  </h6>

                                  <h6>
                                    <strong>RUC CLIENTE VT:</strong>{' '}
                                    {selectedProyecto?.rucclienteventas}
                                  </h6>

                                  <h6>
                                    <strong>CBM:</strong>{' '}
                                    {selectedProyecto?.cbm}
                                  </h6>
                                </div>
                              </div>

                              <div style={separadorStyle}></div>
                              <div className="row invoice-info">
                                <div className="text-center">
                                  <h5>
                                    <b>DATOS DE EMBARQUE</b>
                                  </h5>
                                </div>

                                <div className="col-sm-7 invoice-col">
                                  <h6>
                                    <strong>VALOR UNITARIO :</strong>{' '}
                                    {selectedProyecto?.valorunitario}
                                  </h6>
                                  <h6>
                                    <strong>NÚMERO DE LA MASTER:</strong>{' '}
                                    {selectedProyecto?.masternumber}
                                  </h6>
                                  <h6>
                                    <strong>NÚMERO DE LA HIJA:</strong>{' '}
                                    {selectedProyecto?.numerohija}
                                  </h6>
                                  <h6>
                                    <strong>TOTAL PESO:</strong>{' '}
                                    {selectedProyecto?.pesototal}{' '}
                                    {selectedProyecto?.nombreunidadpeso}
                                  </h6>

                                  <h6>
                                    <strong>VOLUMEN TOTAL:</strong>{' '}
                                    {selectedProyecto?.volumentotal}{' '}
                                    {selectedProyecto?.nombreunidadvolumen}
                                  </h6>
                                  <h6>
                                    <strong>PESO FACTURADO:</strong>{' '}
                                    {selectedProyecto?.pesofacturado}{' '}
                                    {selectedProyecto?.nombreunidadpeso}
                                  </h6>
                                  <h6>
                                    <strong>VOLUMEN PESO TOTAL:</strong>{' '}
                                    {selectedProyecto?.volumenpesototal}{' '}
                                    {selectedProyecto?.nombreunidadvolumen}
                                  </h6>
                                </div>

                                <div className="col-sm-4 invoice-col">
                                  <h6>
                                    <strong>ITEMS:</strong>{' '}
                                    {selectedProyecto?.items}
                                  </h6>
                                  <h6>
                                    <strong>CONTENEDOR DE 20 TONELADAS:</strong>{' '}
                                    {selectedProyecto?.contenedores20tn}
                                  </h6>
                                  <h6>
                                    <strong>CONTENEDOR DE 40 TONELADAS:</strong>{' '}
                                    {selectedProyecto?.contenedores40tn}
                                  </h6>

                                  <h6>
                                    <strong>CONTENEDOR DE 45 TONELADAS:</strong>{' '}
                                    {selectedProyecto?.contenedores45tn}
                                  </h6>
                                  <h6>
                                    <strong>CONTENEDORES TANQUE:</strong>{' '}
                                    {selectedProyecto?.contenedorestanque}
                                  </h6>
                                  <h6>
                                    <strong>NÚMERO DE CONTENEDORES:</strong>{' '}
                                    {selectedProyecto?.numerocontenedores}
                                  </h6>
                                  <h6>
                                    <strong>TEUS:</strong>{' '}
                                    {selectedProyecto?.contenedores20tn}
                                  </h6>
                                </div>
                              </div>
                              <div style={separadorStyle}></div>
                              <div className="text-center">
                                <h5>
                                  <b>DATOS DE LOGÍSTICA</b>
                                </h5>
                              </div>
                              <br />
                              <div className="row invoice-info">
                                <div className="col-sm-7 invoice-col">
                                  <h6>
                                    <strong>DESTINO:</strong>{' '}
                                    {selectedProyecto?.nombrepaisdestino}
                                  </h6>
                                  <h6>
                                    <strong>CÓDIGO DESTINO:</strong>{' '}
                                    {selectedProyecto?.codigopaisdestino}
                                  </h6>
                                  <h6>
                                    <strong>LÍNEA DE SERVICIO CÓDIGO:</strong>{' '}
                                    {selectedProyecto?.codigolineaservicio}
                                  </h6>
                                  <h6>
                                    <strong>
                                      LÍNEA DE SERVICIO NOMBRE CORTO:
                                    </strong>{' '}
                                    {selectedProyecto?.lineaservicionombrecorto}
                                  </h6>
                                  <h6>
                                    <strong>
                                      LÍNEA DE SERVICIO NOMBRE LARGO:
                                    </strong>{' '}
                                    {selectedProyecto?.lineaservicionombrelargo}
                                  </h6>
                                </div>
                                <div className="col-sm-5 invoice-col">
                                  <h6>
                                    <strong>NATURALEZA:</strong>{' '}
                                    {selectedProyecto?.nombrenaturaleza}
                                  </h6>
                                  <h6>
                                    <strong>MODALIDAD:</strong>{' '}
                                    {selectedProyecto?.nombremodalidad}
                                  </h6>
                                  <h6>
                                    <strong>EMBARQUE:</strong>{' '}
                                    {selectedProyecto?.nombrepuertoembarque}
                                  </h6>
                                  <h6>
                                    <strong>TRÁFICO:</strong>{' '}
                                    {selectedProyecto?.nombretrafico}
                                  </h6>

                                  <h6>
                                    <strong>ORIGEN:</strong>{' '}
                                    {selectedProyecto?.nombrepaisorigen}
                                  </h6>
                                  <h6>
                                    {' '}
                                    <strong>CÓDIGO ORIGEN:</strong>{' '}
                                    {selectedProyecto?.codigopaisorigen}
                                  </h6>
                                </div>
                                <div className="row invoice-info">
                                  <h3>Custom file</h3>
                                  <TableContainer component={Paper}>
                                    <Table aria-label="Vertical Data Table">
                                      <TableBody>
                                        {selectedCamposTabla.map((campo) => (
                                          <TableRow key={campo.id}>
                                            <TableCell>
                                              {campo.nombreVisual}
                                            </TableCell>
                                            <TableCell align="left">
                                            {campo.valorCampo === 'None' ? '-' : campo.valorCampo}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </div>
                              </div>
                            </div>
                          </section>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCerrar} variant="danger">
                            <i
                              className="fa fa-times-circle"
                              aria-hidden="true"
                            ></i>
                            &nbsp; Cerrar
                          </Button>                         
                        </DialogActions>
                      </Dialog>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
          <div className="row">
            {selectedCabecera?.trafico === 2 && <DropzoneComponent />}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="danger">
            <i className="fa fa-times-circle" aria-hidden="true"></i>
            &nbsp; Cerrar
          </Button>
          <Button
            onClick={() => handleActualizarEstado(selectedFactura1)}
            variant="success"
          >
            <i className="fa fa-check-circle" aria-hidden="true"></i>
            &nbsp; Aprobación
          </Button>
          <Button onClick={handleExportPDF} variant="primary">
            <i className=" fa fa-file-pdf" aria-hidden="true"></i>
            &nbsp; Exportar a PDF
          </Button>
        </DialogActions>
      </Dialog>

      {loading ? (
        <p>Cargando...</p>
      ) : (
        <FacturaTable
          data={data}
          columns={columns}
          customStyles={customStyles}
        />
      )}
    </div>
  );
};

const customStyles = {
  headCells: {
    style: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontFamily: 'Arial'
    }
  },
  rows: {
    style: {
      fontSize: '18px'
    }
  },
  cells: {
    style: {
      fontSize: '16px'
    }
  },
  pagination: {
    style: {
      fontSize: '14px'
    }
  },
  style: (column: {name: string}) => {
    if (column.name === 'Margen') {
      return {
        color: 'red'
      };
    }
    return {};
  }
};

export default Factura;
