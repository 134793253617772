import React, { useEffect, useRef, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  IconButton,
  TableContainer,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import DialogBox from "../../components/DialogBox";
import EditIcon from "@mui/icons-material/EditTwoTone";
import CloseIcon from "@mui/icons-material/CloseTwoTone";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import UserIcon from "@mui/icons-material/SaveOutlined";
import PersonIcon from "@mui/icons-material/AccountCircle";
import { PerfilApi } from "@app/api/configurardorPrincipal/PerfilApi";
import PerfilTable from "./PerfilTable";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";

interface Perfil {
  idperfil: number;
  nombre: string;
  observacion: string;
  activo: boolean;
}

const Perfil: React.FC = () => {
  const [data, setData] = useState<Perfil[]>([]);
  const [editingData, setEditingData] = useState<Perfil | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validar, setValidar] = React.useState({ rucValor: "" });
  const [activo, setActivo] = useState<boolean>(editingData?.activo || false);
  const validarInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidar({ ...validar, [e.target.name]: e.target.value });
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseGuardar = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    handleReset();
  };

  const handleReset = () => {
    setEditingData(null);
  };
  const roundedCardStyle = {
    borderRadius: "10px",
    overflow: "hidden",
  };
  const titleTextStyle = {
    borderBottom: "2px solid tomato",
    paddingBottom: "10px",
    marginBottom: "15px",
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await PerfilApi.getPerfil();
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const handleEdit = (row: Perfil) => {
    console.log("Editar:", row);
    setEditingData(row);
    handleOpen();
  };

  const handleDelete = (row: Perfil) => {
    console.log("Eliminar:", row);
    confirmAlert({
      title: "Confirmar eliminación",
      message: `¿Estás seguro que deseas eliminar el registro ${row.nombre}?`,
      buttons: [
        {
          label: "Sí",
          onClick: () => {
            const newData = data.filter(
              (d: Perfil) => d.idperfil !== row.idperfil
            );
            setData(newData);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSave = (row: Perfil) => {
    var validacionDatos = "";
    if (row.idperfil) {
      if (row.nombre.length === 0) {
        validacionDatos = "Nombre no puede estar en blanco";
      }
      const actualizacion = data.find((element) => {
        if (
          element.nombre.toUpperCase() === row.nombre.toUpperCase() &&
          element.idperfil !== row.idperfil
        ) {
          validacionDatos = "Error Datos Duplicado, Valide el Catalogo";
          return true;
        }
      });
      if (validacionDatos.length === 0) {
        PerfilApi.updatePerfil(row.idperfil, row)
          .then(() => {
            setData((prevRow) =>
              prevRow.map((u) => (u.idperfil === row.idperfil ? row : u))
            );
            toast.success("Actualización Exitosa");
            handleReset();
            handleCloseGuardar();
          })
          .catch((err) => {
            toast.error("Error de Comunicación, Contacte a TI");
          });
      } else {
        toast.error(validacionDatos);
      }
    } else {
      if (row.nombre.length === 0) {
        validacionDatos = "Nombre no puede estar en blanco";
      }
      const creacion = data.find((element) => {
        if (element.nombre.toUpperCase() == row.nombre.toUpperCase()) {
          validacionDatos = "Error Datos Duplicado, Valide el Catalogo";
          return true;
        }
      });
      if (validacionDatos.length === 0) {
        PerfilApi.createPerfil(row)
          .then((response) => {
            setData((prevData) => [...prevData, response.data]);
            toast.success("Creación Exitosa");
            handleReset();
            handleCloseGuardar();
          })
          .catch((err) => {
            toast.error("Error de Comunicación, Contacte a TI");
          });
      } else {
        toast.error(validacionDatos);
      }
    }
  };

  const columns: TableColumn<Perfil>[] = [
    { name: "Perfil", selector: (row) => row.nombre },
    { name: "Detalle", selector: (row) => row.observacion },
    {
      name: "Acciones",
      cell: (row: Perfil) => (
        <div>
          <IconButton
            onClick={() => handleEdit(row)}
            style={{ color: "#212C55" }}
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      <IconButton onClick={handleOpen} style={{ color: "#212C55" }}>
        <AddIcon />
      </IconButton>

      {loading ? (
        <p>Cargando...</p>
      ) : (
        <>
          <TableContainer>
            <PerfilTable
              data={data}
              columns={columns}
              customStyles={customStyles}
            />
          </TableContainer>
        </>
      )}

      <DialogBox
        open={open}
        onClose={handleClose}
        content={
          <Card style={roundedCardStyle}>
            <CardContent className="card-body">
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                style={titleTextStyle}
              >
                {editingData ? "Editar" : "Crear"} Perfil <PersonIcon/>

              </Typography>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const row: Perfil = {
                    idperfil: editingData?.idperfil || 0,
                    nombre: formData.get("nombre") as string,
                    observacion: formData.get("observacion") as string,
                    activo,
                  };
                  handleSave(row);
                }}
              >
                <div className="form-group" id="nombre">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-user-circle" aria-hidden="true"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      name="nombre"
                      className="form-control "
                      defaultValue={editingData?.nombre}
                      placeholder="Ingrese el nombre del perfil"
                      required
                    />
                  </div>
                </div>
                <div className="form-group" id="observacion">
                  <label>Observaciones</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      </span>
                    </div>
                    <textarea
                      name="observacion"
                      className="form-control"
                      defaultValue={editingData?.observacion}
                      required
                      title="Ingrese las oservaciones del rol"
                    />
                  </div>
                </div>
                <div className="form-group custom-control custom-checkbox">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    checked={activo}
                    onChange={(e) => setActivo(e.target.checked)}
                    defaultChecked={editingData?.activo}
                    id="activo"
                  />
                  <label htmlFor="activo" className="custom-control-label">
                    Activo
                  </label>
                </div>

                <div className="card-footer">
                  <ButtonGroup size="lg" className="mb-2">
                    <IconButton onClick={handleClose} style={{ color: "red" }}>
                      <CloseIcon />
                    </IconButton>
                    <span style={{ margin: "0 70px" }}>&nbsp;</span>

                    <IconButton type="submit" style={{ color: "#212C55" }}>
                      {editingData ? <SaveIcon /> : <UserIcon />}
                    </IconButton>
                  </ButtonGroup>
                </div>
              </form>
            </CardContent>
          </Card>
        }
      />
    </div>
  );
};

const customStyles = {
  headCells: {
    style: {
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "Arial",
    },
  },
  rows: {
    style: {
      fontSize: "18px",
    },
  },
  cells: {
    style: {
      fontSize: "16px",
    },
  },
  pagination: {
    style: {
      fontSize: "14px",
    },
  },
};

export default Perfil;
