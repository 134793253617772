import React from 'react';
import { Snackbar } from '@mui/material';

interface NotificationProps {
  message: string;
  onClose: () => void;
}

function Notification({ message, onClose }: NotificationProps) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={message !== ''}
      autoHideDuration={5000}
      onClose={onClose}
      message={message}
    />
  );
}

export default Notification;