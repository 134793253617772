import { api } from "./ConfiguracionBancoApi"
export const ReportePacificoApi =
{
    getReportePacifico:function()
    {
        return api.get("reportepacifico")
    },
    getReportePacificoId:function(id: number)
    {
        return api.get(`reportepacifico/${id}`)
    },
    createReportePacifico: function(data: any){
        return api.post("reportepacifico", data);
    },
    updateReportePacifico: function(id: number, data: any){
        return api.put(`reportepacifico/${id}`, data);
    },
    deleteReportePacifico: function(id: number){
        return api.delete(`reportepacifico/${id}`);
    }
}