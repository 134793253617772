import UsuarioApi from '@app/api/configurardorPrincipal/UserApi';
import {PerfilApi} from '@app/api/configurardorPrincipal/PerfilApi';
import {UsuarioPerfilApi} from '@app/api/configurardorPrincipal/UsuarioPerfilApi';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputLabel
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

type UserProfile = {
  idperfilusuario: number;
  idperfil: number;
  idusuario: number;
};
type User = {
  idusuario: number;
  givenname: string;
  familyname: string;
  email: string;
  imageurl: string;
};
type Perfil = {
  idperfil: number;
  nombre: string;
};
const UserProfilesComponent: React.FC = () => {
  const [userProfiles, setUserProfiles] = useState<UserProfile[]>([]);
  const [perfiles, setPerfiles] = useState<Perfil[]>([]);
  const [usuarios, setUsuarios] = useState<User[]>([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [updateProfileId, setUpdateProfileId] = useState<number | null>(null);
  const [editingProfileData, setEditingProfileData] = useState<UserProfile>({
    idperfil: 0,
    idusuario: 0,
    idperfilusuario: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener perfiles de usuario
        const userProfilesResponse = await UsuarioPerfilApi.getUsuarioPerfil();
        setUserProfiles(userProfilesResponse.data);

        // Obtener lista de perfiles
        const perfilesResponse = await PerfilApi.getPerfil();
        setPerfiles(perfilesResponse.data);

        // Obtener lista de usuarios
        const usuariosResponse = await UsuarioApi.usuarios();
        setUsuarios(usuariosResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;

    setEditingProfileData({
      ...editingProfileData,
      [name]: isNaN(Number(value)) ? value : Number(value),
    });
  };

  const handleCreate = async () => {
    try {
      if (isUpdateMode) {
        await UsuarioPerfilApi.updateUsuarioPerfil(
          updateProfileId!,
          editingProfileData
        );
      } else {
        await UsuarioPerfilApi.createUsuarioPerfil(editingProfileData);
      }

      // Restablecer el estado y cerrar el diálogo
      setEditingProfileData({
        idperfil: 0,
        idusuario: 0,
        idperfilusuario: 0,
      });
      setOpenCreateDialog(false);
      setIsUpdateMode(false);
      setUpdateProfileId(null);

      // Actualizar la lista de perfiles de usuario
      const updatedUserProfilesResponse =
        await UsuarioPerfilApi.getUsuarioPerfil();
      setUserProfiles(updatedUserProfilesResponse.data);
    } catch (error) {
      console.error('Error creando/actualizando el perfil de usuario:', error);
    }
  };

  const handleEdit = async (profileId: number) => {
    const selectedProfile = userProfiles.find(
      (profile) => profile.idperfilusuario === profileId
    );

    if (selectedProfile) {
      setEditingProfileData(selectedProfile);

      setIsUpdateMode(true);
      setUpdateProfileId(profileId);
      setOpenCreateDialog(true);
    }
  };
  const getPerfilNameById = (perfilId: number): string => {
    const perfil = perfiles.find((p) => p.idperfil === perfilId);
    return perfil?.nombre || 'Perfil no encontrado';
  };
  const getUsuarioNameById = (usuarioId: number): string => {
    const usuario = usuarios.find((u) => u.idusuario === usuarioId);
    return usuario?.givenname || 'Usuario no encontrado';
  };
  const getUsuarioLastById = (usuarioId: number): string => {
    const usuario = usuarios.find((u) => u.idusuario === usuarioId);
    return usuario?.familyname || 'Usuario no encontrado';
  };
  const getUsuarioPictureById = (usuarioId: number): string => {
    const usuario = usuarios.find((u) => u.idusuario === usuarioId);
    return usuario?.imageurl || 'Usuario no encontrado';
  };
  const getUsuarioEmailById = (usuarioId: number): string => {
    const usuario = usuarios.find((u) => u.idusuario === usuarioId);
    return usuario?.email || 'Usuario no encontrado';
  };
  return (
    <div>
      <div>
        <Paper>
        <IconButton
            onClick={() => {
              setOpenCreateDialog(true);
              setEditingProfileData({ idperfil: 0, idusuario: 0, idperfilusuario: 0 });
            }}
            style={{ color: '#212C55' }}
          >
            <AddIcon />
          </IconButton>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>                  
                  <TableCell><strong>Nombre</strong></TableCell>
                  <TableCell><strong>Apellido</strong></TableCell>
                  <TableCell><strong>Email</strong></TableCell>
                  <TableCell><strong>Perfil</strong></TableCell>
                  <TableCell><strong>Foto</strong></TableCell>
                  <TableCell><strong>Acciones</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userProfiles.map((userProfile, index) => (
                  <TableRow key={index}>

                    <TableCell>
                      {getUsuarioNameById(userProfile.idusuario)}
                    </TableCell>
                    <TableCell>
                      {getUsuarioLastById(userProfile.idusuario)}
                    </TableCell>
                    <TableCell>
                      {getUsuarioEmailById(userProfile.idusuario)}
                    </TableCell>
                    <TableCell>
                      {getPerfilNameById(userProfile.idperfil)}
                    </TableCell>
                    <TableCell>
                      <img
                        src={
                          getUsuarioPictureById(userProfile.idusuario) ??
                          'URL_POR_DEFECTO'
                        }
                        alt="Foto"
                        style={{width: '50px', height: '50px'}}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          handleEdit(userProfile.idperfilusuario);
                        }}
                        style={{color: '#212C55'}}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
            open={openCreateDialog}
            onClose={() => setOpenCreateDialog(false)}
          >
            <DialogTitle>
              {isUpdateMode
                ? 'Editar UsuarioPerfil'
                : 'Crear Nuevo UsuarioPerfil'}
            </DialogTitle>
            <DialogContent>
              <InputLabel>Usuarios</InputLabel>
              <select
                className="form-control"
                name="idusuario"
                value={editingProfileData.idusuario}
                onChange={handleInputChange}
                required
                title="Usuario"
              >
                <option value="">Seleccionar usuario</option>
                {usuarios.map((tipo: any) => (
                  <option key={tipo.idusuario} value={tipo.idusuario}>
                    {tipo.email}
                  </option>
                ))}
              </select>

              <InputLabel>Perfil</InputLabel>
              <select
                className="form-control"
                name="idperfil"
                value={editingProfileData.idperfil}
                onChange={handleInputChange}
                required
                title="Tipo de Perfil"
              >
                <option value="">Seleccionar perfil</option>
                {perfiles.map((tipo: any) => (
                  <option key={tipo.idperfil} value={tipo.idperfil}>
                    {tipo.nombre}
                  </option>
                ))}
              </select>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenCreateDialog(false)}>
                Cancelar
              </Button>
              <Button onClick={handleCreate}>
                {isUpdateMode ? 'Actualizar' : 'Crear'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    </div>
  );
};

export default UserProfilesComponent;
