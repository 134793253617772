import React from 'react';
import { Button } from 'react-bootstrap';
import { Sistema } from './Sistema';
import DialogBox from '@app/components/DialogBox';

type SistemaFormProps = {
  editingData: Sistema | null;
  open: boolean;
  onClose: () => void;
  onSaveLinea: (row: Sistema) => void;
};

const SistemaForm: React.FC<SistemaFormProps> = ({
  editingData,
  open,
  onClose,
  onSaveLinea,
}) => {
  return (
    <DialogBox
      open={open}
      onClose={onClose}
      title={editingData ? 'Editar' : 'Agregar'}
      content={
        <form
          onSubmit={(event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const row: Sistema = {
              idsistema: editingData?.idsistema || 0,
              idplataforma: editingData?.idplataforma || 0,
              idbasemagaya: editingData?.idplataforma || 0,
              idnetworkmagaya: editingData?.idbasemagaya || 0,
              servidorcomunicaciones: formData.get('servidorcomunicaciones') as string,
              endpoint: formData.get('endpoint') as string,
              usuarioapi: formData.get('usuarioapi') as string,
              contraseniaapi: formData.get('contraseniaapi') as string,
            };
            onSaveLinea(row);
          }}
        >
          <div className="form-group" id="servidorcomunicaciones">
            <label>Sistema</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">Servidor de comunicaciones</span>
              </div>
              <input
                type="text"
                name="servidorcomunicaciones"
                className="form-control "
                defaultValue={editingData?.servidorcomunicaciones}
                placeholder="Ingrese el servidor de comunicaciones"
              />
            </div>
          </div>
          <div className="form-group" id="endpoint">
            <label>Endpoint</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">Endpoint</span>
              </div>
              <input
                type="text"
                name="endpoint"
                className="form-control "
                defaultValue={editingData?.endpoint}
                placeholder="Ingrese el servidor de comunicaciones"
              />
            </div>
          </div>
          <div className="form-group" id="usuarioapi">
            <label>Usuario Api</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">Usuario Api</span>
              </div>
              <input
                type="text"
                name="usuarioapi"
                className="form-control "
                defaultValue={editingData?.usuarioapi}
                placeholder="Ingrese el usuario api"
              />
            </div>
          </div>
          <div className="form-group" id="contraseniaapi">
            <label>Contraseña</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">Usuario Api</span>
              </div>
              <input
                type="password"
                name="contraseniaapi"
                className="form-control "
                defaultValue={editingData?.contraseniaapi}
              />
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <Button type="button" className="btn btn-danger" onClick={onClose}>
              Cerrar
            </Button>
            <Button type="submit" className="btn btn-success">
              {editingData ? 'Guardar' : 'Agregar'}
            </Button>
          </div>
        </form>
      }
    />
  );
};

export default SistemaForm;