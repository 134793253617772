import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import LineaDeNegocio from './LineaDeNegocio';




interface LineaDeNegocioTableProps {
  data: LineaDeNegocio[];
  columns: TableColumn<LineaDeNegocio>[];
  customStyles: Record<string, any>;
}

const LineaDeNegocioTable: React.FC<LineaDeNegocioTableProps> = ({ data, columns, customStyles }) => {
  return (
    <DataTable<LineaDeNegocio>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      customStyles={customStyles}
      noDataComponent={"No hay registros para mostrar."}
    />
  );
};

export default LineaDeNegocioTable;