import React from 'react'
import Banco from './banco/Banco'
import Cuenta from './cuenta/Cuenta'
import Empresa from './empresa/Empresa'



export const Catalogo = () => {
  return (
    <section className="content">
    <div className="container-fluid">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Catálagos de Banca</h3>
          <div className="card-tools">
          </div>
        </div>
        <div className="card-body">
          <div className="p-tabview p-component">
            <div className=".p-tabview-nav p-0 pt-1">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-one-tab"
                role="tablist"
              >
                <li className="li.p-highlight">
                  <a
                    className="nav-link active"
                    id="custom-tabs-one-banco-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-banco"
                    role="tab"
                    aria-controls="custom-tabs-one-banco"
                    aria-selected="true"
                    style={{color:'#101D61'}}
                  >
                    Bancos
                  </a>
                </li>
                <li className="li.p-highlight">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-cuentas-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-cuentas"
                    role="tab"
                    aria-controls="custom-tabs-one-cuentas"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Cuentas
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-empresa-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-empresa"
                    role="tab"
                    aria-controls="custom-tabs-one-empresa"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Empresas
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-empr-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-empr"
                    role="tab"
                    aria-controls="custom-tabs-one-empr"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-pichincha-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-pichincha"
                    role="tab"
                    aria-controls="custom-tabs-one-pichincha"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >

                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-bones-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-bones"
                    role="tab"
                    aria-controls="custom-tabs-one-bones"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >

                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-naturaleza-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-naturaleza"
                    role="tab"
                    aria-controls="custom-tabs-one-naturaleza"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-one-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-one-banco"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-banco-tab"
                >
                  <Banco/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-cuentas"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-cuentas-tab"
                >
                  <Cuenta/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-empresa"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-empresa-tab"
                >
                  <Empresa/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-empr"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-empr-tab"
                >
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-pichincha"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-pichincha-tab"
                >

                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-bones"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-bones-tab"
                >

                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-naturaleza"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-naturaleza-tab"
                >
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
    </div>
  </section>
  )
}