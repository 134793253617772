
import React, {useEffect, useState} from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button} from 'react-bootstrap';
import {toast} from 'react-toastify';
import DialogBox from '../../../components/DialogBox';
import { LineaNegocioApi } from '@app/api/estadisticas/LineaNegocioApi';
import LineaDeNegocioTable from './LineaDeNegocioTable';




interface LineaDeNegocio {
  idlineadenegocio: number;
  nombredescriptivo: string;
  estado:string;
  observaciones:string;
  activo: boolean;
}

const LineaDeNegocio: React.FC = () => {
  const [data, setData] = useState<LineaDeNegocio[]>([]);
  const [editingData, setEditingData] = useState<LineaDeNegocio | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activo, setActivo] = useState<boolean>(editingData?.activo || false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseGuardar = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    handleReset();
  };

  const handleReset = () => {
    setEditingData(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await LineaNegocioApi.getLineaNegocio();
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  const handleEdit = (row: LineaDeNegocio) => {
    console.log('Editar:', row);
    setEditingData(row);
    handleOpen();
  };

  const handleDelete = (row: LineaDeNegocio) => {
    console.log('Eliminar:', row);
    confirmAlert({
      title: 'Confirmar eliminación',
      message: `¿Estás seguro que deseas eliminar el registro ${row.nombredescriptivo}?`,
      buttons: [
        {
          label: 'Sí',
          onClick: () => {
            const newData = data.filter(
              (d: LineaDeNegocio) => d.idlineadenegocio !== row.idlineadenegocio
            );
            setData(newData);
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const handleSave = (row: LineaDeNegocio) => {
    var validacionDatos = '';
    if (row.idlineadenegocio) {
      if (row.nombredescriptivo.length === 0) {
        validacionDatos = 'Nombre no puede estar en blanco';
      }
      const actualizacion = data.find((element) => {
        if (
          element.nombredescriptivo.toUpperCase() === row.nombredescriptivo.toUpperCase() &&
          element.idlineadenegocio !== row.idlineadenegocio
        ) {
          validacionDatos = 'Error Datos Duplicado, Valide el Catalogo';
          return true;
        }
      });
      if (validacionDatos.length === 0) {
        LineaNegocioApi.updateLineaNegocio(row.idlineadenegocio, row)
          .then(() => {
            setData((prevRow) =>
              prevRow.map((u) =>
                u.idlineadenegocio === row.idlineadenegocio ? row : u
              )
            );
            toast.success('Actualización Exitosa');
            handleReset();
            handleCloseGuardar();
          })
          .catch((err) => {
            toast.error('Error de Comunicación, Contacte a TI');
          });
      } else {
        toast.error(validacionDatos);
      }
    } else {
      if (row.nombredescriptivo.length === 0) {
        validacionDatos = 'Nombre no puede estar en blanco';
      }
      const creacion = data.find((element) => {
        if (element.nombredescriptivo.toUpperCase() == row.nombredescriptivo.toUpperCase()) {
          validacionDatos = 'Error Datos Duplicado, Valide el Catalogo';
          return true;
        }
      });
      if (validacionDatos.length === 0) {
        LineaNegocioApi.createLineaNegocio(row)
          .then((response) => {
            setData((prevData) => [...prevData, response.data]);
            toast.success('Creación Exitosa');
            handleReset();
            handleCloseGuardar();
          })
          .catch((err) => {
            toast.error('Error de Comunicación, Contacte a TI');
          });
      } else {
        toast.error(validacionDatos);
      }
    }
  };

  const columns: TableColumn<LineaDeNegocio>[] = [
    {name: 'Nombre', selector: (row) => row.nombredescriptivo},
    {name: 'Estado', selector: (row) => row.estado},
    {name: 'Observaciones', selector: (row) => row.observaciones},
    {
      name: 'Acciones',
      cell: (row: LineaDeNegocio) => (
        <div>
          <button
            onClick={() => handleEdit(row)}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-pen"></i> Editar{' '}
          </button>
          &nbsp;
          <button
            onClick={() => handleDelete(row)}
            className="btn btn-danger btn-sm"
          >
            <i className="fa fa-eraser"></i> Eliminar{' '}
          </button>
        </div>
      )
    }
  ];

  return (
    <div>
      <Button variant="success" onClick={handleOpen}>
        <i className="fa fa-plus"></i> Agregar{' '}
      </Button>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <LineaDeNegocioTable
          data={data}
          columns={columns}
          customStyles={customStyles}
        />
      )}
      <DialogBox
        open={open}
        onClose={handleClose}
        title={editingData ? 'Editar' : 'Agregar'}
        content={
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const row: LineaDeNegocio = {
                idlineadenegocio: editingData?.idlineadenegocio || 0,
                nombredescriptivo: formData.get('nombredescriptivo') as string,
                estado:formData.get('estado') as string,
                observaciones:formData.get('observaciones') as string,
                activo
              };
              handleSave(row);
            }}
          >
            <div className="form-group" id="nombredescriptivo">
              <label>Línea de negocio</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Línea de negocio</span>
                </div>
                <input
                  type="text"
                  name="nombredescriptivo"
                  className="form-control "
                  defaultValue={editingData?.nombredescriptivo}
                  placeholder="Ingrese el nombre descriptivo"
                />
              </div>
            </div>
            <div className="form-group" id="estado">
              <label>Estado</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Estado</span>
                </div>
                <input
                  type="text"
                  name="estado"
                  className="form-control "
                  defaultValue={editingData?.estado}
                  placeholder="Ingrese el estado"
                />
              </div>
            </div>
            <div className="form-group" id="observaciones">
              <label>Observaciones</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Observaciones</span>
                </div>
                <textarea
                  name="observaciones"
                  className="form-control "
                  defaultValue={editingData?.observaciones}
                />
              </div>
            </div>
            <div className="form-group custom-control custom-checkbox">
              <input
                className="custom-control-input"
                type="checkbox"
                checked={activo}
                onChange={(e) => setActivo(e.target.checked)}
                defaultChecked={editingData?.activo}
                id="activo"
              />
              <label htmlFor="activo" className="custom-control-label">
                Activo
              </label>
            </div>

            <div className="modal-footer justify-content-between">
              <Button
                type="button"
                className="btn btn-danger"
                onClick={handleClose}
              >
                Cerrar
              </Button>
              <Button type="submit" className="btn btn-success">
                {editingData ? 'Guardar' : 'Agregar'}
              </Button>
            </div>
          </form>
        }
      />
    </div>
  );
};

const customStyles = {
  headCells: {
    style: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontFamily: 'Arial'
    }
  },
  rows: {
    style: {
      fontSize: '18px'
    }
  },
  cells: {
    style: {
      fontSize: '16px'
    }
  },
  pagination: {
    style: {
      fontSize: '14px'
    }
  }
};

export default LineaDeNegocio;