import {ContentHeader} from '@app/components';
import React, {useEffect, useState} from 'react';
import {ReporteBonesApi} from '@app/api/bancos/ReporteBonesApi';
import {RegistroApi} from '@app/api/bancos/RegistroApi';
import TabComponent from '@app/components/TabComponent';
import TabComponentLectura from '@app/components/TabComponentLectura';
import {useSelector} from 'react-redux';
import { UsuarioPerfilApi } from '@app/api/configurardorPrincipal/UsuarioPerfilApi';

const Reporteria = () => {
  const [registros, setRegistros] = useState(0);
  const [documentos, setDocumentos] = useState(0);
  const [perfil, setPerfil] = useState(null);
  useEffect(() => {
    ReporteBonesApi.getReporteBones2()
      .then((response) => setRegistros(response.data))
      .catch((error) => console.error(error));
  }, []);
  useEffect(() => {
    RegistroApi.getRegistro1()
      .then((response) => setDocumentos(response.data))
      .catch((error) => console.error(error));
  }, []);
  const user = useSelector((state: any) => state.auth.currentUser);
  useEffect(() => {
    if (user?.data?.idusuario) {
      UsuarioPerfilApi.getUsuarioId(user.data.idusuario)
        .then((response) => {
          setPerfil(response.data);
        })
        .catch((error) => {
          console.error('Error al obtener el perfil del usuario', error);
        });
    }
  }, [user?.data?.idusuario]);

  return (
    <div>
      <ContentHeader title="Reportes bancarios" />
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="row">
                <div className="col-12 col-sm-4">
                  <div className="info-box bg-light">
                    <div className="info-box-content">
                      <span className="info-box-text text-center text-muted">
                        Total de registros por día
                      </span>
                      <span className="info-box-number text-center text-muted mb-0">
                        {registros}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="info-box bg-light">
                    <div className="info-box-content">
                      <span className="info-box-text text-center text-muted">
                        Archivos procesados
                      </span>
                      <span className="info-box-number text-center text-muted mb-0">
                        {documentos}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="info-box bg-light">
                    <div className="info-box-content">
                      <span className="info-box-text text-center text-muted">
                        Bancos
                      </span>
                      <span className="info-box-number text-center text-muted mb-0">
                        3
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="callout callout-info">
                  <h5>
                    <i className="fas fa-info"></i> Nota:
                  </h5>
                  En esta sección, se cargan los informes correspondientes a
                  cada banco. Después de cargar cada uno de los documentos
                  mediante el botón de carga de archivos, se realizará una
                  validación para asegurar que el archivo sea el correcto.
                  Luego, a través de un segundo botón, se enviará la información
                  al informe general.
                </div>
                {/* {perfil === 7 ? (
                  <TabComponent />
                ) :
                perfil === 8 ? (
                  <TabComponent />
                ) : perfil === 9 ? (
                  <TabComponentLectura />
                ) : (
                  <div>Perfil no reconocido para mostrar menú</div>
                )} */}
                {perfil !== 0 ? (
                  <TabComponent />
                ) : (
                  <div>Perfil no reconocido para mostrar menú</div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reporteria;
