import { ConfiguracionApiActivosFijos } from "./ConfiguracionApiActivosFijos"

export const EstadoFuncionalApi =
{
    getEstadoFuncional:function()
    {
        return ConfiguracionApiActivosFijos.get("estadoFuncional")
    },
    getEstadoFuncionalId:function(id: number)
    {
        return ConfiguracionApiActivosFijos.get(`estadoFuncional/${id}`)
    },
    createEstadoFuncional: function(data: any){
        return ConfiguracionApiActivosFijos.post("estadoFuncional", data);
    },
    updateEstadoFuncional: function(id: number, data: any){
        return ConfiguracionApiActivosFijos.put(`estadoFuncional/${id}`, data);
    },
    deleteEstadoFuncional: function(id: number){
        return ConfiguracionApiActivosFijos.delete(`estadoFuncional/${id}`);
    }
}