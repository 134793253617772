import { api } from "../integrador/ApiConfiguracionIntegrador"

export const ArchivoApi =  {
    getArchivoId:function(id: number)
    {
        return api.get(`api/archivo/${id}`)
    },
    getArchivo:function(id: number)
    {
        return api.get(`api/archivo/porEstadoIntegracion/${id}`)
    },
    updateArchivo: function( data: any) {
        return api.put(`api/archivo/actualizarEstado/`, data);
    },
    createArchivo: function(data: any){
        return api.post("/api/archivo", data);
    },
    createFile: function(data: any){
        return api.post("/api/archivos/uploads", data);
    }
}