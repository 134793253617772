import React, { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import {
  IconButton,
  TableContainer,
  Card,
  CardContent,
  makeStyles,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUniversity,
  faChartBar,
  faExchangeAlt,
  faUser,
  faShare,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import EditIcon from "@mui/icons-material/EditTwoTone";
import CloseIcon from "@mui/icons-material/CloseTwoTone";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import UserIcon from "@mui/icons-material/SaveOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import DialogBox from "@app/components/DialogBox";
import MenuTable from "./MenuTable";
import MenuApi from "@app/api/configurardorPrincipal/MenuApi";

interface Menu {
  idmenu: number;
  nombre: string;
  path: string;
  nivel: string;
  idmenupadre: number;
  icono: string;
}

const iconNames = ["Banca", "Estadistica", "Activos", "Perfiles", "Integrador"];
const iconComponents: Record<typeof iconNames[number], IconDefinition> = {
  Banca: faUniversity,
  Estadistica: faChartBar,
  Activos: faExchangeAlt,
  Perfiles: faUser,
  Integrador: faShare,
};

const useStyles = makeStyles((theme: { spacing: (arg0: number) => any }) => ({
  paper: {
    width: "250px",
    padding: theme.spacing(2),
  },
}));

const IconListBox: React.FC<{ onSelect: (icon: string) => void }> = ({
  onSelect,
}) => {
  const classes = useStyles();
  const [selectedIcon, setSelectedIcon] = useState<typeof iconNames[number]>(
    iconNames[0]
  );
  useEffect(() => {
    setSelectedIcon("Seleccionar un ícono");
  }, []);

  const handleIconSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedIconName = event.target.value as typeof iconNames[number];
    setSelectedIcon(selectedIconName);
    onSelect(selectedIconName);
  };

  return (
    <Paper className={classes.paper}>
      <FormControl fullWidth>
        <InputLabel id="icon-select-label">Seleccionar Icono</InputLabel>
        <Select
          labelId="icon-select-label"
          id="icon-select-label"
          value={selectedIcon}
          onChange={handleIconSelect}
          name="icono"
          defaultValue="Seleccione un ícono"
        >
          <MenuItem disabled value="">
            <em>Seleccionar Icono</em>
          </MenuItem>
          {iconNames.map((iconName) => (
            <MenuItem key={iconName} value={iconName}>
              <FontAwesomeIcon icon={iconComponents [iconName] as any} />{" "}
              &nbsp; &nbsp;{ iconName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  );
};
const Menu: React.FC = () => {
  const [data, setData] = useState<Menu[]>([]);
  
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [selectedIcon, setSelectedIcon] = useState<string>("");
  const [menuPadreOptions, setMenuPadreOptions] = useState<Menu[]>([]);
  const [editingData, setEditingData] = useState<Menu | null>(null);
  const [selectedMenuPadre, setSelectedMenuPadre] = useState(
    editingData?.idmenupadre || ""
  );
  const roundedCardStyle = {
    borderRadius: "10px",
    overflow: "hidden",
  };
  const titleTextStyle = {
    borderBottom: "2px solid tomato",
    paddingBottom: "10px",
    marginBottom: "15px",
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseGuardar = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    handleReset();
  };

  const handleReset = () => {
    setEditingData(null);
    setSelectedIcon("");
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const fetchMenuPadreOptions = async () => {
      try {
        const response = await MenuApi.menus(); // Ajusta según tu API
        const menusPadre = response.data.filter(
          (menu: { menuPadre: any }) => !menu.menuPadre
        ); // Filtra los menús padres
        setMenuPadreOptions(menusPadre);
      } catch (error) {
        console.error("Error fetching menu padre options:", error);
      }
    };

    fetchMenuPadreOptions();
  }, []);

  const fetchData = async () => {
    try {
      const response = await MenuApi.menus();
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };
  const handleIconSelect = (icon: string) => {
    setSelectedIcon(icon);
  };

  const handleEdit = (row: Menu) => {
    console.log("Editar:", row);
    setEditingData(row);
    handleOpen();
  };

  const handleSave = (row: Menu) => {
    var validacionDatos = "";
    if (row.idmenu) {
      if (row.nombre.length === 0) {
        validacionDatos = "Nombre no puede estar en blanco";
      }
      const actualizacion = data.find((element) => {
        if (
          element.nombre.toUpperCase() === row.nombre.toUpperCase() &&
          element.idmenu !== row.idmenu
        ) {
          validacionDatos = "Error Datos Duplicado, Valide el Catalogo";
          return true;
        }
      });
      if (validacionDatos.length === 0) {
        MenuApi.putMenu(row.idmenu, row)
          .then(() => {
            setData((prevRow) =>
              prevRow.map((u) => (u.idmenu === row.idmenu ? row : u))
            );
            toast.success("Actualización Exitosa");
            handleReset();
            handleCloseGuardar();
          })
          .catch((err) => {
            toast.error("Error de Comunicación, Contacte a TI");
          });
      } else {
        toast.error(validacionDatos);
      }
    } else {
      if (row.nombre.length === 0) {
        validacionDatos = "Nombre no puede estar en blanco";
      }
      const creacion = data.find((element) => {
        if (element.nombre.toUpperCase() == row.nombre.toUpperCase()) {
          validacionDatos = "Error Datos Duplicado, Valide el Catalogo";
          return true;
        }
      });
      if (validacionDatos.length === 0) {
        MenuApi.createMenu(row)
          .then((response) => {
            setData((prevData) => [...prevData, response.data]);
            toast.success("Creación Exitosa");
            handleReset();
            handleCloseGuardar();
          })
          .catch((err) => {
            toast.error("Error de Comunicación, Contacte a TI");
          });
      } else {
        toast.error(validacionDatos);
      }
    }
  };

  const columns: TableColumn<Menu>[] = [
    { name: "Nombre", selector: (row) => row.nombre },
    { name: "Path", selector: (row) => row.path },
    { name: "Menú", selector: (row) => row.nombre },
    {
      name: "Ícono",
      cell: (row) => (
        <div>
          <i className={row.icono} />
        </div>
      ),
    },
    {
      name: "Acciones",
      cell: (row: Menu) => (
        <div>
          <IconButton
            onClick={() => handleEdit(row)}
            style={{ color: "#212C55" }}
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      <IconButton onClick={handleOpen} style={{ color: "#212C55" }}>
        <AddIcon />
      </IconButton>

      {loading ? (
        <p>Cargando...</p>
      ) : (
        <>
          <TableContainer>
            <MenuTable
              data={data}
              columns={columns}
              customStyles={customStyles}
            />
          </TableContainer>
        </>
      )}

      <DialogBox
        open={open}
        onClose={handleClose}
        content={
          <Card style={roundedCardStyle}>
            <CardContent>
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                style={titleTextStyle}
              >
                {editingData ? "Editar" : "Crear"} Menú <MenuIcon />
              </Typography>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const row: Menu = {
                    idmenu: editingData?.idmenu || 0,
                    nombre: formData.get("nombre") as string,
                    path: formData.get("path") as string,
                    nivel: formData.get("nivel") as string,
                    idmenupadre: editingData?.idmenu || 0,
                    icono: formData.get("icono") as string,
                  };
                  handleSave(row);
                }}
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group" id="nombre">
                        <label>Nombre del menú</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-bars" aria-hidden="true"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            name="nombre"
                            className="form-control "
                            defaultValue={editingData?.nombre}
                            placeholder="Ingrese el nombre del menú"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" id="path">
                        <label>Path</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-code" aria-hidden="true"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            name="path"
                            className="form-control "
                            defaultValue={editingData?.path}
                            placeholder="Ingrese el path del menú"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" id="idmenupadre">
                        <label>Menú Padre</label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i
                                className="fa fa-th-large"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                          <select
                            name="menupadre"
                            className="form-control"
                            value={selectedMenuPadre}
                            onChange={(e) =>
                              setSelectedMenuPadre(e.target.value)
                            }
                          >
                            <option value="" disabled>
                              Selecciona un menú padre
                            </option>
                            {menuPadreOptions.map((menu) => (
                              <option key={menu.idmenu} value={menu.idmenu}>
                                {menu.nombre}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-9">
                      <div className="form-group" id="icono">
                        <label>Iconos</label>
                        <div className="input-group mb-6">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i
                                className="fa fa-chevron-right"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                          <IconListBox onSelect={handleIconSelect} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <IconButton onClick={handleClose} style={{ color: "red" }}>
                    <CloseIcon />
                  </IconButton>
                  <span style={{ margin: "0 180px" }}>&nbsp;</span>
                  <IconButton type="submit" style={{ color: "#212C55" }}>
                    {editingData ? <SaveIcon /> : <UserIcon />}
                  </IconButton>
                </div>
              </form>
            </CardContent>
          </Card>
        }
      />
    </div>
  );
};

const customStyles = {
  headCells: {
    style: {
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "Arial",
    },
  },
  rows: {
    style: {
      fontSize: "18px",
    },
  },
  cells: {
    style: {
      fontSize: "16px",
    },
  },
  pagination: {
    style: {
      fontSize: "14px",
    },
  },
};

export default Menu;
