import React, {useState, useEffect, useCallback} from 'react';
import {Outlet} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {loadUser, loginUser, logoutUser} from '@store/reducers/auth';
import {toggleSidebarMenu} from '@app/store/reducers/ui';
import {addWindowClass, removeWindowClass, sleep} from '@app/utils/helpers';
import Header from '@app/modules/main/header/Header';
import Footer from '@app/modules/main/footer/Footer';
import {PfImage} from '@profabric/react-components';
import { UsuarioApi } from '@app/api/configurardorPrincipal/UserApi';
import MenuSidebar from './menu-sidebar/MenuSidebar';
import { UsuarioPerfilApi } from '@app/api/configurardorPrincipal/UsuarioPerfilApi';
import ControlSidebar from './control-sidebar/ControlSidebar';


type MainProps = {
  email:string;
  imagen:any
}

const Main = ({email, imagen}:MainProps) => {
  const dispatch = useDispatch();
  const [perfil, setPerfil] = useState(null);
  const user = useSelector((state: any) => state.auth.currentUser);
  const menuSidebarCollapsed = useSelector(
    (state: any) => state.ui.menuSidebarCollapsed
  );

  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  

  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  const fetchProfile = async () => {
    try {
      
      const storedUser = localStorage.getItem('user');

      if (storedUser) {
        const userObject = JSON.parse(storedUser);
        email = userObject?.claims?.preferred_username;
      }

      const responseByEmail = await UsuarioApi.usuarioPorEmail(email);
      //console.log("ESTO AUN TIENE LOCAL STORAGE", localStorage);
      //console.log("ESTO ME TRAE LLAMANDO A LA BASE DE DATOS", responseByEmail);

      if (!responseByEmail.data) {
        const userObject = storedUser ? JSON.parse(storedUser):null;
        const storedNoUser = {
          givenname: userObject.name.split(' ')[0], 
          familyname: userObject.name.split(' ')[1],  
          email: userObject.preferredName
        };
        //console.log("Esto tiene cuando no existe", storedNoUser)
        await UsuarioApi.createUsuario(storedNoUser);
      }
      //const responseByPreferredName = userEmail ? await UsuarioApi.usuarioPreferredname(userEmail) : null;

      const response = responseByEmail;// || responseByPreferredName;
      

      //const response = await (UsuarioApi.usuarioPorEmail(email) || UsuarioApi.usuarioPreferredname(email) || UsuarioApi.usuarioPreferredname(userEmail));
      loadUser(response)
      //dispatch(loginUser(JSON.stringify(response)));

      dispatch(loadUser(response));
      localStorage.getItem(loadUser.name);
      
      await sleep(1000);
      setIsAppLoaded(true);
      
      
    } catch (error) {
      dispatch(logoutUser());
      await sleep(1000);
      setIsAppLoaded(true);
    }
  };
  
  useEffect(() => {

    if (user?.data?.idusuario) {
      
      UsuarioPerfilApi.getUsuarioId(user.data.idusuario)
        .then((response) => {
          //console.log('responseffff solo',response)
          setPerfil(response.data);
          //console.log('response perfilffffff', response.data)
        })
        .catch((error) => {
          //console.error('Error al obtener el perfil del usuario', error);
        });
    }
  }, [user?.data?.idusuario]);
  //console.log('perfilfffffffff',perfil);



  useEffect(() => {

    removeWindowClass('register-page');
    removeWindowClass('login-page');
    removeWindowClass('hold-transition');

    addWindowClass('sidebar-mini');

    fetchProfile();
    return () => {
      removeWindowClass('sidebar-mini');
    };
  }, []);
 
  useEffect(() => {
    removeWindowClass('sidebar-closed');
    removeWindowClass('sidebar-collapse');
    removeWindowClass('sidebar-open');
    if (menuSidebarCollapsed && screenSize === 'lg') {
      addWindowClass('sidebar-collapse');
    } else if (menuSidebarCollapsed && screenSize === 'xs') {
      addWindowClass('sidebar-open');
    } else if (!menuSidebarCollapsed && screenSize !== 'lg') {
      addWindowClass('sidebar-closed');
      addWindowClass('sidebar-collapse');
    }
  }, [screenSize, menuSidebarCollapsed]);

  const getAppTemplate = useCallback(() => {
    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      const userObject = JSON.parse(storedUser);
      email = userObject?.claims?.preferred_username;
    }
    if (!isAppLoaded) {
      return (
        <div className="preloader flex-column justify-content-center align-items-center">
          <PfImage
            className="animation__shake"
            src="/img/SiatiLoading.png"
            alt="SiatiLogo"
            height={60}
            width={60}
          />
        </div>
      );
    }
    return (
      <>
        <Header email={email} imagen={imagen} />
        
        {
          perfil !== 0 ? (
            <MenuSidebar email={email} imagen={imagen}/>
          ) : (
            <div>Perfil por asignarse</div>
          )
        }

        

        <div className="content-wrapper">
          <div className="pt-3" />
          <section className="content">
          
            <Outlet />
          </section>
        </div>
        <Footer />
        <ControlSidebar/>
        <div
          id="sidebar-overlay"
          role="presentation"
          onClick={handleToggleMenuSidebar}
          onKeyDown={() => {}}
        />
      </>
    );
    
  }, [isAppLoaded]);

  return <div className="wrapper">{getAppTemplate()}</div>;
};

export default Main;
