import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import DialogBox from "../../../components/DialogBox";
import { CuentaApi } from "@app/api/bancos/CuentaApi";
import CuentaTable from "./CuentaTable";
import { BancoApi } from "@app/api/bancos/BancoApi";
import { EmpresaApi } from "@app/api/bancos/EmpresaApi";

// DC LIBRERIAS INICIO
// Esta librería trae desde la base de datos los metodos que se necesita
// para dar los permisos a los botones
import AccionesApi from "@app/api/configurardorPrincipal/AccionesApi";
import { PerfilApi } from '@app/api/configurardorPrincipal/PerfilApi';
import { UsuarioApi } from '@app/api/configurardorPrincipal/UserApi';
import { UsuarioPerfilApi } from '@app/api/configurardorPrincipal/UsuarioPerfilApi';
import MenuPerfilApi from "@app/api/configurardorPrincipal/MenuPerfilApi";
// DC LIBRERIAS FIN

// DC VARIABLES INICIO
// Declaro una variable que tiene los atributos de las acciones
// Esto para ser usadas mas adelante cuando se compare los permisos
interface Acciones {
  idaccion?: number;
  idmenuperfil?: number;
  idmenu?: number;
  nombre?: string;
  crear: boolean;
  leer: boolean;
  borrar: boolean;
  actualizar: boolean;
}
// DC VARIABLES FIN

interface Cuenta {
  idcuenta: number;
  cuentacontable: number;
  numcta: string;
  idbanco: number;
  idempresa: number;
}
interface Banco {
  idbanco: number;
  nombre: string;
  ruc: string;
}
interface Empresa {
  idempresa: number;
  nombre: string;
  ruc: string;
}
const Cuenta: React.FC = () => {
  // DC CONSTANTES INICIO
  // DECLARO UNA VARIABLE SETEABLE PARA TRAER LOS PERMISOS
  const [permisosAcciones, setPermisosAcciones] = useState<Acciones>({
    idaccion: 0,
    idmenuperfil: 0,
    idmenu: 0,
    nombre: "",
    crear: false,
    leer: false,
    borrar: false,
    actualizar: false
  });
  
  // DC CONSTANTES FIN
  const [data, setData] = useState<Cuenta[]>([]);
  const [bancos, setBancos] = useState<Banco[]>([]);
  const [empresas, setEmpresas] = useState<Empresa[]>([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState<number | null>(null);
  const [selectedBanco, setSelectedBanco] = useState<number | null>(null);
  const [editingData, setEditingData] = useState<Cuenta | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validar, setValidar] = React.useState({
    cuentaContable: "",
    numCuenta: "",
  });
  const validarInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValidar({ ...validar, [e.target.name]: e.target.value });
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseGuardar = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    handleReset();
  };

  const handleReset = () => {
    setEditingData(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // DC Obtencion de datos acciones fin
      const permisosResponse = await AccionesApi.getAcciones();
      console.log("Esto tiene permisosResponse", permisosResponse.data);
      // DC Obtencion de datos acciones fin

      // DC Datos del perfil PERFIL Y MENU INICIO
      // Esto es para traer todos los perfiles contabilidad, lectura
      // CAC y ADMINISTRADOR
      // Consiguiendo el perfil buscandolo con el correo
      const perfilUsuarioResponse = await UsuarioApi.usuarioPorEmail(localStorage.idToken);
      console.log("Esto tiene perfilUsuarioResponse",perfilUsuarioResponse);
      // Ahora con el idusuario buscaremos el perfil admin, conta, cac o lectura
      const perfilUsuarioIdResponse = await UsuarioPerfilApi.getUsuarioId(perfilUsuarioResponse.data.idusuario);
      const perfilUsuarioData = perfilUsuarioIdResponse.data; // Esta variable contiene el perfil
      console.log("Datos del perfil del usuario:", perfilUsuarioData);
      // Ahora se consigue el menu
      const menuPerfil = await MenuPerfilApi.getMenuPerfilXId(perfilUsuarioData);
      console.log("Esto obtengo en el menu", menuPerfil.data);
      // Obteniendo permisos del usuario

      const idMenus = menuPerfil.data.map((item: any) => item.menu.idmenu);
      console.log("IDs de menú:", idMenus);

      // Paso 1: Obtener una lista de idmenuperfil coincidentes entre permisosResponse.data y perfilUsuarioData
      const idmenuperfilesCoincidentes = permisosResponse.data
        .filter((permiso: any) => permiso.idmenuperfil === perfilUsuarioData)
        .map((permiso: any) => permiso.idmenuperfil);

      // Paso 2: Obtener una lista de idmenu coincidentes entre permisosResponse.data y idMenus
      const idmenusCoincidentes = permisosResponse.data
        .filter((permiso: any) => idMenus.includes(permiso.idmenu))
        .map((permiso: any) => permiso.idmenu);

      // Paso 3: Filtrar permisos correspondientes a los idmenuperfil y idmenu coincidentes
      const permisosCoincidentes = permisosResponse.data.filter((permiso: any) =>
        idmenuperfilesCoincidentes.includes(permiso.idmenuperfil) &&
        idmenusCoincidentes.includes(permiso.idmenu)
      );

      // Paso 4: Extraer los permisos crear, leer, borrar, actualizar de los permisos coincidentes
      const permisosAcciones: any = permisosCoincidentes.reduce((acc: any, permiso: any) => {
        acc.crear = acc.crear || permiso.crear;
        acc.leer = acc.leer || permiso.leer;
        acc.borrar = acc.borrar || permiso.borrar;
        acc.actualizar = acc.actualizar || permiso.actualizar;
        return acc;
      }, { crear: false, leer: false, borrar: false, actualizar: false });

      console.log("Permisos de acciones:", permisosAcciones);
      // Dentro de la función fetchData
      setPermisosAcciones({
        idaccion: 0,
        idmenuperfil: 0,
        idmenu: 0,
        nombre: "",
        crear: permisosAcciones.crear,
        leer: permisosAcciones.leer,
        borrar: permisosAcciones.borrar,
        actualizar: permisosAcciones.actualizar
      });


      // DC Datos del perfil PERFIL Y MENU FIN
      const response = await CuentaApi.getCuenta();
      setData(response.data);
      const bancosResponse = await BancoApi.getBanco();
      setBancos(bancosResponse.data);
      const empresaReponse = await EmpresaApi.getEmpresa();
      setEmpresas(empresaReponse.data);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };
  const getCuentaEmpresa = (cuenta: Cuenta) => {
    const empresa = empresas.find((em) => em.idempresa === cuenta.idempresa);
    return empresa ? `${empresa.nombre}` : "Sin empresa asocida";
  };
  const handleEmpresaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = parseInt(event.target.value);
    setSelectedEmpresa(selectedId);
  };
  const getCuentaBanco = (cuenta: Cuenta) => {
    const banco = bancos.find((b) => b.idbanco === cuenta.idbanco);
    return banco ? `${banco.nombre}` : "Sin banco asociado";
  };
  const handleBancoChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = parseInt(event.target.value);
    setSelectedBanco(selectedId);
  };
  const handleEdit = (row: Cuenta) => {
    console.log("Editar:", row);
    setEditingData(row);
    handleOpen();
  };

  const handleDelete = (row: Cuenta) => {
    console.log("Eliminar:", row);
    confirmAlert({
      title: "Confirmar eliminación",
      message: `¿Estás seguro que deseas eliminar el registro ${row.numcta}?`,
      buttons: [
        {
          label: "Sí",
          onClick: () => {
            const newData = data.filter(
              (d: Cuenta) => d.idbanco !== row.idbanco
            );
            setData(newData);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSave = (row: Cuenta) => {
    let validacionDatos = "";
    if (row.idbanco) {
      if (row.numcta.length === 0) {
        validacionDatos = "Nombre no puede estar en blanco";
      }
      const actualizacion = data.find((element) => {
        if (
          element.numcta.toUpperCase() === row.numcta.toUpperCase() &&
          element.idbanco !== row.idbanco
        ) {
          validacionDatos = "Error Datos Duplicado, Valide el Catálogo";
          return true;
        }
      });
      if (validacionDatos.length === 0) {
        CuentaApi.updateCuenta(row.idcuenta, row)
          .then(() => {
            setData((prevRow) =>
              prevRow.map((u) => (u.idcuenta === row.idcuenta ? row : u))
            );
            toast.success("Actualización Exitosa");
            handleReset();
            handleCloseGuardar();
          })
          .catch((err) => {
            toast.error("Error de Comunicación, Contacte a TI");
          });
      } else {
        toast.error(validacionDatos);
      }
    } else {
      if (row.numcta.length === 0) {
        validacionDatos = "Nombre no puede estar en blanco";
      }
      const creacion = data.find((element) => {
        if (element.numcta.toUpperCase() === row.numcta.toUpperCase()) {
          validacionDatos = "Error Datos Duplicado, Valide el Catálogo";
          return true;
        }
      });
      if (validacionDatos.length === 0) {
        CuentaApi.createCuenta(row)
          .then((response) => {
            setData((prevData) => [...prevData, response.data]);
            toast.success("Creación Exitosa");
            handleReset();
            handleCloseGuardar();
          })
          .catch((err) => {
            toast.error("Error de Comunicación, Contacte a TI");
          });
      } else {
        toast.error(validacionDatos);
      }
    }
  };

  const columns: TableColumn<Cuenta>[] = [
    { name: "Banco", selector: (row) => getCuentaBanco(row?.idbanco) },
    { name: "Cuenta contable", selector: (row) => row.cuentacontable },
    { name: "Número de cuenta", selector: (row) => row.numcta },
    { name: "Empresa", selector: (row) => getCuentaEmpresa(row.idempresa) },
    {
      name: "Acciones",
      cell: (row: Cuenta) => (
        <div>
        {permisosAcciones?.actualizar && (
          <button
            onClick={() => handleEdit(row)}
            className="btn btn-primary btn-sm"
          >
            <i className="fa fa-pen"></i> Editar{' '}
          </button>
        )}
          &nbsp;
          {permisosAcciones?.borrar && (
            <button
              onClick={() => handleDelete(row)}
              className="btn btn-danger btn-sm"
            >
              <i className="fa fa-eraser"></i> Eliminar
            </button>
        )}
        </div>
      ),
    },
  ];

  return (
    <div>
      {permisosAcciones?.crear && (
        <Button variant="success" onClick={handleOpen}>
        <i className="fa fa-plus"></i> Agregar{' '}
      </Button>
        )}
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <CuentaTable
          data={data}
          columns={columns}
          customStyles={customStyles}
        />
      )}
      <DialogBox
        open={open}
        onClose={handleClose}
        title={editingData ? "Editar" : "Agregar"}
        content={
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const row: Cuenta = {
                idcuenta: editingData?.idcuenta || 0,
                cuentacontable: editingData?.cuentacontable || 0,
                numcta: formData.get("numcta") as string,
                idbanco: editingData?.idbanco || 0,
                idempresa: editingData?.idempresa || 0,
              };
              handleSave(row);
            }}
          >
            <div className="form-group" id="cuentacontable">
              <label>Cuenta contable</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-university" aria-hidden="true"></i>
                  </span>
                </div>
                <input
                  type="text"
                  name="cuentacontable"
                  inputMode="numeric"
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                    validarInput(e);
                  }}
                  className="form-control "
                  defaultValue={editingData?.cuentacontable}
                  placeholder="Ingrese la cuenta contable"
                  required
                  title="Es necesaria la cuenta cotable"
                />
              </div>
            </div>
            <div className="form-group" id="numcta">
              <label>Número de cuenta</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-id-card" aria-hidden="true"></i>
                  </span>
                </div>
                <input
                  type="text"
                  name="numcta"
                  inputMode="numeric"
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                    validarInput(e);
                  }}
                  className="form-control "
                  defaultValue={editingData?.numcta}
                  placeholder="Ingrese el número de cuenta"
                  required
                  title="Es necesaria la cuenta bancaria"
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="idbanco">Banco</label>
              <select
                className="custom-select rounded-0"
                defaultValue={editingData?.idbanco}
                onChange={handleBancoChange}
                id="idbanco"
                name="idbanco"
              >
                <option value="">Seleccionar banco</option>
                {bancos.map((banco) => (
                  <option key={banco.idbanco} value={banco.idbanco}>
                    {banco.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="idempresa">Empresa</label>
              <select
                className="custom-select rounded-0"
                defaultValue={editingData?.idempresa}
                onChange={handleEmpresaChange}
                id="idempresa"
                name="idempresa"
              >
                <option value="">Seleccionar empresa</option>
                {empresas.map((empresa) => (
                  <option key={empresa.idempresa} value={empresa.idempresa}>
                    {empresa.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="modal-footer justify-content-between">
              <Button
                type="button"
                className="btn btn-danger"
                onClick={handleClose}
              >
                Cerrar
              </Button>
              <Button type="submit" className="btn btn-success">
                {editingData ? "Guardar" : "Agregar"}
              </Button>
            </div>
          </form>
        }
      />
    </div>
  );
};

const customStyles = {
  headCells: {
    style: {
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "Arial",
    },
  },
  rows: {
    style: {
      fontSize: "18px",
    },
  },
  cells: {
    style: {
      fontSize: "16px",
    },
  },
  pagination: {
    style: {
      fontSize: "14px",
    },
  },
};

export default Cuenta;
