import { User } from '@auth0/auth0-react';
import {PayloadAction, createSlice} from '@reduxjs/toolkit';


export interface AuthState {
  isLoggedIn: boolean;
  token: string | null;
  currentUser: any;
}

const initialState: AuthState = {
  isLoggedIn: !!localStorage.getItem('token'),
  token: localStorage.getItem('token'),
  currentUser: null
  // currentUser: {
  //   email: 'mail@siatigroup.com',
  //   picture: null
  // }
};


 

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser: (state, {payload}) => {
 
      localStorage.setItem('token', JSON.stringify(payload));
      localStorage.setItem('user', JSON.stringify(payload));
      state.isLoggedIn = true;
      state.token = payload;
    },
    logoutUser: (state) => {
      localStorage.removeItem('token');
      state.currentUser = {};
      state.isLoggedIn = false;
      state.token = null;
    },
    loadUser: (state, {payload}) => {
      state.currentUser = payload;
    }
    
  }
});

export const {loginUser, logoutUser, loadUser} = authSlice.actions;

export default authSlice.reducer;