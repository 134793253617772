import React from 'react'
import ReporteProdubanco from './reportesprodubanco/ReporteProdubanco'
import FileUploader from './FileUploader'
import ReportePichincha from './reportespichincha/ReportePichincha'


export const Reportes = () => {
  return (
    <section className="content">
    <div className="container-fluid">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Catálagos de Banca</h3>
          <div className="card-tools">
          </div>
        </div>
        <div className="card-body">
          <div className="p-tabview p-component">
            <div className=".p-tabview-nav p-0 pt-1">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-one-tab"
                role="tablist"
              >
                <li className="li.p-highlight">
                  <a
                    className="nav-link active"
                    id="custom-tabs-one-bancopro-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-bancopro"
                    role="tab"
                    aria-controls="custom-tabs-one-bancopro"
                    aria-selected="true"
                    style={{color:'#101D61'}}
                  >
                    Reporte Produbanco
                  </a>
                </li>
                <li className="li.p-highlight">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-cuentas-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-cuentas"
                    role="tab"
                    aria-controls="custom-tabs-one-cuentas"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Cuentas
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-empresa-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-empresa"
                    role="tab"
                    aria-controls="custom-tabs-one-empresa"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Empresas
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-bancopro-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-bancopro"
                    role="tab"
                    aria-controls="custom-tabs-one-bancopro"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Banco Produbanco
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-rep-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-rep"
                    role="tab"
                    aria-controls="custom-tabs-one-rep"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Prueba
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-cac-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-cac"
                    role="tab"
                    aria-controls="custom-tabs-one-cac"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-naturaleza-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-naturaleza"
                    role="tab"
                    aria-controls="custom-tabs-one-naturaleza"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-one-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-one-bancopro"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-bancopro-tab"
                >
                <ReporteProdubanco/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-rep"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-rep-tab"
                >
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-empresa"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-empresa-tab"
                >
 
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-csv"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-csv-tab"
                >
                 
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-tipodeaforo"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-tipodeaforo-tab"
                >
                  
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-lineNego"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-lineNego-tab"
                >
                  
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-naturaleza"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-naturaleza-tab"
                >
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
    </div>
  </section>
  )
}