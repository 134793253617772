import { api } from "./ApiConfiguracionEstadisticas"
export const TipoAforoApi =
{
    getTipoAforo:function()
    {
        return api.get("tipodeaforo")
    },
    getTipoAforoId:function(id: number)
    {
        return api.get(`tipodeaforo/${id}`)
    },
    createTipoAforo: function(data: any){
        return api.post("tipodeaforo", data);
    },
    updateTipoAforo: function(id: number, data: any){
        return api.put(`tipodeaforo/${id}`, data);
    },
    deleteTipoAforo: function(id: number){
        return api.delete(`tipodeaforo/${id}`);
    }
}