import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Sistema } from './Sistema';

interface SistemaTableProps {
  data: Sistema[];
  columns: TableColumn<Sistema>[];
  handleEdit: (row: Sistema) => void;
  handleDelete: (row: Sistema) => void;
}

const SistemaTable: React.FC<SistemaTableProps> = ({
  data,
  columns,
  handleEdit,
  handleDelete
}) => {
  return (
    <div>
      <DataTable<Sistema>
        columns={columns}
        data={data}
        pagination
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por página:',
          rangeSeparatorText: 'de'
        }}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 20, 30]}
        customStyles={customStyles}
        noDataComponent={"No hay registros para mostrar."}
      />
    </div>
  );
};

const customStyles = {
  headCells: {
    style: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontFamily: 'Arial'
    }
  },
  rows: {
    style: {
      fontSize: '18px'
    }
  },
  cells: {
    style: {
      fontSize: '16px'
    }
  },
  pagination: {
    style: {
      fontSize: '14px'
    }
  }
};

export default SistemaTable;