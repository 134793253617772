import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';


type DialogBoxProps = {
  open: boolean,
  onClose: () => void,
  title?: string,
  content?: React.ReactNode,
  actions?: React.ReactNode
};
const dialog = {
  borderRadius: "40px",
};

const DialogBox: React.FC<DialogBoxProps> = ({ open, onClose, title, content, actions }) => {
  return (
    <Dialog open={open} onClose={onClose} className="dialog">
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && <DialogContent className="dialog-box-content">{content}</DialogContent>}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default DialogBox;