import { api } from "./ApiConfiguracionIntegrador"

export const EstadoIntegracionPrefacturaApi = {
    getEstadoIntegracionPrefactura:function()
    {
        return api.get("/api/estadointegracionprefactura")
    },
    getEstadoIntegracionPrefacturaId:function(id: number)
    {
        return api.get(`/api/estadointegracionprefactura/${id}`)
    },
    createEstadoIntegracionPrefactura: function(data: any){
        return api.post("/api/estadointegracionprefactura", data);
    },
    updateEstadoIntegracionPrefactura: function(id: number, data: any){
        return api.put(`/api/estadointegracionprefactura/${id}`, data);
    },
    deleteEstadoIntegracionPrefactura: function(id: number){
        return api.delete(`/api/estadointegracionprefactura/${id}`);
    }
}