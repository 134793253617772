import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import TipoActivo from './TipoActivo';




interface TipoActivoTableProps {
  data: TipoActivo[];
  columns: TableColumn<TipoActivo>[];
  customStyles: Record<string, any>;
}

const TipoActivoTable: React.FC<TipoActivoTableProps> = ({ data, columns, customStyles }) => {
  return (
    <DataTable<TipoActivo>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      customStyles={customStyles}
      noDataComponent={"No hay registros para mostrar."}
    />
  );
};

export default TipoActivoTable;