import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import EstadoDetramite from './EstadoDeTramite';



interface EstadoDetramiteTableProps {
  data: EstadoDetramite[];
  columns: TableColumn<EstadoDetramite>[];
  customStyles: Record<string, any>;
}

const EstadoDetramiteTable: React.FC<EstadoDetramiteTableProps> = ({ data, columns, customStyles }) => {
  return (
    <DataTable<EstadoDetramite>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      customStyles={customStyles}
      noDataComponent={"No hay registros para mostrar."}
    />
  );
};

export default EstadoDetramiteTable;