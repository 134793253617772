import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { TableColumn } from 'react-data-table-component';
import RegistroTable from './RegistroTable';
import { RegistroApi } from '@app/api/bancos/RegistroApi';
import { format } from 'date-fns';

interface Registros {
    idregistro: number;
    nombredearchivo: string;
    fechadecreacion: Date;
  }
const Registros = () => {
    const [registros, setRegistros] = useState<Registros[]>([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchData();
      }, []);
    
      const fetchData = async () => {
        try {
          const registrosResponse = await RegistroApi.getRegistro2();
          setRegistros(registrosResponse.data);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
      };
      const columns: TableColumn<Registros>[] = [
        {name: 'Documento', selector: (row) => row.nombredearchivo },
        {
          name: 'Fecha',
          selector: (row) => format(new Date(row.fechadecreacion), 'dd/MM/yyyy HH:mm:ss'), // Formatea la fecha
        },
      ];
    
  return (
    <section className="content">
    <div className="container-fluid">
     
    <div className='card card-default'>
        <div className="card-header" >
            <h1>
            <FontAwesomeIcon icon={faFile} />    DOCUMENTOS
            </h1>

        </div>
     {loading ? (
        <p>Cargando...</p>
      ) : (
        <RegistroTable
          data={registros}
          columns={columns}
          customStyles={customStyles}
        />
      )}
    </div>
    </div>
    </section>
  )
};
const customStyles = {
    headCells: {
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: 'Arial'
      }
    },
    rows: {
      style: {
        fontSize: '18px'
      }
    },
    cells: {
      style: {
        fontSize: '16px'
      }
    },
    pagination: {
      style: {
        fontSize: '14px'
      }
    }
  };

export default Registros