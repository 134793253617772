import { api } from "./ConfiguracionBancoApi"
export const BancoApi =
{
    getBanco1:function()
    {
        return api.get("banco/registros")
    },
    getBanco:function()
    {
        return api.get("banco")
    },
    getBancoId:function(id: number)
    {
        return api.get(`banco/${id}`)
    },
    creatBanco: function(data: any){
        return api.post("banco", data);
    },
    updateBanco: function(id: number, data: any){
        return api.put(`banco/${id}`, data);
    },
    deleteBanco: function(id: number){
        return api.delete(`banco/${id}`);
    }
}