import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, CircularProgress } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridEventListener, GridRowEditStopReasons, GridRowId, GridRowModes, GridRowModesModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { esES } from '@mui/x-data-grid/locales';
import { ReporteBonesApi } from '@app/api/bancos/ReporteBonesApi';
import * as XLSX from 'xlsx'; // Importar XLSX para manipular Excel
import Swal from 'sweetalert2';
import { BancoApi } from '@app/api/bancos/BancoApi';
import AccionesApi from '@app/api/configurardorPrincipal/AccionesApi';
import MenuPerfilApi from '@app/api/configurardorPrincipal/MenuPerfilApi';
import UsuarioApi from '@app/api/configurardorPrincipal/UserApi';
import { UsuarioPerfilApi } from '@app/api/configurardorPrincipal/UsuarioPerfilApi';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

interface ReporteBone {
  idreportebone: number;
  empresa: string;
  banco: string;
  cuentacontable: string;
  fechaFormatted: string;
  ref1: string;
  categorizacion: string;
  ref2: string;
  modificado: boolean;
  descarga: boolean;
  oficina: string;
  valor: number;
}
interface Banca {
  idbanco: number;
  nombre: string;
  ruc: string;
}
interface Acciones {
  idaccion?: number;
  idmenuperfil?: number;
  idmenu?: number;
  nombre?: string;
  crear: boolean;
  leer: boolean;
  borrar: boolean;
  actualizar: boolean;
}


export default function FullFeaturedCrudGrid() {
  const [rows, setRows] = useState<ReporteBone[]>([]);
  const [filteredData, setFilteredData] = useState<ReporteBone[]>([]);
  const [filterText, setFilterText] = useState('');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});


  const [loading, setLoading] = useState(true);
  const [permisosAcciones, setPermisosAcciones] = useState<Acciones>({
    idaccion: 0,
    idmenuperfil: 0,
    idmenu: 0,
    nombre: "",
    crear: false,
    leer: false,
    borrar: false,
    actualizar: false
  });
  

  const fetchData = async () => {
    try {
      const permisosResponse = await AccionesApi.getAcciones();
      const perfilUsuarioResponse = await UsuarioApi.usuarioPorEmail(localStorage.idToken);
      // Ahora con el idusuario buscaremos el perfil admin, conta, cac o lectura
      const perfilUsuarioIdResponse = await UsuarioPerfilApi.getUsuarioId(perfilUsuarioResponse.data.idusuario);
      const perfilUsuarioData = perfilUsuarioIdResponse.data; // Esta variable contiene el perfil
      // Ahora se consigue el menu
      const menuPerfil = await MenuPerfilApi.getMenuPerfilXId(perfilUsuarioData);

      // Obteniendo permisos del usuario

      const idMenus = menuPerfil.data.map((item: any) => item.menu.idmenu);

      // Paso 1: Obtener una lista de idmenuperfil coincidentes entre permisosResponse.data y perfilUsuarioData
      const idmenuperfilesCoincidentes = permisosResponse.data
        .filter((permiso: any) => permiso.idmenuperfil === perfilUsuarioData)
        .map((permiso: any) => permiso.idmenuperfil);

      // Paso 2: Obtener una lista de idmenu coincidentes entre permisosResponse.data y idMenus
      const idmenusCoincidentes = permisosResponse.data
        .filter((permiso: any) => idMenus.includes(permiso.idmenu))
        .map((permiso: any) => permiso.idmenu);

      // Paso 3: Filtrar permisos correspondientes a los idmenuperfil y idmenu coincidentes
      const permisosCoincidentes = permisosResponse.data.filter((permiso: any) =>
        idmenuperfilesCoincidentes.includes(permiso.idmenuperfil) &&
        idmenusCoincidentes.includes(permiso.idmenu)
      );

      // Paso 4: Extraer los permisos crear, leer, borrar, actualizar de los permisos coincidentes
      const permisosAcciones: any = permisosCoincidentes.reduce((acc: any, permiso: any) => {
        acc.crear = acc.crear || permiso.crear;
        acc.leer = acc.leer || permiso.leer;
        acc.borrar = acc.borrar || permiso.borrar;
        acc.actualizar = acc.actualizar || permiso.actualizar;
        return acc;
      }, { crear: false, leer: false, borrar: false, actualizar: false });
      // Dentro de la función fetchData
      setPermisosAcciones({
        idaccion: 0,
        idmenuperfil: 0,
        idmenu: 0,
        nombre: "",
        crear: permisosAcciones.crear,
        leer: permisosAcciones.leer,
        borrar: permisosAcciones.borrar,
        actualizar: permisosAcciones.actualizar
      });


      const response = await ReporteBonesApi.getReporteBones();
      const dataWithIds = response.data.map((row: ReporteBone) => ({
        ...row,
        id: row.idreportebone,
        modificado: row.modificado,
        descarga: row.descarga,
      }));
      setRows(dataWithIds); // Asegúrate de que la respuesta sea un array de objetos
      setFilteredData(dataWithIds); // Inicializamos los datos filtrados
      setLoading(false); 
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columnVisibilityModel = React.useMemo(() => {
    
    if (permisosAcciones?.leer && (permisosAcciones?.crear || permisosAcciones?.borrar || permisosAcciones?.actualizar)!) {
      return {
        categorizacion: true,
        modificado: true,
        descarga: true,
      };
    } else if (permisosAcciones?.leer && permisosAcciones?.crear && permisosAcciones?.borrar && permisosAcciones?.actualizar) {
      
      return {
        
        categorizacion: false,
        modificado: false,
        descarga: false,
      };
    }
    return {
      categorizacion: false,
      modificado: false,
      descarga: false,
    };
  }, [permisosAcciones]);
  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
};

const handleSaveClick = (id: GridRowId) => async () => {
  const editedRow = rows.find((row) => row.idreportebone === id);
  if (editedRow) {
    try {
      await processRowUpdate(editedRow);
      setRowModesModel((prev) => ({
        ...prev,
        [id]: { mode: GridRowModes.View },
      }));
    } catch (error) {
      console.error("Error al guardar la fila:", error);
    }
  }
};










  function CustomToolbar() {
    return (
      <GridToolbarContainer>

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
          </div>

          <div>
          {(permisosAcciones.crear || permisosAcciones.actualizar || permisosAcciones.borrar) && (
           <>
           <Button
              variant="contained"
              color="primary"
              style={{ marginRight: '10px',  color:'white', background:'#F18A00'}}
              onClick={handleCustomExport}
            >
              <i className="fas fa-file"></i>
              &nbsp;
              Informe final
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: '10px', color:'white', background:'#212C55' }}
              onClick={handleCustomExport1}
            >
              <i className="fas fa-file"></i>
              &nbsp;
              Informe depósitos contables
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: '10px',  color:'white', background:'#F18A00' }}
              onClick={handleCustomExport2}
            >
              <i className="fas fa-file"></i>
              &nbsp;
              Informe depósitos no identificados
            </Button>
            </>
               )}
            <GridToolbarExport
            sx={{
              '& .MuiButton-root': {
                color: 'white',
                background: '#212C55',
                '&:hover': {
                  background: '#F18A00',
                  },
                  },
            }}
              csvOptions={{
                fileName: 'informe_DEPOSITOS_CARTERA_O_DP CARTERA',
                delimiter: ';',
                utf8WithBom: true,
              }} />
          </div>
        </div>
        <br />
      </GridToolbarContainer>
    );
  }
  const processRowUpdate = async (newRow: ReporteBone) => {
    const updatedRow = { ...newRow, modificado: true };
  
    try {
      await ReporteBonesApi.updateReporteBones(updatedRow.idreportebone, updatedRow);
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.idreportebone === updatedRow.idreportebone ? updatedRow : row
        )
      );
      filterData(filterText, startDate, endDate); // Filtra los datos después de la actualización
      return updatedRow;
    } catch (error) {
      console.error('Error al actualizar el reporte:', error);
      throw error; // Lanza el error para manejarlo en el bloque catch
    }
  };
  



  // Manejadores para los filtros
  const handleFilterText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase().trim();
    setFilterText(value);
    filterData(value, startDate, endDate);
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setStartDate(value);
    filterData(filterText, value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEndDate(value);
    filterData(filterText, startDate, value);
  };

  // Función que aplica los filtros a los datos
  const filterData = (text: string, start: string | null, end: string | null) => {
    const filteredItems = rows.filter((item) => {
      const matchesText = Object.values(item).some((val) =>
        val && val.toString().toLowerCase().includes(text)
      );
      const dateColumn = item['fechaFormatted'];
      const dateMatches =
        (!start || new Date(dateColumn) >= new Date(start)) &&
        (!end || new Date(dateColumn) <= new Date(end));
      return matchesText && dateMatches;
    });
    setFilteredData(filteredItems);
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.idreportebone === id);
    if (editedRow!.modificado) {
      setRows(rows.filter((row) => row.idreportebone !== id));
    }
  };
  const handleCustomExport = async () => {
    // Filtrar las filas donde descarga es false
    const filteredRows = rows.filter(row => (row.categorizacion === 'CARTERA') && row.descarga === false);

    // Verificar si todas las filas ya están descargadas (descarga = true)
    if (filteredRows.length === 0) {
      Swal.fire({
        icon: 'info',
        title: '¡Descargas al día!',
        text: 'Todos los registros ya han sido descargados.',
      });
      return;
    }

    // Seleccionar las columnas específicas para exportar
    const exportData = filteredRows.map(row => ({
      empresa: row.empresa,
      banco: row.banco,
      cuentacontable: row.cuentacontable,
      fecha: row.fechaFormatted,
      ref1: row.ref1,
      ref2: row.ref2,
      oficina: row.oficina,
      valor: row.valor
    }));

    // Crear el archivo de Excel
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte");

    // Descargar el archivo
    XLSX.writeFile(workbook, "informe_DEPOSITOS_CARTERA_O_DP CARTERA.xlsx");

    // Actualizar la columna "descarga" a true en la base de datos y en el estado local
    try {
      await Promise.all(filteredRows.map(row =>
        ReporteBonesApi.updateReporteFinal(row.idreportebone, { ...row, descarga: true })
      ));

      // Actualizar el estado con las filas actualizadas
      const updatedRows = rows.map(row =>
        filteredRows.some(filteredRow => filteredRow.idreportebone === row.idreportebone)
          ? { ...row, descarga: true }
          : row
      );
      setRows(updatedRows);

      // Mostrar mensaje de éxito
      Swal.fire({
        icon: 'success',
        title: 'Exportación completa',
        text: 'Los registros seleccionados han sido exportados y actualizados.',
      });
    } catch (error) {
      console.error('Error al actualizar los registros:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un problema al actualizar los registros.',
      });
    }
  };
  const handleCustomExport1 = async () => {
    // Filtrar las filas donde descarga es false
    const filteredRows = rows.filter(row => (row.categorizacion === 'CAJA' || row.categorizacion === 'DP CONT.') && row.descarga === false);

    // Verificar si todas las filas ya están descargadas (descarga = true)
    if (filteredRows.length === 0) {
      Swal.fire({
        icon: 'info',
        title: '¡Descargas al día!',
        text: 'Todos los registros ya han sido descargados.',
      });
      return;
    }

    // Seleccionar las columnas específicas para exportar
    const exportData = filteredRows.map(row => ({
      empresa: row.empresa,
      banco: row.banco,
      cuentacontable: row.cuentacontable,
      fecha: row.fechaFormatted,
      ref1: row.ref1,
      ref2: row.ref2,
      oficina: row.oficina,
      valor: row.valor
    }));

    // Crear el archivo de Excel
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte");

    // Descargar el archivo
    XLSX.writeFile(workbook, "informe_DEPOSITOS_CONT_CAJA_O_DP_CONT_CAJA.xlsx");

    // Actualizar la columna "descarga" a true en la base de datos y en el estado local
    try {
      await Promise.all(filteredRows.map(row =>
        ReporteBonesApi.updateReporteFinal(row.idreportebone, { ...row, descarga: true })
      ));

      // Actualizar el estado con las filas actualizadas
      const updatedRows = rows.map(row =>
        filteredRows.some(filteredRow => filteredRow.idreportebone === row.idreportebone)
          ? { ...row, descarga: true }
          : row
      );
      setRows(updatedRows);

      // Mostrar mensaje de éxito
      Swal.fire({
        icon: 'success',
        title: 'Exportación completa',
        text: 'Los registros seleccionados han sido exportados y actualizados.',
      });
    } catch (error) {
      console.error('Error al actualizar los registros:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un problema al actualizar los registros.',
      });
    }
  };
  const handleCustomExport2 = async () => {
    const bancoData = await BancoApi.getBanco();
    const bancos = bancoData.data; // Obtener la lista completa de bancos

    // Mapear los nombres de los bancos a sus respectivos RUCs
    const bancoRUCs: { [nombre: string]: string } = {};
    bancos.forEach((banco: Banca) => {
      bancoRUCs[banco.nombre] = banco.ruc;
    });
    // Filtrar las filas donde descarga es false
    const filteredRows = rows.filter(row => (row.categorizacion === 'BOX' || row.categorizacion === 'TARJETA') && row.descarga === false);

    // Verificar si todas las filas ya están descargadas (descarga = true)
    if (filteredRows.length === 0) {
      Swal.fire({
        icon: 'info',
        title: '¡Descargas al día!',
        text: 'Todos los registros ya han sido descargados.',
      });
      return;
    }

    // Seleccionar las columnas específicas para exportar
    const exportData = filteredRows.map(row => ({

      cuentacontable: row.cuentacontable,
      concepto: 'REG. DP NO IDENTIFICADOS CLIENTES BOX',
      ref1: row.ref1,
      ref2: row.ref2,
      debe: '',
      haber: '',
      fecha1: row.fechaFormatted,
      fecha2: row.fechaFormatted,
      banco: row.banco,
      ctacte: '',
      cheque: row.ref1,
      cc: '',
      proyecto: '',
      valorP: 'P',
      codid: bancoRUCs[row.banco],
    }));

    // Crear el archivo de Excel
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte");

    // Descargar el archivo
    XLSX.writeFile(workbook, "reporte3.xlsx");

    // Actualizar la columna "descarga" a true en la base de datos y en el estado local
    try {
      await Promise.all(filteredRows.map(row =>
        ReporteBonesApi.updateReporteFinal(row.idreportebone, { ...row, descarga: true })
      ));

      // Actualizar el estado con las filas actualizadas
      const updatedRows = rows.map(row =>
        filteredRows.some(filteredRow => filteredRow.idreportebone === row.idreportebone)
          ? { ...row, descarga: true }
          : row
      );
      setRows(updatedRows);

      // Mostrar mensaje de éxito
      Swal.fire({
        icon: 'success',
        title: 'Exportación completa',
        text: 'Los registros seleccionados han sido exportados y actualizados.',
      });
    } catch (error) {
      console.error('Error al actualizar los registros:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un problema al actualizar los registros.',
      });
    }
  };



  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esES}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              height: 500,
              width: '100%',
              overflow: 'initial',
              '& .super-app-theme--header': {
                backgroundColor: 'rgba(0,73, 196, 0.21)',
              },
              '& .bg-categorizacion-column': {
                backgroundColor: '#f7f7f7',
              },
              '& .bg-ref2-column': {
                backgroundColor: 'beige',
              },
            }}>
            {/* Filtros */}
            <br />
            <br />
            <Box sx={{ mb: 2 }}>
              <TextField
                label="Filtrar por texto"
                variant="outlined"
                value={filterText}
                onChange={handleFilterText}
                sx={{ mr: 2 }}
              />
              <TextField
                label="Fecha de inicio"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={startDate ?? ''}
                onChange={handleStartDateChange}
                sx={{ mr: 2 }}
              />
              <TextField
                label="Fecha de fin"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={endDate ?? ''}
                onChange={handleEndDateChange}
              />
            </Box>

            {/* DataGrid */}
            <DataGrid
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },

              }}
              rows={filteredData}

              columns={[
                {
                  field: 'empresa', headerName: 'Empresa', width: 150, headerClassName: 'super-app-theme--header',
                  headerAlign: 'center', align: 'center'
                },
                {
                  field: 'banco', headerName: 'Banco', width: 150, headerClassName: 'super-app-theme--header',
                  headerAlign: 'center', align: 'center'
                },
                {
                  field: 'cuentacontable', headerName: 'Reporte Bones', width: 150, headerClassName: 'super-app-theme--header',
                  headerAlign: 'center', align: 'center'
                },
                {
                  field: 'fechaFormatted', headerName: 'Fecha', width: 150, headerClassName: 'super-app-theme--header',
                  headerAlign: 'center', align: 'center'
                },
                {
                  field: 'ref1', headerName: 'Ref 1', width: 150, headerClassName: 'super-app-theme--header',
                  headerAlign: 'center'
                },
                {
                  field: 'categorizacion',
                  headerName: 'Categorización',
                  width: 170,
                  editable:  permisosAcciones.actualizar, 
                  type: 'singleSelect',
                  valueOptions: ['TARJETA', 'NO IDENT.', 'BOX', 'CARTERA', 'CAJA', 'DP CONT.'],
                  headerClassName: 'super-app-theme--header',
                  cellClassName: 'bg-categorizacion-column',
                  headerAlign: 'center',
                  align: 'center'
                },
                {
                  field: 'ref2',
                  headerName: 'Ref 2',
                  width: 150,
                  editable: true,
                  headerClassName: 'super-app-theme--header',
                  cellClassName: 'bg-ref2-column',
                  headerAlign: 'center',
                  align: 'left'
                },
                {
                  field: 'modificado',
                  headerName: 'Modificado',
                  width: 150,
                  headerClassName: 'super-app-theme--header',
                  headerAlign: 'center',
                  align: 'center',
                  renderCell: (params) =>
                    params.row.modificado ? <span style={{ color: 'green' }}>✓</span> : <span style={{ color: 'red' }}>✗</span>,
                },
                {
                  field: 'oficina', headerName: 'Oficina', width: 150, headerClassName: 'super-app-theme--header',
                  headerAlign: 'center', align: 'center'
                },
                {
                  field: 'valor', headerName: 'Valor', width: 150, headerClassName: 'super-app-theme--header',
                  headerAlign: 'center', align: 'center'
                },
                {
                  field: 'actions',
                  type: 'actions',
                  headerName: 'Actions',
                  width: 100,
                  cellClassName: 'actions',
                  getActions: ({ id }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            
                    if (isInEditMode) {
                      return [
                        <GridActionsCellItem
                          icon={<SaveIcon />}
                          label="Save"
                          sx={{
                            color: 'primary.main',
                          }}
                          onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                          icon={<CancelIcon />}
                          label="Cancel"
                          className="textPrimary"
                          onClick={handleCancelClick(id)}
                          color="inherit"
                        />,
                      ];
                    }
            
                    return [
                      <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                      />,
                    ];
                  },
                },
                {
                  field: 'descarga',
                  headerName: 'Descarga',
                  width: 150,
                  headerClassName: 'super-app-theme--header',
                  headerAlign: 'center',
                  align: 'center',
                  renderCell: (params) => (
                    params.value ? (
                      <i className="fa fa-check-circle" style={{ color: 'green' }} aria-hidden="true" title="Descargado"></i>
                    ) : (
                      <i className="fa fa-times-circle" style={{ color: 'red' }} aria-hidden="true" title="No descargado"></i>
                    )
                  )
                }
              ]}
              getRowId={(row) => row.idreportebone}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              columnVisibilityModel={columnVisibilityModel}
              slots={{
                toolbar: CustomToolbar,
              }}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              slotProps={{
                toolbar: { setRows, setRowModesModel, rowModesModel, rows }, // Asegúrate de pasar los estados necesarios
            }}
            />
          </Box>
        )}
      </MuiPickersUtilsProvider>
    </div>
  );
}
