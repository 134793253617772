import { api } from "./ApiConfiguracionIntegrador"

export const NuevoProyectoMagayaApi =  {
    getNuevosProyectosMagaya:function()
    {
        return api.get("api/nuevoproyecto")
    },
    getNuevoProyectoMagayaId:function(id: number)
    {
        return api.get(`api/nuevoproyecto/${id}`)
    },
  
}
