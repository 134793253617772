import React, {useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button} from 'react-bootstrap';
import {
  DataGrid,
  GridColDef,
  GridToolbar
} from '@material-ui/data-grid';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import {Box, Dialog, DialogContent} from '@material-ui/core';
import {Tooltip} from '@mui/material';
import { ProyectoApi } from '@app/api/integradorcontabilidad/ProyectoApi';
import {Chart, ArcElement} from 'chart.js/auto';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import Typography from '@mui/material/Typography'; 
import 'chartjs-plugin-datalabels';
import EstadoIntegracionProyecto from './estadointegracionproyecto/EstadoIntegracionProyecto';
import { EstadoIntegracionProyectoApi } from '../../api/integrador/EstadoIntegracionProyectoApi';
interface Proyecto {
  idproyecto: number,
  fechacreacion: Date,
  empresa: string,
  sucursal: string,
  numeroproyecto: string,
  masternumber: string,
  numerohija: string,
  ruccliente: string,
  nombrecliente: string,
  nombrenaturaleza: string,
  nombretrafico: string,
  nombrepaisorigen: string,
  nombremodalidad: string,
  nombrepuertoembarque: string,
  lineaservicionombrecorto: string,  
  codigopaisorigen: string,
  nombrepaisdestino: string,
  codigopaisdestino: string,
  incoterm: string,
  valorfob: string,
  pesofacturado: number,   
  contenedores20tn: number,
  contenedores40tn: number,
  contenedores45tn: number,
  nombreunidadpeso: string,
  contenedores: number,
  totalventa: number,
  totalcosto: number,
  codigoEjecutivo: string,
  nombre: string
}


export default function DashboardIntegrador() {

  const [data, setData] = useState<Proyecto[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(true);
  const [bancos, setBancos] = useState<string[]>([]);
  const [selectedBanco, setSelectedBanco] = useState<string>('Todos los estados de integración');
  const [open, setOpen] = useState(false);
  const [proyectos, setProyectos] = useState<Proyecto[]>([]);
  const [filtro, setFiltro] = useState<string>('');
  const [campoFiltrado, setCampoFiltrado] = useState<string>('fechacreacion');
  const [modoGrafico, setModoGrafico] = useState<'cantidad' | 'sumatoria'>('cantidad'); 
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function CustomToolbar() {
    const iconColor = '#3F51B5'; 
    return (
      <div>
        <GridToolbar /> 
        <IconButton  onClick={handleOpen} style={{ color: iconColor }}>
        <ZoomOutMapIcon /> 
        &nbsp; 
        <Typography variant="body1" style={{ color: iconColor }}>Ampliar</Typography>
      </IconButton>
      </div>
    );
  }
  function CustomToolbar1() {
    const iconClose = '#6f3130'; 
    return (
      <div>
        <GridToolbar />
         
        <IconButton  onClick={handleClose} style={{ color: iconClose }}>
        <CloseIcon /> 
        &nbsp; 
        <Typography variant="body1" style={{ color: iconClose }}>Cerrar</Typography>
      </IconButton>
      </div>
    );
  }
  const localeText = {
    // Textos de la barra de herramientas (toolbar)
    toolbarDensity: 'Densidad',
    toolbarDensityLabel: 'Densidad',
    toolbarDensityCompact: 'Compacta',
    toolbarDensityStandard: 'Estándar',
    toolbarDensityComfortable: 'Confortable',
    toolbarFilters: 'Filtros',
    toolbarExport: 'Exportar',
    toolbarColumns: 'Columnas',
    toolbarColumnsLabel: 'Seleccionar columnas',
    toolbarFiltersLabel: 'Mostrar filtros',
    toolbarFiltersTooltipHide: 'Ocultar filtros',
    toolbarFiltersTooltipShow: 'Mostrar filtros',
    toolbarFiltersTooltipActive: (count: number) =>
      count > 1 ? `${count} filtros activos` : `${count} filtro activo`,
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Buscar…',
    toolbarQuickFilterLabel: 'Buscar',
    toolbarQuickFilterDeleteIconLabel: 'Limpiar',

    // Export selector toolbar button text
    toolbarExportLabel: 'Exportar',
    toolbarExportCSV: 'Descargar como CSV',
    toolbarExportPrint: 'Imprimir',
    toolbarExportExcel: 'Descargar como Excel',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Columna de búsqueda',
    columnsPanelTextFieldPlaceholder: 'Título de columna',
    columnsPanelDragIconLabel: 'Reordenar columna',
    columnsPanelShowAllButton: 'Mostrar todo',
    columnsPanelHideAllButton: 'Ocultar todo',

    // Filter panel text
    filterPanelAddFilter: 'Agregar filtro',
    filterPanelRemoveAll: 'Remover todos',
    filterPanelDeleteIconLabel: 'Borrar',
    filterPanelLogicOperator: 'Operador lógico',
    filterPanelOperator: 'Operadores',
    filterPanelOperatorAnd: 'Y',
    filterPanelOperatorOr: 'O',
    filterPanelColumns: 'Columnas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Valor de filtro',

    // Filter operators text
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'es igual',
    filterOperatorStartsWith: 'comienza con',
    filterOperatorEndsWith: 'termina con',
    filterOperatorIs: 'es',
    filterOperatorNot: 'no es',
    filterOperatorAfter: 'es posterior',
    filterOperatorOnOrAfter: 'es en o posterior',
    filterOperatorBefore: 'es anterior',
    filterOperatorOnOrBefore: 'es en o anterior',
    filterOperatorIsEmpty: 'está vacío',
    filterOperatorIsNotEmpty: 'no esta vacío',
    filterOperatorIsAnyOf: 'es cualquiera de'
  };
  
  const handleBancoChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedBanco(event.target.value);
  };
  useEffect(() => {
    const fetchBancos = async () => {
      try {
        const response = await EstadoIntegracionProyectoApi.getEstadoIntegracionProyecto();
        const nombresBancos = response.data.map((banco: any) => banco.nombre);
        setBancos(nombresBancos);
      } catch (error) {
        console.error('Error al obtener los datos del banco:', error);
      }
    };

    fetchBancos();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);
    useEffect(() => {
    // Filtrar las columnas del DataGrid por el rango de fechas y el banco seleccionado
    const filteredData = data.filter((row) => {
      if (selectedStartDate && selectedEndDate ) {
        const startDate = selectedStartDate.setHours(0, 0, 0, 0);
        const endDate = selectedEndDate.setHours(23, 59, 59, 999);
        const rowDate = new Date(row.fechacreacion).getTime();
        return (
          rowDate >= startDate &&
          rowDate <= endDate
        );
      } else if (selectedStartDate && selectedEndDate) {
        const startDate = selectedStartDate.setHours(0, 0, 0, 0);
        const endDate = selectedEndDate.setHours(23, 59, 59, 999);
        const rowDate = new Date(row.fechacreacion).getTime();
        return rowDate >= startDate && rowDate <= endDate;
      }
      return true;
    });
    setData(filteredData);
  }, [selectedStartDate, selectedEndDate]);
  const fetchData = async () => {
    try {
      const response = await ProyectoApi.getProyecto1();
      const dataWithIds = response.data.map((row: Proyecto) => ({
        ...row,
        id: row.idproyecto
      }));
      setData(dataWithIds);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };
  const handleSearch = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSelectedBanco('Todos los estados de integración');
    fetchData();
  };
  useEffect(() => {
    ProyectoApi.filtreProyecto(campoFiltrado, filtro)
      .then((response) => {
        setProyectos(response.data);
      })
      .catch((error) => console.error('Error al obtener proyectos', error));
  }, [campoFiltrado, filtro]);

  const columns: GridColDef[] = [
    {
      field: 'fechacreacion',
      headerName: 'Fecha',
      width: 150,
      valueFormatter: (params) => {
        if (
          typeof params.value === 'string' ||
          typeof params.value === 'number'
        ) {
          const date = new Date(params.value).toLocaleDateString('es-ES');
          return date;
        }
        return '';
      }
    },
    {field: 'empresa', headerName: 'Empresa', width: 150, align:'center'},
    {field: 'sucursal', headerName: 'Sucursal', width: 150, align:'center'},
    {field: 'numeroproyecto', headerName: 'N° Proyecto', width: 160},
    {field: 'masternumber', headerName: 'N° Master', width: 160},
    {field: 'numerohija', headerName: 'N° hija', width: 150},
    {field: 'ruccliente', headerName: 'N° RUC Cliente', width: 150},
    {field: 'nombrecliente', headerName: 'Nombre Cliente', width: 150},
    {field: 'nombrenaturaleza', headerName: 'Naturaleza', width: 150},
    {field: 'nombretrafico', headerName: 'Tráfico', width: 150, align:'center'},
    {field: 'nombremodalidad', headerName: 'Modalidad', width: 150, align:'center'},
    {field: 'nombrepaisorigen', headerName: 'País de origen', width: 170},
    {field: 'lineaservicionombrecorto', headerName: 'Línea de servicio', width: 200},
    {field: 'incoterm', headerName: 'Incoterm', width: 150, align:'center'},
    {field: 'valorfob', headerName: 'valorfob', width: 150, align:'center'},
    {field: 'pesofacturado', headerName: 'Peso facturado', width: 200, align:'center'},
    {field: 'contenedores20tn', headerName: 'Contenedores 20tn', width: 200, align:'center'},
    {field: 'contenedores40tn', headerName: 'Contenedores 40tn', width: 200, align:'center'},
    {field: 'contenedores45tn', headerName: 'Contenedores 45tn', width: 200, align:'center'},
    {field: 'contenedores', headerName: 'Contenedores', width: 200, align:'center'},
    {field: 'nombreunidadpeso', headerName: 'Unidad de peso', width: 200, align:'center'},
    {field: 'totalventa', headerName: 'Total venta', width: 200, align:'center'},
    {field: 'totalcosto', headerName: 'Total venta', width: 200, align:'center'},
    {field: 'codigo_ejecutivo', headerName: 'Código del ejecutivo', width: 200, align:'center'},
    {field: 'nombre', headerName: 'Estado de Integración', width: 200, align:'center'},
  ];
  useEffect(() => {
    if (!loading) {
      createPieChart(data);
    }
  }, [loading, data]);
  useEffect(() => {
    if (!loading) {
      createPieChart1(data);
    }
  }, [loading, data]);
  useEffect(() => {
    if (!loading) {
      createPieChart2(data, modoGrafico);
    }
  }, [loading, data]);
  useEffect(() => {
    if (!loading) {
      createPieChart3(data);
    }
  }, [loading, data]);
  const mostrarCantidad = () => {
    setModoGrafico('cantidad');
  };

  const mostrarSumatoria = () => {
    setModoGrafico('sumatoria');
  };
  const createPieChart = (data: any[]) => {
    const canvasRef = document.getElementById('pieChart') as HTMLCanvasElement | null;
  
    if (canvasRef) {
      // Agrupa los datos por nombre de tráfico y calcula el total para cada nombre
      const filteredData = data.filter((row) => row.nombretrafico !== null && row.idproyecto !== null);
      const existingChart = Chart.getChart(canvasRef);
      if (existingChart) {
        existingChart.destroy();
      }
      const groupedData = filteredData.reduce((acc, row) => {
        if (!acc[row.nombretrafico]) {
          acc[row.nombretrafico] = 0;
        }
        acc[row.nombretrafico] += row.idproyecto;
        return acc;
      }, {});
  
      // Convierte los datos agrupados en arreglos para labels y values
      const labels = Object.keys(groupedData);
      const values = labels.map((nombretrafico) => groupedData[nombretrafico]);
  
      // Calcula el total de proyectos para calcular los porcentajes
      const totalProyectos = values.reduce((acc, value) => acc + value, 0);
  
      // Calcula los porcentajes
      const percentages = values.map((value) => ((value / totalProyectos) * 100).toFixed(2));
  
      new Chart(canvasRef, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [
            {
              data: values,
              backgroundColor: [
                'rgba(241, 138, 0, 0.6)',
                'rgba(33, 44, 85, 0.6)',
                'rgba(241, 138, 0, 0.6)',
                // Puedes agregar más colores según la cantidad de datos
              ],
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              color: 'white',
              font: {
                size: 14,
              },
              formatter: (value: any, context: any) => {
                const percentage = percentages[context.dataIndex];
                return `${value} (${percentage}%)`;
              },
            },
          },
        },
      });
    }
  };
  const createPieChart1 = (data: any[]) => {
    const canvasRef = document.getElementById('pieChart1') as HTMLCanvasElement | null;
  
    if (canvasRef) {
      // Agrupa los datos por nombre de tráfico y calcula el total para cada nombre
      const filteredData = data.filter((row) => row.nombremodalidad !== null && row.idproyecto !== null);
      const existingChart = Chart.getChart(canvasRef);
      if (existingChart) {
        existingChart.destroy();
      }
      const groupedData = filteredData.reduce((acc, row) => {
        if (!acc[row.nombremodalidad]) {
          acc[row.nombremodalidad] = 0;
        }
        acc[row.nombremodalidad] += row.idproyecto;
        return acc;
      }, {});
  
      // Convierte los datos agrupados en arreglos para labels y values
      const labels = Object.keys(groupedData);
      const values = labels.map((nombremodalidad) => groupedData[nombremodalidad]);
  
      // Calcula el total de proyectos para calcular los porcentajes
      const totalProyectos = values.reduce((acc, value) => acc + value, 0);
  
      // Calcula los porcentajes
      const percentages = values.map((value) => ((value / totalProyectos) * 100).toFixed(2));
  
      new Chart(canvasRef, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [
            {
              data: values,
              backgroundColor: [
                'rgba(241, 138, 0, 0.6)',
                'rgba(33, 44, 85, 0.6)',
                'rgba(241, 138, 0, 0.6)',
                // Puedes agregar más colores según la cantidad de datos
              ],
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              color: 'white',
              font: {
                size: 14,
              },
              formatter: (value: any, context: any) => {
                const percentage = percentages[context.dataIndex];
                return `${value} (${percentage}%)`;
              },
            },
          },
        },
      });
    }
  };
  const filteredData = data.filter((row) => {
    if (selectedStartDate && selectedEndDate) {
      const startDate = selectedStartDate.setHours(0, 0, 0, 0);
      const endDate = selectedEndDate.setHours(23, 59, 59, 999);
      const rowDate = new Date(row.fechacreacion).getTime();
      return rowDate >= startDate && rowDate <= endDate;
    }
    return true;
  });

  const createPieChart2 = (data: any[],  mode: 'cantidad' | 'sumatoria') => {
    const canvasRef = document.getElementById('pieChart2') as HTMLCanvasElement | null;
  
    if (canvasRef) {
      // Agrupa los datos por nombre de tráfico y calcula el total para cada nombre
      const filteredData = data.filter((row) => row.nombrepaisorigen !== null && row.idproyecto !== null);
      const existingChart = Chart.getChart(canvasRef);
      if (existingChart) {
        existingChart.destroy();
      }
      const groupedData = filteredData.reduce((acc, row) => {
        if (!acc[row.nombrepaisorigen]) {
          acc[row.nombrepaisorigen] = 0;
        }
        if (mode === 'cantidad') {
          acc[row.nombrepaisorigen] += 1; // Contar la cantidad de elementos en la columna
        } else if (mode === 'sumatoria') {
          acc[row.nombrepaisorigen] += row.idproyecto; // Sumar los valores en la columna
        }
        return acc;
      }, {});
  
      // Convierte los datos agrupados en arreglos para labels y values
      const labels = Object.keys(groupedData);
      const values = labels.map((nombrepaisorigen) => groupedData[nombrepaisorigen]);
  
      // Calcula el total de proyectos para calcular los porcentajes
      const totalProyectos = values.reduce((acc, value) => acc + value, 0);
  
      // Calcula los porcentajes
      const percentages = values.map((value) => ((value / totalProyectos) * 100).toFixed(2));
  
      new Chart(canvasRef, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [
            {
              data: values,
              backgroundColor: [
                'rgba(241, 138, 0, 0.6)',
                'rgba(33, 44, 85, 0.6)',
                'rgba(241, 138, 0, 0.6)',
                // Puedes agregar más colores según la cantidad de datos
              ],
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              color: 'white',
              font: {
                size: 14,
              },
              formatter: (value: any, context: any) => {
                const percentage = percentages[context.dataIndex];
                if (mode === 'cantidad') {
                  return `${value} (${percentage}%)`;
                } else if (mode === 'sumatoria') {
                  return `${value} (${percentage}%)`;
                }
                return '';
              },
            },
          },
        },
      });
    }
  };
  const createPieChart3 = (data: any[]) => {
    const canvasRef = document.getElementById('pieChart3') as HTMLCanvasElement | null;
  
    if (canvasRef) {
      // Agrupa los datos por nombre de tráfico y calcula el total para cada nombre
      const filteredData = data.filter((row) => row.lineaservicionombrecorto !== null && row.idproyecto !== null);
      const existingChart = Chart.getChart(canvasRef);
      if (existingChart) {
        existingChart.destroy();
      }
      const groupedData = filteredData.reduce((acc, row) => {
        if (!acc[row.lineaservicionombrecorto]) {
          acc[row.lineaservicionombrecorto] = 0;
        }
        acc[row.lineaservicionombrecorto] += row.idproyecto;
        return acc;
      }, {});
  
  
      // Convierte los datos agrupados en arreglos para labels y values
      const labels = Object.keys(groupedData);
      const values = labels.map((lineaservicionombrecorto) => groupedData[lineaservicionombrecorto]);
      const totalProyectos = values.reduce((acc, value) => acc + value, 0);
      const percentages = values.map((value) => ((value / totalProyectos) * 100).toFixed(2));
      new Chart(canvasRef, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [
            {
              data: values,
              backgroundColor: [
                'rgba(241, 138, 0, 0.6)',
                'rgba(33, 44, 85, 0.6)',
                'rgba(241, 138, 0, 0.6)',
              ],
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              color: 'white',
              font: {
                size: 14,
              },
              formatter: (value: any, context: any) => {
                const percentage = percentages[context.dataIndex];
                return `${value} (${percentage}%)`;
              },
            },
          },
        },
      });
    }
  };
  return (

    <div className="container-fluid">
      <div className="row">
        <div className="col-md-20">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Catálagos de Integrador</h3>
          <div className="card-tools">
          </div>
        </div>
        <div className="card-body">
        <section className="content">
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-7">
        {/* AREA CHART */}
        <div className="card card-navy">
          <div className="card-header">
            <h3 className="card-title">Filtros</h3>
            <div className="card-tools">
              <button type="button" className="btn btn-tool" data-card-widget="collapse">
                <i className="fas fa-minus" />
              </button>
              <button type="button" className="btn btn-tool" data-card-widget="remove">
                <i className="fas fa-times" />
              </button>
            </div>
          </div>
          <div className="card-body">
<MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <div>
          <label>Buscar por fecha o rango de fecha: </label>
 
            <div className="col">
              <Tooltip title="Ingrese la fecha inicial" id={''}>
                <DatePicker
                  label="Fecha de inicio"
                  value={selectedStartDate}
                  onChange={setSelectedStartDate}
                  format="dd/MM/yyyy"
                />
              </Tooltip>
              &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <Tooltip title="Ingrese la fecha final" id={''}>
                <DatePicker
                  label="Fecha de fin"
                  value={selectedEndDate}
                  onChange={setSelectedEndDate}
                  format="dd/MM/yyyy"
                  aria-modal='true'
                />
              </Tooltip>
            </div>
             <br />
            <div className="col-6">
              <label>Buscar por estado de integración: </label>
              <Tooltip title="Filtrar la información por el nombre del banco" id={''}>
                <select
                  className="form-control"
                  style={{width: '100%'}}
                  value={selectedBanco}
                  onChange={handleBancoChange}
                >
                  <option value="">Todos los estados de integración</option>
                  {bancos.map((nombreBanco) => (
                    <option key={nombreBanco} value={nombreBanco}>
                      {nombreBanco}
                    </option>
                  ))}
                </select>
              </Tooltip>
            </div>
            <br />
            <br />
            <div className="col-3">
              <Button variant="secondary" onClick={handleSearch}>
                <i className="fa fa-undo" aria-hidden="true"></i>
                &nbsp; Limpiar filtros
              </Button>

            </div>
        </div>
        
        <br />
        <br />
        {loading ? (
          <p>Cargando...</p>
        ) : (

            <Box style={{height: 500, width: '100%'}}>
              <DataGrid
                rows={data}
                columns={[...columns]}
                onColumnOrderChange={(params, event, details) => {
                  console.log('Reordered columns:', params);
                  console.log('Event:', event);
                  console.log('Details:', details);
                }}
                components={{
                  Toolbar: CustomToolbar // Reemplaza la Toolbar predeterminada con tu CustomToolbar
                }}
                localeText={localeText}
              />
            </Box>

        )}
              <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogContent>
          <DataGrid
                rows={data}
                columns={[...columns]}
                onColumnOrderChange={(params, event, details) => {
                  console.log('Reordered columns:', params);
                  console.log('Event:', event);
                  console.log('Details:', details);
                }}
                components={{
                  Toolbar: CustomToolbar1 
                }}
                localeText={localeText}
            autoHeight
          />
        </DialogContent>
      </Dialog>
      </MuiPickersUtilsProvider>

          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
        {/* DONUT CHART */}

        {/* /.card */}
        {/* PIE CHART */}

        {/* /.card */}
      </div>
      {/* /.col (LEFT) */}
      <div className="col-md-4">
        {/* LINE CHART */}
        {/* <Button onClick={mostrarCantidad}>Mostrar Cantidad</Button>
      <Button onClick={mostrarSumatoria}>Mostrar Sumatoria</Button> */}
        <div className="card card-orange">
          <div className="card-header">
            <h3 className="card-title">Modalidad</h3>
            <div className="card-tools">
              <button type="button" className="btn btn-tool" data-card-widget="collapse">
                <i className="fas fa-minus" />
              </button>
              <button type="button" className="btn btn-tool" data-card-widget="remove">
                <i className="fas fa-times" />
              </button>
            </div>
          </div>
          <div className="card-body">
          <canvas id="pieChart1" style={{minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%'}} />
          </div>
          {/* /.card-body */}
        </div>
        <div className="card card-orange">
          <div className="card-header">
            <h3 className="card-title">Tráfico</h3>
            <div className="card-tools">
              <button type="button" className="btn btn-tool" data-card-widget="collapse">
                <i className="fas fa-minus" />
              </button>
              <button type="button" className="btn btn-tool" data-card-widget="remove">
                <i className="fas fa-times" />
              </button>
            </div>
          </div>
          <div className="card-body">
            <canvas id="pieChart" style={{minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%'}} />
          </div>
          {/* /.card-body */}
        </div>
        <div className="card card-orange">
          <div className="card-header">
            <h3 className="card-title">País origen</h3>
            <div className="card-tools">
              <button type="button" className="btn btn-tool" data-card-widget="collapse">
                <i className="fas fa-minus" />
              </button>
              <button type="button" className="btn btn-tool" data-card-widget="remove">
                <i className="fas fa-times" />
              </button>
            </div>
          </div>
          <div className="card-body">
            <canvas id="pieChart2" style={{minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%'}} />
          </div>
          {/* /.card-body */}
        </div>
        <div className="card card-orange">
          <div className="card-header">
            <h3 className="card-title">Línea de servicio</h3>
            <div className="card-tools">
              <button type="button" className="btn btn-tool" data-card-widget="collapse">
                <i className="fas fa-minus" />
              </button>
              <button type="button" className="btn btn-tool" data-card-widget="remove">
                <i className="fas fa-times" />
              </button>
            </div>
          </div>
          <div className="card-body">
            <canvas id="pieChart3" style={{minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%'}} />
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
        {/* BAR CHART */}

        {/* /.card */}
        {/* STACKED BAR CHART */}

        {/* /.card */}
      </div>
      {/* /.col (RIGHT) */}
    </div>
    {/* /.row */}
  </div>{/* /.container-fluid */}
</section>

            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
    </div>

  )
}
