import { ConfiguracionApiActivosFijos } from "./ConfiguracionApiActivosFijos"

export const NombreBienApi =
{
    getNombreBien:function()
    {
        return ConfiguracionApiActivosFijos.get("nombreBien")
    },
    getNombreBienId:function(id: number)
    {
        return ConfiguracionApiActivosFijos.get(`nombreBien/${id}`)
    },
    createNombreBien: function(data: any){
        return ConfiguracionApiActivosFijos.post("nombreBien", data);
    },
    updateNombreBien: function(id: number, data: any){
        return ConfiguracionApiActivosFijos.put(`nombreBien/${id}`, data);
    },
    deleteNombreBien: function(id: number){
        return ConfiguracionApiActivosFijos.delete(`nombreBien/${id}`);
    }
}