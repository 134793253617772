import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MenuItem } from "@components";
import { PfImage } from "@profabric/react-components";
import styled from "styled-components";
import i18n from "@app/utils/i18n";
import { MenuApi } from "@app/api/configurardorPrincipal/MenuApi";
// LIBRERIAS DC INICIO
import {useDispatch, useSelector} from 'react-redux';
import { UsuarioApi } from '@app/api/configurardorPrincipal/UserApi';
import { UsuarioPerfilApi } from '@app/api/configurardorPrincipal/UsuarioPerfilApi';
import MenuPerfilApi from "@app/api/configurardorPrincipal/MenuPerfilApi";


// LIBRERIAS DC FIN

type MainProps = {
  email: string;
  imagen: any;
};
type Menu = {
  idmenu: number;
  nombre: string;
  nivel: number;
  icono: string;
  path?: string;
  menuPadre?: Menu;
};
type MenuDataItem = {
  idmenuperfil: number;
  menu: {
    idmenu: number;
    nombre: string;
    nivel: number;
    icono: string;
    path?: string;
    menuPadre?: Menu;
  };
  perfil: {
    idperfil: number;
    nombre: string;
    observacion: string;
    activo: boolean;
    tipo: string | null;
  };
};

// Dentro de useEffect


const StyledBrandImage = styled(PfImage)`
  float: left;
  line-height: 0.8;
  margin: -1px 8px 0 6px;
  opacity: 0.8;
  --pf-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23) !important;
`;

const StyledUserImage = styled(PfImage)`
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
`;

const MenuSidebar = ({ email, imagen }: MainProps) => {
  const [menuData, setMenuData] = useState<Menu[]>([]);
  // CONSTANTES DC
  const [perfil, setPerfil] = useState(null);
  const user = useSelector((state: any) => state.auth.currentUser);
  // CONSTANTES DC
  // INICIO LOGICA PERFIL DC
  useEffect(() => {
    
    if (user?.data?.idusuario) {
      UsuarioPerfilApi.getUsuarioId(user.data.idusuario)
        .then((response) => {
          setPerfil(response.data);
          MenuPerfilApi.getMenuPerfilXId(response.data)
            .then((responseMenu) => {
              //console.log('esto tiene menufff x 2',MenuPerfilApi.getMenuPerfilXId(response.data));
              //setMenuData(responseMenu.data.map((item: MenuDataItem) => item.menu));
              // Dentro de useEffect
              setMenuData(responseMenu.data.map((item: MenuDataItem) => item.menu));

              //console.log('esto tiene mi menufff', responseMenu.data.map((item: MenuDataItem) => item.menu))
            })
            .catch((error) => {
              console.error('Error al obtener el perfil del usuario', error);
        })
      })
        .catch((error) => {
          console.error('Error al obtener el perfil del usuario', error);
        });
    }
  }, [user?.data?.idusuario]);
  //console.log('perfilfff',perfil);
  // FIN LOGICA PERFIL DC
  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        // IMPLEMENTANDO LOGICA DC INICIO
        const responseUser = await UsuarioApi.usuarioPorEmail(email);
        //console.log('Esto tiene responseUserfff', responseUser);
        
        // IMPLEMENTANDO LOGICA DC FIN
        const responseC = await MenuApi.menus();
        //console.log('Esto tiene responseCffff',responseC.data)
        // const response = await MenuPerfilApi.getMenuPerfilXId(perfil)
        // setMenuData(response.data);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchMenuData();

  }, []);
  const formatMenuList = (menus: Menu[]) => {
    //console.log('Que es estoff?', menus.map((menu) => JSON.stringify(menu)).join(","));
    return menus.map((menu) => JSON.stringify(menu)).join(",");
    
  };
  return (
    <aside className={`main-sidebar elevation-4 sidebar-light-navy`}>
      <Link to="/" className="brand-link">
        <StyledBrandImage
          src="/img/SiatiImg.png"
          alt="Siati Logo"
          width={33}
          height={33}
          rounded
        />
        <span className="brand-text font-weight-dark">Siati</span>
      </Link>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <StyledUserImage
              src={imagen}
              fallbackSrc="/img/default-profile.png"
              alt="User"
              width={34}
              height={34}
              rounded
            />
          </div>
          <div className="info">
            <Link to="/" className="d-block">
              {email}
            </Link>
          </div>
        </div>

        {/* <nav className="mt-2" style={{ overflowY: "hidden" }}>
          <ul className={`nav nav-pills nav-sidebar flex-column`} role="menu">
            {menuData
              .filter((menuItem: Menu) => !menuItem.menuPadre)
              .map((parentMenu: Menu) => (
                <MenuItem
                  key={parentMenu.idmenu}
                  menuItem={{
                    name: parentMenu.nombre,
                    icon: parentMenu.icono,
                    children: menuData
                      .filter(
                        (childMenu: Menu) =>
                          childMenu.menuPadre?.idmenu === parentMenu.idmenu
                      ) 
                      .map((childMenu: Menu) => ({
                        name: childMenu.nombre,
                        icon: childMenu.icono,
                        path: childMenu.path || "",
                      })),
                  }}
                />
              ))}
          </ul>
        </nav> */}
        <nav className="mt-2" style={{ overflowY: "hidden" }}>
          <ul className={`nav nav-pills nav-sidebar flex-column`} role="menu">
            {menuData && menuData
              .filter((menuItem: Menu) => !menuItem.menuPadre)
              .map((parentMenu: Menu) => {
                //console.log("Menú padre:", parentMenu.nombre);
                return (
                  <MenuItem
                    key={parentMenu.idmenu}
                    menuItem={{
                      name: parentMenu.nombre,
                      icon: parentMenu.icono,
                      children: menuData
                        .filter(
                          (childMenu: Menu) =>
                            childMenu.menuPadre?.idmenu === parentMenu.idmenu
                        )
                        .map((childMenu: Menu) => {
                          //console.log("Menú hijo:", childMenu.nombre);
                          return {
                            name: childMenu.nombre,
                            icon: childMenu.icono,
                            path: childMenu.path || "",
                          };
                        }),
                    }}
                  />
                );
              })}
          </ul>
        </nav>

      </div>
    </aside>
  );
};

export default MenuSidebar;
