import React, {useEffect, useState} from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import 'react-confirm-alert/src/react-confirm-alert.css';

import NuevoProyectoMagayaTable from './NuevoProyectoMagayaTable';
import { NuevoProyectoMagayaApi } from '@app/api/integrador/NuevoProyectoMagayaApi';

interface NuevoProyectoMagaya {
  idnuevoproyectomagaya: number;
  identificadormagaya: string;
  numeroregistrolibro: string;
  numeroreserva: string;
  fechacreacion: string;
  observacion: string;
  sincronizado: boolean;
}

const NuevoProyectoMagaya: React.FC = () => {
  const [data, setData] = useState<NuevoProyectoMagaya[]>([]);
  const [editingData, setEditingData] = useState<NuevoProyectoMagaya | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activo, setActivo] = useState<boolean>(editingData?.sincronizado || false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseGuardar = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    handleReset();
  };

  const handleReset = () => {
    setEditingData(null);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await NuevoProyectoMagayaApi.getNuevosProyectosMagaya();
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  const columns: TableColumn<NuevoProyectoMagaya>[] = [
    {name: 'Identificador Magaya', selector: (row) => row.identificadormagaya},
    {name: 'Number', selector: (row) => row.numeroreserva},
    {name: 'Booking Number', selector: (row) => row.numeroregistrolibro},
    {name: 'Fecha Creación', selector: (row) => row.fechacreacion},
    {name: 'Observación', selector: (row) => row.observacion},
  ];

  return (
    <div>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <NuevoProyectoMagayaTable
          data={data}
          columns={columns}
          customStyles={customStyles}
        />
      )}
    </div>
  );
};

const customStyles = {
  headCells: {
    style: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontFamily: 'Arial'
    }
  },
  rows: {
    style: {
      fontSize: '18px'
    }
  },
  cells: {
    style: {
      fontSize: '16px'
    }
  },
  pagination: {
    style: {
      fontSize: '14px'
    }
  }
};

export default NuevoProyectoMagaya;