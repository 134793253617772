import { api } from "./ConfiguracionBancoApi"
export const CuentaApi =
{
    getCuenta1:function()
    {
        return api.get("cuenta/registros")
    },
    getCuenta:function()
    {
        return api.get("cuenta")
    },
    getCuentaId:function(id: number)
    {
        return api.get(`cuenta/${id}`)
    },
    createCuenta: function(data: any){
        return api.post("cuenta", data);
    },
    updateCuenta: function(id: number, data: any){
        return api.put(`cuenta/${id}`, data);
    },
    deleteCuenta: function(id: number){
        return api.delete(`cuenta/${id}`);
    }
}