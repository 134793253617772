import { ConfiguracionApiActivosFijos } from "./ConfiguracionApiActivosFijos"

export const TipoMovimientoApi =
{
    getTipoMovimiento:function()
    {
        return ConfiguracionApiActivosFijos.get("tipoMovimientoActivo")
    },
    getTipoMovimientoId:function(id: number)
    {
        return ConfiguracionApiActivosFijos.get(`tipoMovimientoActivo/${id}`)
    },
    createTipoMovimiento: function(data: any){
        return ConfiguracionApiActivosFijos.post("tipoMovimientoActivo", data);
    },
    updateTipoMovimiento: function(id: number, data: any){
        return ConfiguracionApiActivosFijos.put(`tipoMovimientoActivo/${id}`, data);
    },
    deleteTipoMovimiento: function(id: number){
        return ConfiguracionApiActivosFijos.delete(`tipoMovimientoActivo/${id}`);
    }
}