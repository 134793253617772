import { api } from "./ApiConfiguracionIntegrador"

export const BaseMagayaApi = {
    getBaseMagaya:function()
    {
        return api.get("/api/basedatosmagaya")
    },
    getBaseMagayaId:function(id: number)
    {
        return api.get(`/api/basedatosmagaya/${id}`)
    },
    createBaseMagaya: function(data: any){
        return api.post("/api/basedatosmagaya", data);
    },
    updateBaseMagaya: function(id: number, data: any){
        return api.put(`/api/basedatosmagaya/${id}`, data);
    },
    deleteBaseMagaya: function(id: number){
        return api.delete(`/api/basedatosmagaya/${id}`);
    }
}
