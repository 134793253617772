import { api } from "./ApiConfiguracionIntegrador"

export const CuentaContableMagayaApi = {
    getCuentaContableMagaya:function()
    {
        return api.get("/api/cuentacontablemagaya")
    },
    getCuentaContableMagayaId:function(id: number)
    {
        return api.get(`/api/cuentacontablemagaya/${id}`)
    },
    createCuentaContableMagaya: function(data: any){
        return api.post("/api/cuentacontablemagaya", data);
    },
    updateCuentaContableMagaya: function(id: number, data: any){
        return api.put(`/api/cuentacontablemagaya/${id}`, data);
    },
    deleteCuentaContableMagaya: function(id: number){
        return api.delete(`/api/cuentacontablemagaya/${id}`);
    }
}