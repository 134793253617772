import { api } from "./ApiConfiguracionEstadisticas"
export const TipoDeTramiteApi =
{
    getTipoDeTramite:function()
    {
        return api.get("tipotramite")
    },
    getTipoDeTramiteById:function(id: number)
    {
        return api.get(`tipotramite/${id}`)
    },
    createTipoDeTramite: function(data: any){
        return api.post("tipotramite", data);
    },
    updateTipoDeTramite: function(id: number, data: any){
        return api.put(`tipotramite/${id}`, data);
    },
    deleteTipoDeTramite: function(id: number){
        return api.delete(`tipotramite/${id}`);
    }
}