import { api } from "./ConfiguracionGeneralApi"

export const MenuApi = {

  createMenu: function(data: any){
    return api.post("menu", data);
  },
  menus: function(){
    return api.get("menu/todos");
  },

  getMenuId:function(idMenu: number)
  {
      return api.get(`menu/obtener/${idMenu}`)
  },
  putMenu:function(idMenu: number, data: any)
  {
    return api.put(`menu/actualiza/${idMenu}`, data)
  },
};

export default MenuApi;