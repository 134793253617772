import { api } from "./ApiConfiguracionIntegrador"

export const SistemaApi =  {
    getSistema:function()
    {
        return api.get("/api/sistema")
    },
    getSistemaId:function(id: number)
    {
        return api.get(`/api/sistema/${id}`)
    },
    createSistema: function(data: any){
        return api.post("/api/sistema", data);
    },
    updateSistema: function(id: number, data: any){
        return api.put(`/api/sistema/${id}`, data);
    },
    deleteSistema: function(id: number){
        return api.delete(`/api/sistema/${id}`);
    }
}
