import { api } from "./ConfiguracionBancoApi"
export const ReporteBonesApi =
{
    getReporteBones:function()
    {
        return api.get("reportebones")
    },
    getReporteBones1:function()
    {
        return api.get("reportebones/editar")
    },
    getReporteBones2:function()
    {
        return api.get("reportebones/registros")
    },
    getReporteBonesId:function(id: number)
    {
        return api.get(`reportebones/${id}`)
    },
    createReporteBones: function(data: any){
        return api.post("reportebones", data);
    },
    updateReporteBones: function(id: number, data: any){
        return api.put(`reportebones/${id}`, data);
    },
    updateReporteFinal: function(id: number, data: any){
        return api.put(`reportebones/final/${id}`, data);
    },
    deleteReporteBones: function(id: number){
        return api.delete(`reportebones/${id}`);
    }
}