import { ConfiguracionApiActivosFijos } from "./ConfiguracionApiActivosFijos"

export const EstadoFisicoApi =
{
    getEstadoFisico:function()
    {
        return ConfiguracionApiActivosFijos.get("estadoFisico")
    },
    getEstadoFisicoId:function(id: number)
    {
        return ConfiguracionApiActivosFijos.get(`estadoFisico/${id}`)
    },
    createEstadoFisico: function(data: any){
        return ConfiguracionApiActivosFijos.post("estadoFisico", data);
    },
    updateEstadoFisico: function(id: number, data: any){
        return ConfiguracionApiActivosFijos.put(`estadoFisico/${id}`, data);
    },
    deleteEstadoFisico: function(id: number){
        return ConfiguracionApiActivosFijos.delete(`estadoFisico/${id}`);
    }
}