import { api } from "./ApiConfiguracionEstadisticas"
export const NaturalezaFacturaApi =
{
    getNaturalezaFactura:function()
    {   
        return api.get("naturalezafactura")
    },
    getNaturalezaFacturaById:function(id: number)
    {
        return api.get(`naturalezafactura/${id}`)
    },
    createNaturalezaFactura: function(data: any){
        return api.post("naturalezafactura", data);
    },
    updateNaturalezaFactura: function(id: number, data: any){
        return api.put(`naturalezafactura/${id}`, data);
    },
    deleteNaturalezaFactura: function(id: number){
        return api.delete(`naturalezafactura/${id}`);
    }
}
