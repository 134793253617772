// DC SCRIPT PARA UNA NUEVA PESTAÑA INICIO
// LIBRERIAS INICIO
import React, {useEffect, useRef, useState} from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button} from 'react-bootstrap';
import {toast} from 'react-toastify';
import DialogBox from '../../../components/DialogBox';
import EditIcon from "@mui/icons-material/EditTwoTone";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { PerfilApi } from '@app/api/configurardorPrincipal/PerfilApi';
import AsignarMenuTable from './AsignarMenuTable';
import { UsuarioPerfilApi } from '@app/api/configurardorPrincipal/UsuarioPerfilApi';
import UsuarioApi from '@app/api/configurardorPrincipal/UserApi';
import MenuPerfilApi from "@app/api/configurardorPrincipal/MenuPerfilApi";
import { Select, MenuItem } from "@material-ui/core";
import MenuApi from "@app/api/configurardorPrincipal/MenuApi";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/CloseTwoTone";

import {
    IconButton,
    TableContainer,
    Card,
    CardContent,
    Typography,
    FormControl,
    InputLabel,
  } from "@material-ui/core";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import Menu from '../Menu/Menu';
// LIBRERIAS FIN

// VARIABLES INICIO 
interface AsignarMenu {
    menu: {
        idMenuPerfil: number;
        nombre: string;
    };
    perfil: {
        idPerfil:number;
        nombre: string;
    };
}

interface Perfiles {
    idperfil: number;
    nombre: string;
  }
interface Menus {
    idmenu: number;
    nombre: string;
}
interface MenuPerfil {
    idmenu: number;
    idperfil: number;
}
// VARIABLES FIN

// FUNCION ASIGNAR MENU INICIO
const AsignarMenu: React.FC = () => {
    const [data, setData] = useState<AsignarMenu[]>([]); // para asignar menus
    const [perfil, setPerfil] = useState<Perfiles[]>([]);// para asignar perfiles todos
    const [menus, setMenus] = useState<Menus[]>([]); // PARA ASIGNAR LOS MENUS TODOS
    const [selectedPerfil, setSelectedPerfil] = useState<number | null>(null); // PARA MOSTRAR EL PERFIL SELECCIONADO
    const [selectedMenu, setSelectedMenu] = useState<number | null>(null); // PARA MOSTRAR EL MENU SELECCIONADO
    const [editingMenuPerfil, setEditingMenuPerfil] = useState<MenuPerfil[]>([]);// PARA EDITAR EL MENU
    const [editingData, setEditingData] = useState<AsignarMenu | null>(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    // const handleErase = (row){

    // }
    const handleCloseGuardar = () => {
        setOpen(false);
      };
    const handleReset = () => {
        setEditingData(null);

      };
    const handleClose = () => {
        setOpen(false);
        handleReset();
      };
    // Metodo fetchData inicio
    useEffect(() => {
        fetchData();
      }, []);
    const fetchData = async () => {
        try {
            const response = await PerfilApi.getPerfil();
            setPerfil(response.data);
            // ok funciona trae los perfiles existentes 
            console.log("Esto tiene perfile data",response.data)

            // trayendo todos los menus existentes
            const responseMenusTodos = await MenuApi.menus();
            setMenus(responseMenusTodos.data);
          //setData(response.data);
    

        //   const menuData = await MenuPerfilApi.getMenuPerfil(); 
        //   console.log("Esto estoy mandando",menuData.data);
        //   setDataMenu(menuData.data);

            const menuData = await MenuPerfilApi.getMenuPerfil(); 
            const modifiedData = menuData.data.map((item:any) => ({
                menu: { nombre: item.menu.nombre, idMenuPerfil: item.menu.idmenu },
                perfil: { nombre: item.perfil.nombre, idPerfil: item.perfil.idperfil }

            }));
            setData(modifiedData);
            console.log("Esto estoy mandando", modifiedData)
        //   setMenus(menuData.data.reduce((acc: { [key: number]: Menu[] }, menu: Menu) => {
        //     if (!acc[menu.idmenu]) {
        //       acc[menu.idmenu] = [];
        //     }
        //     acc[menu.idmenu].push(menu);
        //     console.log("Esto estoy mandando",acc)
        //     return acc;
        //   }, {}));


          setLoading(false);
        } catch (error) {
          console.error("Error al obtener los datos:", error);
        }
      };
    // Metodo fetchData fin

    // Metodo handleEdit inicio
    const handleDelete = async (row: AsignarMenu) => {
        console.log("Editaaaaaaar menu:", row.menu.idMenuPerfil);

        console.log("Editaaaaaaar perfil:", row.perfil.idPerfil);
        console.log("ESTO VOY A MANDAR A ELIMINAR", row.perfil.idPerfil,row.menu.idMenuPerfil);

        await MenuPerfilApi.deleteMenuPerfil(row.perfil.idPerfil,row.menu.idMenuPerfil);
        toast.success("Elemento Eliminado");

        fetchData();

        //setEditingData(row);
        // setEditingDataMenuPerfil(row1);

        //handleOpen();
      };
    // metodo handleEdit Fin

    //Metodo handleopen inicio
    const handleOpen = () => {
        setOpen(true);
      };
    //Metodo handleopen fin
    const handleSave = async (row: MenuPerfil) => {
        try {
            // Obtener datos del perfil por su ID
            const validacionPerfil = await MenuPerfilApi.getMenuPerfilXId(row.idperfil);
            console.log("Validación del Perfil:", validacionPerfil.data);
            
            // Verificar si el menú ya está asignado al perfil
            const menuAsignado = validacionPerfil.data.menus.find((menu: any) => menu.idmenu === row.idmenu);
            if (menuAsignado) {
                // Si el menú ya está asignado, mostrar un mensaje de advertencia y salir de la función
                toast.dismiss("Menú ya asignado a ese Perfil");
                return;
            }
            
            // Si el menú no está asignado, crear el nuevo registro de MenuPerfil
            await MenuPerfilApi.createMenuPerfil({
                menu: {
                    idmenu: row.idmenu,
                },
                perfil: {
                    idperfil: row.idperfil,
                }
            });
            
            // Mostrar mensaje de éxito y actualizar datos
            
            toast.success("Actualización Exitosa");
            fetchData();
            handleCloseGuardar();
        } catch (error) {
            // Manejar errores de manera adecuada
            console.error("Error al guardar el menú del perfil:", error);
            // Mostrar un mensaje de error al usuario si es necesario
            toast.error("El menú ya ha sido asignado a este perfil anteriormente");
        }
    };
    


    const columns: TableColumn<AsignarMenu>[] = [
        { 
            name: "Perfil", 
            selector: (row) => {
                //console.log("Datos de perfil:", row.perfil.idPerfil);
                return row.perfil.nombre;
            }
        },
        { 
            name: "Menús", 
            selector: (row) => {
                //console.log("Datos de menú:", row.menu.idMenuPerfil);
                return row.menu.nombre;
            }
        },

          {
          name: "Eliminar",
          cell: (row: AsignarMenu) => (
            <div>
            <button                                     
              onClick={() => handleDelete(row)}
              className="btn btn-danger btn-sm"
            >
              <i className="fa fa-eraser"></i> Eliminar
            </button>
            </div>
          ),
        },
      ];

    const titleTextStyle = {
        borderBottom: "2px solid tomato",
        paddingBottom: "10px",
        marginBottom: "15px",
      };
    return (
        <div>
            <IconButton onClick={handleOpen} style={{ color: "#212C55" }}>
                
                <AddIcon />

            </IconButton>
            {loading ? (
            <p>Cargando...</p>
            ) : (
            <>
                <TableContainer>
                <AsignarMenuTable
                    data={data}
                    columns={columns}
                    customStyles={customStyles}
                />
                </TableContainer>
            </>
            )}
            <DialogBox
                open={open}
                onClose={handleClose}
                content={
                    <Card style={roundedCardStyle}>
                        <CardContent className="card-body">
                        <Typography
                            variant="h6"
                            component="div"
                            gutterBottom
                            style={titleTextStyle}
                        >
                            Asignar menú<ToggleOffIcon />
                        </Typography>
                        <form
                            onSubmit={(event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const row: MenuPerfil ={
                                idmenu: selectedMenu || 0,
                                idperfil: selectedPerfil || 0,

                            }
                            console.log("esto tiene row", row);
                            handleSave(row);
                            }}
>
                            <div className="form-group" id="idmenuperfil">
                            <FormControl style={{ width: "100%" }}>
                                <InputLabel>Perfiles</InputLabel>
                                <Select
                                name="idperfil"
                                className="form-control"
                                value={selectedPerfil}
                                onChange={(e)=>{
                                    const selectedPerfil = e.target.value as number;
                                    setSelectedPerfil(selectedPerfil);

                                }}
                                >
                                <MenuItem value="" disabled>
                                    Seleccione un perfil
                                </MenuItem>
                                {perfil.map((perfil) => (
                                    <MenuItem key={perfil.idperfil} value={perfil.idperfil}>
                                        {perfil.nombre}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            <FormControl style={{ width: "100%" }}>
                                <InputLabel>Menús</InputLabel>
                                <Select
                                name="idmenu"
                                className="form-control"
                                value={(selectedMenu)
                                }
                                onChange={(e2) => {
                                    const selectedMenu = e2.target.value as number;
                                    setSelectedMenu(selectedMenu);

                                }}
                                >
                                <MenuItem value="" disabled>
                                    Seleccione un menú
                                </MenuItem>
                                {menus.map((menu) => (
                                    <MenuItem key={menu.idmenu} value={menu.idmenu}>
                                    {menu.nombre}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            </div>
                            <div className="card-footer">
                            <ButtonGroup size="lg" className="mb-2">
                                <IconButton onClick={handleClose} style={{ color: "red" }}>
                                <CloseIcon />
                                </IconButton>
                                <span style={{ margin: "0 70px" }}>&nbsp;</span>
                                <IconButton type="submit" style={{ color: "#212C55" }}>
                                {editingData ? <SaveIcon /> : <SaveIcon />}
                                </IconButton>
                            </ButtonGroup>
                            </div>
                        </form>
                        </CardContent>
                    </Card>
                }
            />
    </div>

      );
};
const roundedCardStyle = {
    borderRadius: "10px",
    overflow: "hidden",
  };
const customStyles = {
    headCells: {
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        fontFamily: "Arial",
      },
    },
    rows: {
      style: {
        fontSize: "18px",
      },
    },
    cells: {
      style: {
        fontSize: "16px",
      },
    },
    pagination: {
      style: {
        fontSize: "14px",
      },
    },
  };
export default AsignarMenu;
// FIN FUNCION ASIGNAR MENU
// DC SCRIPT PARA UNA NUEVA PESTAÑA FIN
