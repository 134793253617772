import React, {useRef, useEffect, useState} from 'react';
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import {Button} from 'react-bootstrap';
import swal from 'sweetalert';
import { ArchivoApi } from '@app/api/integrador/ArchivoApi';

const DropzoneComponent: React.FC = () => {
  const dropzoneRef = useRef<HTMLDivElement | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');

  const handleFileChange = (e: { target: { files: React.SetStateAction<null>[]; }; }) => {
    setSelectedFile(e.target.files[0]);
  };
 
  const dropzoneStyle: React.CSSProperties = {
    backgroundColor: 'silver', 
  };
  useEffect(() => {
    if (dropzoneRef.current) {
      const dropzone = new Dropzone(dropzoneRef.current, {
        url: 'http://192.168.0.55:8080/integrador/api/archivos/upload', 
        paramName: 'file',
        maxFilesize: 10, // Tamaño máximo del archivo en MB
        maxFiles: 5, // Número máximo de archivos permitidos
        acceptedFiles: '.jpg,.jpeg,.png,.pdf', // Tipos de archivo permitidos
        autoProcessQueue: false // Evita la carga automática para manejarla manualmente

      });

      dropzone.on('addedfile', (file) => {
        // Manejar el evento cuando se agrega un archivo
        swal(`Subió el archivo`, 'Para guardar el archivo en base, debe dar clic en el botón Cargar archivos','success');
        console.log('Archivo añadido:', file);
        setUploadedFiles((prevFiles) => [...prevFiles, file.name]);
        setSelectedFileName(file.name);
      });
    return () => {
        dropzone.destroy();
    };
  }
  }, []);

  
  const handleUpload = async () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.dropzone.processQueue();

      try {
        const formData = new FormData();
        uploadedFiles.forEach((file) => {
          formData.append('file', file);
        });

        ArchivoApi.createArchivo(uploadedFiles);
        console.log('Archivos subidos exitosamente.');
      } catch (error) {
        console.error('Error al subir archivos:', error);
      }
    }
  };

  return (
    <div style={dropzoneStyle}>
       &nbsp; &nbsp;
      <div className="row" >
        <div className="col-3"></div>
        <div className="col-6">
          <div ref={dropzoneRef} className="dropzone">
            <div className="dz-message">

              Arrastra y suelta archivos aquí para cargarlos
            </div>
          </div>
        </div>
      </div>
      &nbsp; &nbsp;
      <div className="row">
        <div className="col-5"></div>
          <div className="col-4">
          <Button onClick={handleUpload} variant="warning">
            <i className="fa fa-upload" aria-hidden="true"></i>
            &nbsp; Cargar archivos
          </Button>
        </div>
        </div>
        &nbsp; &nbsp;
    </div>
  );
};

export default DropzoneComponent;
