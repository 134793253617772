import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import TipoMovimiento from './TipoMovimiento';





interface TipoMovimientoTableProps {
  data: TipoMovimiento[];
  columns: TableColumn<TipoMovimiento>[];
  customStyles: Record<string, any>;
}

const TipoMovimientoTable: React.FC<TipoMovimientoTableProps> = ({ data, columns, customStyles }) => {
  return (
    <DataTable<TipoMovimiento>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      customStyles={customStyles}
      noDataComponent={"No hay registros para mostrar."}
    />
  );
};

export default TipoMovimientoTable;