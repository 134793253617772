import { ConfiguracionApiActivosFijos } from "./ConfiguracionApiActivosFijos"

export const UbicacionFisicaApi =
{
    getUbicacionFisica:function()
    {
        return ConfiguracionApiActivosFijos.get("ubicacionFisica")
    },
    getUbicacionFisicaId:function(id: number)
    {
        return ConfiguracionApiActivosFijos.get(`ubicacionFisica/${id}`)
    },
    createUbicacionFisica: function(data: any){
        return ConfiguracionApiActivosFijos.post("ubicacionFisica", data);
    },
    updateUbicacionFisica: function(id: number, data: any){
        return ConfiguracionApiActivosFijos.put(`ubicacionFisica/${id}`, data);
    },
    deleteUbicacionFisica: function(id: number){
        return ConfiguracionApiActivosFijos.delete(`ubicacionFisica/${id}`);
    }
}