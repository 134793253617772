import React, {useEffect, useState} from 'react';
import swal from 'sweetalert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button, Container} from 'react-bootstrap';
import {DataGrid, GridValueFormatterParams} from '@material-ui/data-grid';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import {ReporteProdubancoApi} from '@app/api/bancos/ReporteProdubancoApi';
import {Box, CircularProgress, Tooltip} from '@material-ui/core';
import {EmpresaApi} from '@app/api/bancos/EmpresaApi';
import DataGridComponent from '@app/components/DataGridComponent';

interface ReporteProdubanco {
  idprodubanco: number;
  fecharealFormatted: string;
  referencia: string;
  referencia2: string;
  descripcion: string;
  masmenos: string;
  valor: number;
  saldocontable: number;
  saldodisponible: number;
  oficina: string;
  codtransaccion: number;
  cuenta: number;
  empresa: string;
}

const ReporteProdubanco: React.FC = () => {
  const [data, setData] = useState<ReporteProdubanco[]>([]);
  const [loading, setLoading] = useState(true);



  

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await ReporteProdubancoApi.getReporteProdubanco();
      const dataWithIds = response.data.map((row: ReporteProdubanco) => ({
        ...row,
        id: row.idprodubanco
      }));
      setData(dataWithIds);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  const columns = [
    {
      field: 'fecharealFormatted',
      headerName: 'Fecha',
      width: 150
    },
    {field: 'referencia', headerName: 'Referencia', width: 150},
    {field: 'referencia2', headerName: 'Referencia 2', width: 150},
    {field: 'descripcion', headerName: 'Descripción', width: 150},
    {field: 'masmenos', headerName: '+/-', width: 100},
    {field: 'valor', headerName: 'valor', width: 150},
    {field: 'saldocontable', headerName: 'Saldo Contable', width: 150},
    {field: 'saldodisponible', headerName: 'Saldo Disponible', width: 150},
    {field: 'codtransaccion', headerName: 'Cod. transacción', width: 150},
    {field: 'cuenta', headerName: 'Cuenta', width: 150},
    {field: 'empresa', headerName: 'Empresa', width: 150},
  ];




  return (
    <div>
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        {loading ? (
           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
           <CircularProgress />
         </Box>
        ) : (

          <Box sx={{ height: 500, width: '100%' }}>
            <DataGridComponent
              data={data}
              columns={columns}

            />
          </Box>

        )}
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default ReporteProdubanco;