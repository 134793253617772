import { api } from "./ApiConfiguracionIntegrador"

export const EstadoIntegracionProyectoApi = {
    getEstadoIntegracionProyecto:function()
    {
        return api.get("/api/estadointegracionproyecto")
    },
    getEstadoIntegracionProyectoId:function(id: number)
    {
        return api.get(`/api/estadointegracionproyecto/${id}`)
    },
    createEstadoIntegracionProyecto: function(data: any){
        return api.post("/api/estadointegracionproyecto", data);
    },
    updateEstadoIntegracionProyecto: function(id: number, data: any){
        return api.put(`/api/estadointegracionproyecto/${id}`, data);
    },
    deleteEstadoIntegracionProyecto: function(id: number){
        return api.delete(`/api/estadointegracionproyecto/${id}`);
    }
}