import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import GrupoServicioContable from './GrupoServicioContable';



interface GrupoServicioContableTableProps {
  data: GrupoServicioContable[];
  columns: TableColumn<GrupoServicioContable>[];
  customStyles: Record<string, any>;
}

const GrupoServicioContableTable: React.FC<GrupoServicioContableTableProps> = ({ data, columns, customStyles }) => {
  return (
    <DataTable<GrupoServicioContable>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      customStyles={customStyles}
      noDataComponent={"No hay registros para mostrar."}
    />
  );
};

export default GrupoServicioContableTable;