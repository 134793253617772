import { api } from "./ApiConfiguracionEstadisticas"
export const EstadoDocumentoApi =
{
    getEstadoDocumento:function()
    {
        return api.get("estadodocumento")
    },
    getEstadoDocumentoId:function(id: number)
    {
        return api.get(`estadodocumento/${id}`)
    },
    createEstadoDocumento: function(data: any){
        return api.post("estadodocumento", data);
    },
    updateEstadoDocumento: function(id: number, data: any){
        return api.put(`estadodocumento/${id}`, data);
    },
    deleteEstadoDocumento: function(id: number){
        return api.delete(`estadodocumento/${id}`);
    }
}