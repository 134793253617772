import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { GridColDef } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { ReportePichinchaApi } from '@app/api/bancos/ReportePichinchaApi';
import { Box, CircularProgress } from '@material-ui/core';
import { EmpresaApi } from '@app/api/bancos/EmpresaApi';
import DataGridComponent from '@app/components/DataGridComponent';

interface ReportePichincha {
  idreportepichincha: number;
  fechaFormatted: string;
  codigo: number;
  concepto: string;
  tipo: string;
  documento: number;
  oficina: string;
  monto: number;
  saldo: number;
  cuenta: number;
  empresa: string;
  FechaFormatted: string;
}
interface CustomerProps {
  onDataChange: (data: ReportePichincha) => void;
}

const ReportePichincha: React.FC<CustomerProps> = ({ onDataChange }) => {
  const [data, setData] = useState<ReportePichincha[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [selectedEmpresa, setSelectedEmpresa] =
    useState<string>('Todas las empresas');
  const [empresas, setEmpresas] = useState<string[]>([]);
  useEffect(() => {
    const fetchEmpresas = async () => {
      try {
        const response = await EmpresaApi.getEmpresa();
        const nombresEmpresas = response.data.map(
          (empresa: any) => empresa.nombre
        );
        setEmpresas(nombresEmpresas);
      } catch (error) {
        console.error('Error al obtener los datos de la empresa:', error);
      }
    };

    fetchEmpresas();
  }, []);




  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await ReportePichinchaApi.getReportePichincha();
      const dataWithIds = response.data.map((row: ReportePichincha) => ({
        ...row,
        id: row.idreportepichincha
      }));
      setData(dataWithIds);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  const columns: GridColDef[] = [

    {
      field: 'fechaFormatted',
      headerName: 'Fecha',
      width: 150,
    },
    { field: 'concepto', headerName: 'Concepto', minWidth: 150, resizable: true },
    { field: 'tipo', headerName: 'Tipo', width: 150 },
    { field: 'documento', headerName: 'Documento', width: 150 },
    { field: 'oficina', headerName: 'Oficina', width: 150 },
    { field: 'monto', headerName: 'Monto', width: 150 },
    { field: 'saldo', headerName: 'saldo', width: 150 },
    { field: 'cuenta', headerName: 'cuenta', width: 150 },
    { field: 'empresa', headerName: 'empresa', width: 250, resizable: true }
  ];



  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        {loading ? (
           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
           <CircularProgress />
         </Box>
        ) : (

          <Box sx={{ height: 500, width: '100%' }}>
            <DataGridComponent
              data={data}
              columns={columns}

            />
          </Box>

        )}
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default ReportePichincha;
