import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import EstadoDocumento from './EstadoDocumento';




interface EstadoDocumentoTableProps {
  data: EstadoDocumento[];
  columns: TableColumn<EstadoDocumento>[];
  customStyles: Record<string, any>;
}

const EstadoDocumentoTable: React.FC<EstadoDocumentoTableProps> = ({ data, columns, customStyles }) => {
  return (
    <DataTable<EstadoDocumento>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      noDataComponent={"No hay registros para mostrar."}
      customStyles={customStyles}
    />
  );
};

export default EstadoDocumentoTable;