import { BancoApi } from '@app/api/bancos/BancoApi';
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';
import { TableColumn } from 'react-data-table-component';
import BancoTable from './BancoTable';

interface Banco {
    idbanco: number;
    nombre: string;
    ruc: string;
  }
const Bancos = () => {
    const [bancos, setBancos] = useState<Banco[]>([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchData();
      }, []);
    
      const fetchData = async () => {
        try {
            const bancosResponse = await BancoApi.getBanco();
            setBancos(bancosResponse.data);
          setLoading(false);
        } catch (error) {
          console.error('Error al obtener los datos:', error);
        }
      };
      const columns: TableColumn<Banco>[] = [
        {name: 'Banco', selector: (row) => row.nombre },
        {name: 'Ruc', selector: (row) => row.ruc},
      ];
    
  return (
    <section className="content">
    <div className="container-fluid">
     
    <div className='card card-default'>
        <div className="card-header" >
            <h1>
            <FontAwesomeIcon icon={faUniversity} /> Bancos
            </h1>

        </div>
     {loading ? (
        <p>Cargando...</p>
      ) : (
        <BancoTable
          data={bancos}
          columns={columns}
          customStyles={customStyles}
        />
      )}
    </div>
    </div>
    </section>
  )
};
const customStyles = {
    headCells: {
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: 'Arial'
      }
    },
    rows: {
      style: {
        fontSize: '18px'
      }
    },
    cells: {
      style: {
        fontSize: '16px'
      }
    },
    pagination: {
      style: {
        fontSize: '14px'
      }
    }
  };

export default Bancos