import React from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import TipoDeAforo from './TipoDeAforo';

interface TipoDeAforoTableProps {
  data: TipoDeAforo[];
  columns: TableColumn<TipoDeAforo>[];
  customStyles: Record<string, any>;
}

const TipoDeAforoTable: React.FC<TipoDeAforoTableProps> = ({
  data,
  columns,
  customStyles
}) => {

  return (
    <DataTable<TipoDeAforo>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      noDataComponent={"No hay registros para mostrar."}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      customStyles={customStyles}
    />
  );
};

export default TipoDeAforoTable;
