import React, { useState } from 'react'; 
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import FileInput from '@app/components/FileInput';
import { Box, Button, CircularProgress, LinearProgress  } from '@mui/material';
import { CourierApi } from '@app/api/courier/CourierApi';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { updateShipmentStatusByPpbNumbers } from '@app/api/courier/ShipmentApi';
import Swal from 'sweetalert2';

interface SheetData {
  [key: string]: any;
}

function Adress() {
  const [data, setData] = useState<SheetData[]>([]); // Datos del archivo subido
  const [apiData, setApiData] = useState<SheetData[]>([]); // Datos obtenidos por la API
  const [loading, setLoading] = useState(false); // Estado de carga

  // Extraer PPB numbers del archivo subido
  const getPpbNumbersFromData = (data: SheetData[]) => {
    return data.map(row => row.PPB).filter((ppb: string) => ppb); // Filtra valores vacíos
  };

  const handleShow = () => {
    const driverObj = driver({
      popoverClass: 'driverjs-theme',
      prevBtnText: 'Anterior',
      nextBtnText: 'Siguiente',
      doneBtnText: 'Finalizar',
      allowClose: true,
      animate: true,
      showProgress: true,
      showButtons: ['next', 'previous', 'close'],
      steps: [
        { element: '#tour-example', popover: { title: 'Tutorial de cargado de direcciones', description: 'En este tutorial, aprenderás a usar la aplicación que permita a los usuarios cargar un archivo con datos, procesar esa información, y luego buscar detalles adicionales de las direcciones. Los datos se mostrarán en una tabla interactiva utilizando una tabla, y aprenderás cómo combinar los datos del archivo con los resultados obtenidos con un clic.', side: "left", align: 'start' } },
        { element: '#upload', popover: { title: 'Subir Archivo', description: 'El primer paso es permitir que el usuario cargue un archivo (como un archivo Excel o CSV) que contenga los datos que deseas procesar.', side: "top", align: 'start' } },
        { element: '#search', popover: { title: 'Buscar Direcciones', description: 'Después de que el usuario carga el archivo, puede buscar información adicional de la dirección.', side: "bottom", align: 'start' } },
        { element: '#data-grid', popover: { title: 'Tabla de Datos', description: 'Aquí se mostrarán los datos obtenidos del archivo y del API.', side: "top", align: 'start' } },
        { element: '#grid-toolbar', popover: { title: 'Barra de herramientas', description: 'En esta barra de herramientas van encontrar botones de columnas, filtros, densidad y exportar archivo. Recuerde que solo puede exportar archivo en formato CSV.', side: "top", align: 'start' } },
        { element: '#updatestatus', popover: { title: 'Actualizar estado', description: 'Con este botón pueden actualizar el estado del consolidado a "Listo entrega"', side: "right", align: 'start' } },
        { popover: { title: 'Fin', description: 'Espero que haya sido de tú utilidad' } }
      ]
    });
    driverObj.drive();
  };

  // Llamada al API para buscar datos por los PPB numbers
  const handleFetchData = async () => {
    try {
      setLoading(true); // Inicia la carga
      const ppbList = getPpbNumbersFromData(data);
      if (ppbList.length > 0) {
        const fetchedData = await CourierApi.Get(ppbList);
        setApiData(fetchedData);
        Swal.fire(
          '¡Listo!',
          'Los datos se han cargado correctamente',
          'success'
        )
      } else {
        console.warn('No PPB numbers available for fetching data.');
        Swal.fire(
          'No hay números de PPB para buscar datos',
          'Por favor, asegúrate de que el archivo tenga al menos un número de PPB',
          'warning'
        )
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal.fire(
        'Error al buscar datos',
        'Por favor, inténtalo de nuevo',
        'error'
      )
    } finally {
      setLoading(false); // Finaliza la carga
    }
  };

  // Función para actualizar el estado de los envíos
  const handleUpdateShipmentStatus = async () => {
    try {
      setLoading(true); // Inicia la carga
      const ppbList = getPpbNumbersFromData(data); // Obtener los PPB numbers
      if (ppbList.length > 0) {
        await updateShipmentStatusByPpbNumbers(ppbList); // Llamar a la función actualizada
        console.log('Shipment status updated successfully');
        Swal.fire(
          '¡Listo!',
          'El estado de los envíos se ha actualizado correctamente',
          'success'
          )
      } else {
        console.warn('No PPB numbers available for updating status.');
        Swal.fire(
          'No hay números de PPB para actualizar el estado',
          'Por favor, asegúrate de que el archivo tenga al menos un número de PPB',
          'warning'
          )
      }
    } catch (error) {
      console.error('Error updating shipment status:', error);
      Swal.fire(
        'Error al actualizar el estado de los envíos',
        'Por favor, inténtalo de nuevo',
        'error'
        )
    } finally {
      setLoading(false); // Finaliza la carga
    }
  };

  // Definir columnas, combinando las columnas del archivo con las del API
  const columns: GridColDef[] = data.length
    ? Object.keys(data[0]).map((key) => ({
        field: key,
        headerName: key.charAt(0).toUpperCase() + key.slice(1),
        flex: 1,
      })).concat(apiData.length > 0 ? Object.keys(apiData[0]).map((key) => ({
        field: `api_${key}`, // Prefijo para diferenciar las columnas API
        headerName: key.charAt(0).toUpperCase() + key.slice(1),
        flex: 1,
      })) : [])
    : [];

  // Combina las filas del archivo con las del API según el campo PPB
  const rows = data.map((row) => {
    const apiRow = apiData.find(apiItem => apiItem.ppb === row.PPB) || {}; // Comparar PPB de ambos
    const prefixedApiRow = Object.fromEntries(
      Object.entries(apiRow).map(([key, value]) => [`api_${key}`, value]) // Prefijo para evitar conflictos de nombres
    );
    return { ...row, ...prefixedApiRow }; // Combina la fila del archivo con la del API
  });

  return (
    <div>
      <h2>
        <i className="fas fa-file-excel"></i>
        &nbsp;
        Subir los archivos
      </h2>
     <br />
      <Box display="flex" alignItems="center" gap={2} marginBottom={2}>

       <FileInput onDataLoaded={setData} />
        <br />
        <Button id="search" variant="contained" style={{ color: 'white', background: '#F18A00' }} onClick={handleFetchData} disabled={loading}>
          <i className="fa fa-truck" aria-hidden="true" />
          &nbsp;
          Buscar Direcciones
        </Button>
        <Button
          id="updatestatus"
          variant="contained"
          onClick={handleUpdateShipmentStatus}
          style={{ color: 'white', background: "#101D61" }}
          disabled={loading}
        >
          <i className="fa fa-check" aria-hidden="true"></i>
          &nbsp;
          Actualizar Estado
        </Button>
        <Button
        color='info'
        variant="text"
        onClick={handleShow}
      >
        <i className="fa fa-question" aria-hidden="true"></i>
        &nbsp;
        Ayuda
      </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        rows.length > 0 && (
          <Box id="data-grid" style={{ height: 400, width: '100%' }}>
            <br />
            <DataGrid
              rows={rows}
              columns={columns}
              getRowId={(row) => row.id || Math.random()} // Generar id si no está presente
              slots={{
                toolbar: () => <GridToolbar id="grid-toolbar" />
              }}
              pageSizeOptions={[100]}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            />
          </Box>
        )
      )}
    </div>
  );
}

export default Adress;
