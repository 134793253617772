import React from 'react'
import Perfil from './Pefil'
import UserProfilesComponent from './usuarioPerfil/UserProfilesComponent'
import Menu from './Menu/Menu'
import Acciones from './Accion/Accion'
import AsignarMenuTable from './AsignarMenu/AsignarMenuTable'
import AsignarMenu from './AsignarMenu/AsignarMenu'





export const CatalogoPerfil = () => {
  return (
    <section className="content">
    <div className="container-fluid">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Catálagos de Perfiles</h3>
          <div className="card-tools">
          </div>
        </div>
        <div className="card-body">
          <div className="p-tabview p-component">
            <div className=".p-tabview-nav p-0 pt-1">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-one-tab"
                role="tablist"
              >
                <li className="li.p-highlight">
                  <a
                    className="nav-link active"
                    id="custom-tabs-one-roll-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-roll"
                    role="tab"
                    aria-controls="custom-tabs-one-roll"
                    aria-selected="true"
                    style={{color:'#101D61'}}
                  >
                    Perfiles
                  </a>
                </li>
                {/* DC asignación de menus inicio */}
                <li className="li.p-highlight">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-action-asignarmenu-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-action-asignarmenu"
                    role="tab"
                    aria-controls="custom-tabs-one-action-asignarmenu"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Asignación de menú
                  </a>
                </li>
                {/* DC asignación de menus fin */}
                <li className="li.p-highlight">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-userPro-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-userPro"
                    role="tab"
                    aria-controls="custom-tabs-one-userPro"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Asignación de perfil
                  </a>
                </li>


                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-menu-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-menu"
                    role="tab"
                    aria-controls="custom-tabs-one-menu"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Menus
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-action-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-action"
                    role="tab"
                    aria-controls="custom-tabs-one-action"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    Acciones
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-pichincha-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-pichincha"
                    role="tab"
                    aria-controls="custom-tabs-one-pichincha"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >

                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-bones-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-bones"
                    role="tab"
                    aria-controls="custom-tabs-one-bones"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >

                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-one-naturaleza-tab"
                    data-toggle="pill"
                    href="#custom-tabs-one-naturaleza"
                    role="tab"
                    aria-controls="custom-tabs-one-naturaleza"
                    aria-selected="false"
                    style={{color:'#101D61'}}
                  >
                    
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-one-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-one-roll"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-roll-tab"
                >
                    <Perfil/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-userPro"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-userPro-tab"
                >
                  <UserProfilesComponent/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-menu"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-menu-tab"
                >
                  <Menu/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-action-asignarmenu"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-action-asignarmenu-tab"
                >
                  <AsignarMenu/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-action"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-action-tab"
                >
                  <Acciones/>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-pichincha"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-pichincha-tab"
                >

                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-bones"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-bones-tab"
                >

                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-one-naturaleza"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-one-naturaleza-tab"
                >
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer"></div>
      </div>
    </div>
  </section>
  )
}