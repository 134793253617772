import {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {loginUser} from '@store/reducers/auth';
import {setWindowClass} from '@app/utils/helpers';
import {Form, InputGroup} from 'react-bootstrap';
import {PfButton, PfCheckbox} from '@profabric/react-components';

// import * as AuthService from '../../services/auth';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import UsuarioApi from '@app/api/configurardorPrincipal/UserApi';

const Register = () => {
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
  const [isFacebookAuthLoading, setFacebookAuthLoading] = useState(false);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const clientID = "120737355509-cn5ceh7td6acv98g73l18mdtb84c6ted.apps.googleusercontent.com";
  const navigate = useNavigate();
  const onFailure = (response: any)=>{
    console.log(response);
  }
  const register = async (email: string, password: string) => {
    try {
      setAuthLoading(true);
      const token = await AuthService.registerByAuth(email, password);
      setAuthLoading(false);
      dispatch(loginUser(token));
      toast.success('El registro es un éxito');
      navigate('/');
    } catch (error: any) {
      toast.error(error.message || 'Failed');
      setAuthLoading(false);
    }
  };
  useEffect(()=>{
    const start = ()=>{
      gapi.auth2.init({
        client_id : clientID,
      })
    }
    gapi.load("client:auth2", start)
  }, [])
  const handleGoogleRegisterSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    const { profileObj } = response as GoogleLoginResponse;
    // Accede al idToken a través de UsuarioApi.createUsuario(profileObj)
    UsuarioApi.createUsuario(profileObj)
      .then((tokenId) => {
        setGoogleAuthLoading(true);
        console.log('Token de Google:', tokenId);
        dispatch(loginUser(tokenId));
        toast.success('La autenticación es exitosa');
        setGoogleAuthLoading(false);
        navigate('/');
      })
      .catch((error) => {
        setGoogleAuthLoading(false);
        const {mensaje} = error.response.data;
        console.error('Error al registrar usuario', error);
        toast.error('Se encontró que el registro ya existe');
      })
      .finally(() => {
        setGoogleAuthLoading(false);
      });
  };
  const registerByGoogle = async () => {
    try {
      setGoogleAuthLoading(true);
      const token = await AuthService.registerByGoogle();
      setGoogleAuthLoading(false);
      dispatch(loginUser(token));
      toast.success('La autenticación es exitosa!');
      navigate('/');
    } catch (error: any) {
      toast.error(error.message || 'Failed');
      setGoogleAuthLoading(false);
    }
  };



  const {handleChange, values, handleSubmit, touched, errors} = useFormik({
    initialValues: {
      email: '',
      password: '',
      passwordRetype: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Dirección de correo electrónico no válida').required('Requerido'),
      password: Yup.string()
        .min(5, 'Debe tener 5 caracteres o más')
        .max(30, 'Debe tener 30 caracteres o menos')
        .required('Requerido'),
      passwordRetype: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required')
        .when('contraseña', {
          is: (val: string) => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref('contraseña')],
            'Ambas contraseñas deben ser iguales'
          )
        })
    }),
    onSubmit: (values) => {
      register(values.email, values.password);
    }
  });

  setWindowClass('hold-transition register-page');

  return (
    <div className="register-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h1">
          <img
              src="/img/SiatiGroupLogo.svg"
              alt="SiatiLogo"
              height={100}
              width={300}
            />
          </Link>
        </div>
        <div className="card-body">
          <p className="login-box-msg">{t<string>('register.registerNew')}</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={values.email}
                  isValid={touched.email && !errors.email}
                  isInvalid={touched.email && !!errors.email}
                />
                {touched.email && errors.email ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                ) : (
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <i className="fas fa-envelope" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </div>
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Contraseña"
                  onChange={handleChange}
                  value={values.password}
                  isValid={touched.password && !errors.password}
                  isInvalid={touched.password && !!errors.password}
                />
                {touched.password && errors.password ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                ) : (
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <i className="fas fa-lock" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </div>

            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="passwordRetype"
                  name="passwordRetype"
                  type="password"
                  placeholder="Vuelva a escribir la contraseña"
                  onChange={handleChange}
                  value={values.passwordRetype}
                  isValid={touched.passwordRetype && !errors.passwordRetype}
                  isInvalid={touched.passwordRetype && !!errors.passwordRetype}
                />

                {touched.passwordRetype && errors.passwordRetype ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.passwordRetype}
                  </Form.Control.Feedback>
                ) : (
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <i className="fas fa-lock" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </div>

            <div className="row">
              <div className="col-7">
                <PfCheckbox checked={false}>
                  <span>Acepto los  </span>
                  <Link to="/">Terminos y Condiciones</Link>
                </PfCheckbox>
              </div>
              <div className="col-5">
                <PfButton
                  type="submit"
                  block
                  loading={isAuthLoading}
                  disabled={isFacebookAuthLoading || isGoogleAuthLoading}
                >
                  {t<string>('register.label')}
                </PfButton>
              </div>
            </div>
          </form>
          <div className="social-auth-links text-center">
            <PfButton
              block
              theme="danger"
              onClick={handleGoogleRegisterSuccess}
              loading={isGoogleAuthLoading}
              disabled={isAuthLoading }
            >
              <i className="fab fa-google mr-2" />
              {t<string>('login.button.signUp.social', {what: 'Google'})}
            </PfButton>
                              <GoogleLogin
      clientId={clientID}
      onSuccess={handleGoogleRegisterSuccess}
      onFailure={onFailure}
      cookiePolicy={"single_host_policy"}
      />
          </div>
          <Link to="/login" className="text-center">
            {t<string>('register.alreadyHave')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Register;


