import { api } from "./ConfiguracionGeneralApi"

export const MenuPerfilApi = {

  createMenuPerfil: function(data: any){
    return api.post("menuperfil", data);
  },
  getMenuPerfil: function(){
    return api.get("menuperfil");
  },

  getMenuPerfilId:function(idMenuPerfil: number)
  {
      return api.get(`menuperfil/obtener/${idMenuPerfil}`)
  },
  putMenuPerfil:function(idMenuPerfil: number, data: any)
  {
    return api.put(`menuperfil/actualiza/${idMenuPerfil}`, data)
  },
  getMenuPerfilXId:function(idperfil: number)
  {
      return api.get(`menuperfil/porperfil/${idperfil}`)
  },

  deleteMenuPerfil: function(perfilId: number, idMenuMerfil: number) {
    return api.delete(`menuperfil/eliminar/${perfilId}/${idMenuMerfil}`);
  }
  
};

export default MenuPerfilApi;