import { Button, styled } from '@mui/material';
import React, { ChangeEvent } from 'react';
import * as XLSX from 'xlsx';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
interface SheetData {
  [key: string]: any;
}

interface FileInputProps {
  onDataLoaded: (data: SheetData[]) => void;
}
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
const FileInput: React.FC<FileInputProps> = ({ onDataLoaded }) => {
  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryStr = event.target?.result;
      if (typeof binaryStr === 'string') {
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheetData: SheetData[] = XLSX.utils.sheet_to_json(sheet);

        onDataLoaded(sheetData); // Envía los datos cargados al componente padre
      }
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div>
      <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      style={{color:'white', background:'#101D61'}}
      id='upload'
    >
      Subir archivo CSV o 

      <VisuallyHiddenInput
        type="file"
        onChange={handleFileUpload} 
        multiple
      />
    </Button>
    </div>
  );
};

export default FileInput;
