import { api } from "../integradorcontabilidad/ApiConfiguracionIntegradorContabilidad"

export const ProyectoApi =  {
    getProyecto:function()
    {
        return api.get("/api/proyecto")
    },
    getProyecto1:function()
    {
        return api.get("/api/proyecto/proyectos")
    },
    getProyectoId:function(id: number)
    {
        return api.get(`/api/proyecto/${id}`)
    },
    getProyectoNumero:function(numero: string)
    {
        return api.get(`/api/proyecto/busca/${numero}`)
    },
    createProyecto: function(data: any){
        return api.post("/api/proyecto", data);
    },
    filtreProyecto: function(campoFiltrado: string, filtro: any){
        return api.post(`/api/proyecto/${campoFiltrado}/${filtro}`);
    },
    updateProyecto: function(id: number, data: any){
        return api.put(`/api/proyecto/actualizarEstado/${id}`, data);
    }
}
