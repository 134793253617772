import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import GrupoContable from './GrupoContable';





interface GrupoContableTableProps {
  data: GrupoContable[];
  columns: TableColumn<GrupoContable>[];
  customStyles: Record<string, any>;
}

const GrupoContableTable: React.FC<GrupoContableTableProps> = ({ data, columns, customStyles }) => {
  return (
    <DataTable<GrupoContable>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      customStyles={customStyles}
      noDataComponent={"No hay registros para mostrar."}
    />
  );
};

export default GrupoContableTable;