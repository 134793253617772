// courierApi.js
import axios from 'axios';

const API_BASE_URL = 'https://carga.siatigroup.com/api';

export const CourierApi = {
  Get: async (ppbNumbers: any[]) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/couriers`, {
        params: {
          ppbNumbers: ppbNumbers.join(',')
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching address data:', error);
      throw error;
    }
  }
};
