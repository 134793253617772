import React, { useEffect, useState } from "react";
import {
  IconButton,
  TableContainer,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { toast } from "react-toastify";
import DialogBox from "../../../components/DialogBox";
import EditIcon from "@mui/icons-material/EditTwoTone";
import CloseIcon from "@mui/icons-material/CloseTwoTone";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import AccionesApi from "@app/api/configurardorPrincipal/AccionesApi";
import AccionTable from "./AccionTable";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import MenuApi from "@app/api/configurardorPrincipal/MenuApi";
import MenuPerfilApi from "@app/api/configurardorPrincipal/MenuPerfilApi";
import { PerfilApi } from "@app/api/configurardorPrincipal/PerfilApi";
import { Select, MenuItem } from "@material-ui/core";
import { TableColumn } from "react-data-table-component";

interface Acciones {
  idaccion: number;
  idmenuperfil: number;
  idmenu:number;
  nombre: string;
  crear: boolean;
  leer: boolean;
  borrar: boolean;
  actualizar: boolean;
}

interface Menu {
  idmenu: number;
  nombre: string;
}
interface Perfil {
  idperfil: number;
  nombre: string;
}

interface MenuPerfil {
  idmenuperfil: number;
  idmenu: number;
  idperfil: number;
}
interface CheckboxState {
  crear: boolean;
  leer: boolean;
  borrar: boolean;
  actualizar: boolean;
  [key: string]: boolean;
}

const checkboxes = [
  { key: "crear", label: "Crear" },
  { key: "leer", label: "Leer" },
  { key: "borrar", label: "Eliminar" },
  { key: "actualizar", label: "Actualizar" },
];

const Acciones: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false); // Nueva variable de estado
  const [data, setData] = useState<Acciones[]>([]);
  const [dataMenu, setDataMenu] = useState<MenuPerfil[]>([]);
  const [editingData, setEditingData] = useState<Acciones | null>(null);
  const [
    editingDataMenuPerfil,
    setEditingDataMenuPerfil,
  ] = useState<MenuPerfil | null>(null);
  const [menuPerfiles, setMenuPerfil] = useState<MenuPerfil[]>([]);
  const [menus, setMenu] = useState<Menu[]>([]);
  const [perfiles, setPerfil] = useState<Perfil[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedPerfil, setSelectedPerfil] = useState<number | null>(null);
  const [selectedMenu, setSelectedMenu] = useState<number | null>(null);
  const [checkboxState, setCheckboxState] = useState<CheckboxState>({
    crear: false,
    leer: false,
    borrar: false,
    actualizar: false,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseGuardar = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setIsEditing(false); 
    setOpen(false);
    handleReset();
  };

  const handleReset = () => {
    setEditingData(null);
    setCheckboxState({
      crear: false,
      leer: false,
      borrar: false,
      actualizar: false,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchPerfiles = async () => {
      try {
        const perfilResponse = await PerfilApi.getPerfil();
        //console.log("esto seteamos en perfil",perfilResponse.data)
        setPerfil(perfilResponse.data);
      } catch (error) {
        //console.error("Error al obtener perfiles:", error);
      }
    };

    fetchPerfiles();
  }, []);

  const fetchData = async () => {
    try {
      const accionesResponse = await AccionesApi.getAcciones();
      setData(accionesResponse.data);

      const menuResponse = await MenuApi.menus();
      setMenu(menuResponse.data);

      const menuPerfilResponse = await MenuPerfilApi.getMenuPerfil();
      //setMenuPerfil(menuPerfilResponse.data);
      setMenuPerfil(menuPerfilResponse.data.map((item: any) => item.menu));

      //console.log("Esto seteamos en menu", menuPerfilResponse.data)
      
      menuPerfilResponse.data.forEach((item: any) => {
        //console.log("Contenido de menuffffffffffffffffffffffffffffffffffffffffffffff:", item.menu);
    });
      const perfilResponse = await PerfilApi.getPerfil();
      setPerfil(perfilResponse.data);
      setLoading(false);
    } catch (error) {
      //console.error("Error al obtener los datos:", error);
    }
  };

  const getMenu = (menuPerfilId: number) => {
    //console.log("Si entro a getMenu y mando esto: ",menuPerfilId)
    const menuperfil = menuPerfiles.find(
      (m) => m.idmenu === menuPerfilId
    );
    //console.log("Esto por Dios estoy mandando",menuperfil)
    return menuperfil ? getMenuById(menuperfil.idmenu) : "Sin menú asociado";
  };

  const getPerfil = (menuPerfilId: number) => {
    //console.log("Si entro a getPerfil y mando esto: ",menuPerfilId)
    const perfil = perfiles.find(
      (m) => m.idperfil === menuPerfilId
    );
    return perfil
      ? getPerfilById(perfil.idperfil)
      : "Sin perfil asociado";
  };

  const getMenuById = (menuId: number) => {
    //console.log("Esto tiene el valor a consultar en menuffffffffffffffffffffffffffffffffffffffffffffffffffffff", menuId)
    const menu = menus.find((m) => m.idmenu === menuId);
    return menu ? `${menu.nombre}` : "Sin menú asociado";
  };

  const getPerfilById = (perfilId: number) => {
    const perfil = perfiles.find((p) => p.idperfil === perfilId);
    //console.log("perfil", perfil);
    return perfil ? `${perfil.nombre}` : "Sin perfil asociado";
  };

  useEffect(() => {
    if (editingData) {
      setCheckboxState({
        crear: editingData.crear,
        leer: editingData.leer,
        borrar: editingData.borrar,
        actualizar: editingData.actualizar,
      });
    }
  }, [editingData]);

  const handleEdit = (row: Acciones) => {
    console.log("Editar:", row);
    setIsEditing(true);
    setEditingData(row);
    handleOpen();
    fetchData();

  };
  // Metodo handleEdit inicio
  const handleDelete = async (row1: Acciones) => {
    console.log("Editaaaaaaar perfil:", row1.idmenuperfil);

    console.log("Editaaaaaaar menu:", row1.idmenu);
    try {
      // Llamar a la API para eliminar acciones por idmenuperfil e idmenu
      await AccionesApi.deleteAccionesPorIdPerfilYIdMenu(row1.idmenuperfil, row1.idmenu);
      toast.success("Acciones eliminadas exitosamente");
      fetchData(); // Actualizar datos después de la eliminación
    } catch (error) {
      console.error("Error al eliminar acciones:", error);
      toast.error("Error al eliminar acciones. Por favor, inténtelo de nuevo.");
    }
    //onsole.log("ESTO VOY A MANDAR A ELIMINAR", row.perfil.idPerfil,row.menu.idMenuPerfil);

    //await MenuPerfilApi.deleteMenuPerfil(row.perfil.idPerfil,row.menu.idMenuPerfil);
    //toast.success("Elemento Eliminado");

    //fetchData();

    //setEditingData(row);
    // setEditingDataMenuPerfil(row1);

    //handleOpen();
  };
  
  const handleSave = async (row: MenuPerfil, row1: Acciones) => {
    console.log("Datos recibidos en handleSave - row:", row);
    console.log("Datos recibidos en handleSave - row1:", row1);
    //Validacion 1 si ya existe ese perfil y menu con permisos
    console.log("Esto es el perfil de row 1",row1.idmenuperfil);
    console.log("Editandoffffffffffffff x 22222222",isEditing);

    //if row.idmenu == validarAccionesCreadas.data
    try {
      const validarAccionesCreadas = await AccionesApi.getAccionesPorIdPerfil(row1.idmenuperfil);
      console.log("Esto tiene validarffffffffff",validarAccionesCreadas.data)
      console.log("Editandoffffffffffffff",isEditing);

      if (validarAccionesCreadas.data.some((accion: any) => accion.idmenu === row1.idmenu)) {
        // Restaurar isEditing a false
        if(!isEditing){
          toast.error("El menú ya ha sido asignado a este perfil anteriormente");

          return;
        }
        
      } 
        //console.log("Ya existen acciones creadas para este perfil con el mismo idmenu.");
        // Aquí puedes manejar la lógica adicional si ya existen acciones para este perfil con el mismo idmenu
      else {
        console.log("No existen acciones creadas para este perfil con el mismo idmenu.");
        // Aquí puedes manejar la lógica adicional si no existen acciones para este perfil con el mismo idmenu
      }
    }catch (error) {
      toast.error("El menú ya ha sido asignado a este perfil anteriormente");

      }
    
    var validacionDatos = "";
    if (row.idperfil) {
      if (row.idperfil === 0 && row.idmenu === 0) {
        validacionDatos =
          "Debe seleccionar un menú y perfil, no debe estar en blanco";
      }
      const actualizacion = data.find((element) => {
        if (element.idmenuperfil !== row.idmenuperfil) {
          validacionDatos = "Error Datos Duplicado, Valide el Catálogo";
          return true;
        }
      });

      if (validacionDatos.length === 0) {
        row1.crear = checkboxState.crear as boolean;
        row1.leer = checkboxState.leer as boolean;
        row1.borrar = checkboxState.borrar as boolean;
        row1.actualizar = checkboxState.actualizar as boolean;

        try {
          console.log("Datos enviados a AccionesApi.putAcciones:", row1);
          await AccionesApi.putAcciones(row1.idaccion, row1);
          setData((prevRow) => {
            //console.log("prevRow antes de actualizar en AccionesApi.putAcciones:", prevRow);
            return prevRow.map((u) => (u.idaccion === row1.idaccion ? row1 : u))
          });
          
          //console.log("Datos enviados a MenuPerfilApi.putMenuPerfil:", row);
          await MenuPerfilApi.putMenuPerfil(row.idmenuperfil, row);
          setDataMenu((prevRow) => {
            //console.log("prevRow antes de actualizar en MenuPerfilApi.putMenuPerfil:", prevRow);
            return prevRow.map((u) => (u.idmenu === row.idmenu ? row : u))
          });
          

          toast.success("Actualización Exitosa");
          handleReset();
          handleCloseGuardar();
        } catch (err) {
          toast.error("Error de Comunicación, Contacte a TI");
        }
      } else {
        toast.error(validacionDatos);
      }

    //console.log("Contenido de row1:", row1);

    } else {


      const creacion = data.find((element) => {
        if (element.idmenuperfil === row.idmenuperfil) {
          validacionDatos = "Error Datos Duplicado, Valide el Catálogo";
          return true;
        }
      });

      if (validacionDatos.length === 0) {
        row1.crear = checkboxState.crear as boolean;
        row1.leer = checkboxState.leer as boolean;
        row1.borrar = checkboxState.borrar as boolean;
        row1.actualizar = checkboxState.actualizar as boolean;

        try {
          const response = await AccionesApi.createAcciones(row1);
          //console.log("Esto tiene row1x100000000",row1)
          setData((prevData) => [...prevData, response.data]);
          //console.log("Esto tiene row1x100000000000000000000000000000000000000000000000000000000000000000000",row1)

          toast.success("Creación Exitosa");
          fetchData();

          handleReset();
          handleCloseGuardar();
        } catch (err) {
          toast.error("Error de Comunicación, Contacte a TI");
        }
      } else {
        toast.error(validacionDatos);
      }
    }
  };
                                   
  const columns: TableColumn<Acciones>[] = [
    {
      name: "Perfil",
      selector: (row1: { idmenuperfil: number }) => 
      {console.log("Contenido de row1fffff:", row1);
      return getPerfil(row1.idmenuperfil)}
    },
    { name: "Menú", selector: (row1: { idmenu: number }) => {
      console.log("Valor de idmenu:", row1.idmenu);
      return getMenu(row1.idmenu);
    } },//aqui tambien
    { name: "Crear", selector: (row) => (row.crear ? "Sí" : "No") },
    { name: "Leer", selector: (row) => (row.leer ? "Sí" : "No") },
    { name: "Eliminar", selector: (row) => (row.borrar ? "Sí" : "No") },
    { name: "Actualizar", selector: (row) => (row.actualizar ? "Sí" : "No") },
    {
      name: "Editar",
      cell: (row: Acciones) => (
        <div>
          <IconButton
            onClick={() => handleEdit(row)}
            style={{ color: "#212C55" }}
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
    {
      name: "Eliminar",
      cell: (row: Acciones) => (
        <div>
        <button                                     
          onClick={() => handleDelete(row)}
          className="btn btn-danger btn-sm"
        >
          <i className="fa fa-eraser"></i> Eliminar
        </button>
        </div>
      ),
    },
  ];

  const roundedCardStyle = {
    borderRadius: "10px",
    overflow: "hidden",
  };

  const titleTextStyle = {
    borderBottom: "2px solid tomato",
    paddingBottom: "10px",
    marginBottom: "15px",
  };

  return (
    <div>
      <IconButton onClick={handleOpen} style={{ color: "#212C55" }}>
        <AddIcon />
      </IconButton>

      {loading ? (
        <p>Cargando...</p>
      ) : (
        <>
          <TableContainer>
            <AccionTable
              data={data}
              columns={columns}
              customStyles={customStyles}
            />
          </TableContainer>
        </>
      )}

      <DialogBox
        open={open}
        onClose={handleClose}
        content={
          <Card style={roundedCardStyle}>
            <CardContent className="card-body">
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                style={titleTextStyle}
              >
                {editingData ? "Editar" : "Crear"} Acción <ToggleOffIcon />
              </Typography>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const row: MenuPerfil = {
                    idmenuperfil: editingDataMenuPerfil?.idmenuperfil || 0,
                    idmenu: selectedMenu || 0,
                    idperfil: editingDataMenuPerfil?.idperfil || 0,
                  };
                  //console.log("Esto tiene idmenu x2fff",row.idmenu);

                  const row1: Acciones = {
                    idaccion: editingData?.idaccion || 0,
                    nombre: formData.get("nombre") as string,
                    idmenu: selectedMenu || 0, //aqui tambien cambie
                    idmenuperfil:
                      selectedPerfil || editingData?.idmenuperfil || 0,
                    crear: checkboxState.crear,
                    leer: checkboxState.leer,
                    borrar: checkboxState.borrar,
                    actualizar: checkboxState.actualizar,
                  };
                  //console.log("Esto tiene idmenu",row1.idmenu);

                  handleSave(row, row1);
                }}
              >
                <div className="form-group" id="idmenuperfil">
                  <label>Perfiles</label>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel>Perfiles</InputLabel>
                    <Select
                      name="idperfil"
                      className="form-control"
                      value={selectedPerfil || editingData?.idmenuperfil || ""}
                      onChange={(e) => {
                        const selectedPerfilId = e.target.value as number;
                        setSelectedPerfil(selectedPerfilId);
                      }}
                    >
                      <MenuItem value="" disabled>
                        Seleccione un perfil
                      </MenuItem>
                      {perfiles.map((perfil) => (
                        <MenuItem key={perfil.idperfil} value={perfil.idperfil}>
                          {perfil.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="form-group" id="idmenu">
                  <label>Menús</label>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel>Menús</InputLabel>
                    <Select
                      name="idmenu"
                      className="form-control"
                      value={
                        selectedMenu //|| editingDataMenuPerfil?.idmenu /*  */  //Estoy seguro que es aqui
                      }
                      onChange={(e) => {
                        const selectedMenuId = e.target.value as number;
                        console.log("Valor seleccionado del menú:", selectedMenuId); // Agregar este console.log
                        setSelectedMenu(selectedMenuId);
                      }}
                    >
                      <MenuItem value="" disabled>
                        Seleccione un menú
                      </MenuItem>
                      {menus.map((menu) => (
                        <MenuItem key={menu.idmenu} value={menu.idmenu}>
                          {menu.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="row">
                  <div className="form-group custom-control custom-checkbox">
                    {checkboxes.map((checkbox) => (
                      <div key={checkbox.key} className="col-3">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          checked={checkboxState[checkbox.key]}
                          onChange={(e) =>
                            setCheckboxState({
                              ...checkboxState,
                              [checkbox.key]: e.target.checked,
                            })
                          }
                          id={checkbox.key}
                        />
                        <label
                          htmlFor={checkbox.key}
                          className="custom-control-label"
                        >
                          {checkbox.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="card-footer">
                  <ButtonGroup size="lg" className="mb-2">
                    <IconButton onClick={handleClose} style={{ color: "red" }}>
                      <CloseIcon />
                    </IconButton>
                    <span style={{ margin: "0 70px" }}>&nbsp;</span>
                    <IconButton type="submit" style={{ color: "#212C55" }}>
                      {editingData ? <SaveIcon /> : <SaveIcon />}
                    </IconButton>
                  </ButtonGroup>
                </div>
              </form>
            </CardContent>
          </Card>
        }
      />
    </div>
  );
};

const customStyles = {
  headCells: {
    style: {
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "Arial",
    },
  },
  rows: {
    style: {
      fontSize: "18px",
    },
  },
  cells: {
    style: {
      fontSize: "16px",
    },
  },
  pagination: {
    style: {
      fontSize: "14px",
    },
  },
};

export default Acciones;
