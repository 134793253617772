import React from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import EstadoIntegracionProyecto from './EstadoIntegracionProyecto';

interface EstadoIntegracionProyectoTableProps {
  data: EstadoIntegracionProyecto[];
  columns: TableColumn<EstadoIntegracionProyecto>[];
  customStyles: Record<string, any>;
}

const EstadoIntegracionProyectoTable: React.FC<
  EstadoIntegracionProyectoTableProps
> = ({data, columns, customStyles}) => {
  return (
    <DataTable<EstadoIntegracionProyecto>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      customStyles={customStyles}
      noDataComponent={"No hay registros para mostrar."}
    />
  );
};

export default EstadoIntegracionProyectoTable;
