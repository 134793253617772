import { api } from "./ApiConfiguracionIntegrador"

export const GrupoServicioContableApi = {
    getGrupoServicioContable:function()
    {
        return api.get("/api/gruposerviciocontable")
    },
    getGrupoServicioContableId:function(id: number)
    {
        return api.get(`/api/gruposerviciocontable/${id}`)
    },
    createGrupoServicioContable: function(data: any){
        return api.post("/api/gruposerviciocontable", data);
    },
    updateGrupoServicioContable: function(id: number, data: any){
        return api.put(`/api/gruposerviciocontable/${id}`, data);
    },
    deleteGrupoServicioContable: function(id: number){
        return api.delete(`/api/gruposerviciocontable/${id}`);
    }
}