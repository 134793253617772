import { api } from "./ApiConfiguracionIntegrador"

export const TipoCuentaMagayaApi =  {
    getTipoCuentaMagaya:function()
    {
        return api.get("api/tipocuentamagaya")
    },
    getTipoCuentaMagayaId:function(id: number)
    {
        return api.get(`api/tipocuentamagaya/${id}`)
    },
    createTipoCuentaMagaya: function(data: any){
        return api.post("api/tipocuentamagaya", data);
    },
    updateTipoCuentaMagaya: function(id: number, data: any){
        return api.put(`api/tipocuentamagaya/${id}`, data);
    },
    deleteTipoCuentaMagaya: function(id: number){
        return api.delete(`api/tipocuentamagaya/${id}`);
    }
}
