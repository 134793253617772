/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {ContentHeader} from '@components';
import GrupoContable from './grupoContable/GrupoContable';
import UbicacionFisica from './ubicacionFisica/UbicacionFisica';
import TipoMovimiento from './tipoMovimiento/TipoMovimiento';
import TipoIngreso from './tipoIngreso/TipoIngreso';
import TipoActivo from './tipoActivo/TipoActivo';
import NombreBien from './nombreBien/NombreBien';
import Material from './material/Material';
import Marca from './marca/Marca';
import FamiliaActivo from './familiaActivo/FamiliaActivo';
import EstadoFuncional from './estadoFuncional/EstadoFuncional';
import EstadoFisico from './estadoFisico/EstadoFisico';
import Color from './color/Color';
import NivelReprocesamiento from './nivelReprocesamiento/NivelReprocesamiento';

const Categoria = () => {
  return (
    <div>
    <ContentHeader title="" />
    <section className="content">
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Catálagos de Activos Fijos</h3>
            <div className="card-tools">
            </div>
          </div>
          <div className="card-body">
            <div className="p-tabview p-component">
              <div className="card-header p-0 pt-1">
                <ul
                  className="nav nav-tabs"
                  id="custom-tabs-one-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="custom-tabs-one-grupoContable-tab"
                      data-toggle="pill"
                      href="#custom-tabs-one-grupoContable"
                      role="tab"
                      aria-controls="custom-tabs-one-grupoContable"
                      aria-selected="true"
                      style={{color:'#101D61'}}
                    >
                      Grupo Contable
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-one-ubicacionFisica-tab"
                      data-toggle="pill"
                      href="#custom-tabs-one-ubicacionFisica"
                      role="tab"
                      aria-controls="custom-tabs-one-ubicacionFisica"
                      aria-selected="false"
                      style={{color:'#101D61'}}
                    >
                      Ubicación Física
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-one-tipoMovimiento-tab"
                      data-toggle="pill"
                      href="#custom-tabs-one-tipoMovimiento"
                      role="tab"
                      aria-controls="custom-tabs-one-tipoMovimiento"
                      aria-selected="false"
                      style={{color:'#101D61'}}
                    >
                      Tipo Movimiento
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-one-tipoIngreso-tab"
                      data-toggle="pill"
                      href="#custom-tabs-one-tipoIngreso"
                      role="tab"
                      aria-controls="custom-tabs-one-tipoIngreso"
                      aria-selected="false"
                      style={{color:'#101D61'}}
                    >
                      Tipo Ingreso
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-one-tipoActivo-tab"
                      data-toggle="pill"
                      href="#custom-tabs-one-tipoActivo"
                      role="tab"
                      aria-controls="custom-tabs-one-tipoActivo"
                      aria-selected="false"
                      style={{color:'#101D61'}}
                    >
                      Tipo Activo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-one-nombreBien-tab"
                      data-toggle="pill"
                      href="#custom-tabs-one-nombreBien"
                      role="tab"
                      aria-controls="custom-tabs-one-nombreBien"
                      aria-selected="false"
                      style={{color:'#101D61'}}
                    >
                      Nombre Bien
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-one-material-tab"
                      data-toggle="pill"
                      href="#custom-tabs-one-material"
                      role="tab"
                      aria-controls="custom-tabs-one-material"
                      aria-selected="false"
                      style={{color:'#101D61'}}
                    >
                      Material
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-one-marca-tab"
                      data-toggle="pill"
                      href="#custom-tabs-one-marca"
                      role="tab"
                      aria-controls="custom-tabs-one-marca"
                      aria-selected="false"
                      style={{color:'#101D61'}}
                    >
                      Marca
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-one-familiaActivo-tab"
                      data-toggle="pill"
                      href="#custom-tabs-one-familiaActivo"
                      role="tab"
                      aria-controls="custom-tabs-one-familiaActivo"
                      aria-selected="false"
                      style={{color:'#101D61'}}
                    >
                      Familia Activo
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-one-estadoFuncional-tab"
                      data-toggle="pill"
                      href="#custom-tabs-one-estadoFuncional"
                      role="tab"
                      aria-controls="custom-tabs-one-estadoFuncional"
                      aria-selected="false"
                      style={{color:'#101D61'}}
                    >
                      Estado Funcional
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-one-estadoFisico-tab"
                      data-toggle="pill"
                      href="#custom-tabs-one-estadoFisico"
                      role="tab"
                      aria-controls="custom-tabs-one-estadoFisico"
                      aria-selected="false"
                      style={{color:'#101D61'}}
                    >
                      Estado Fisico
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-one-color-tab"
                      data-toggle="pill"
                      href="#custom-tabs-one-color"
                      role="tab"
                      aria-controls="custom-tabs-one-color"
                      aria-selected="false"
                      style={{color:'#101D61'}}
                    >
                      Color
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="custom-tabs-one-nivel-tab"
                      data-toggle="pill"
                      href="#custom-tabs-one-nivel"
                      role="tab"
                      aria-controls="custom-tabs-one-nivel"
                      aria-selected="false"
                      style={{color:'#101D61'}}
                    >
                      Nivel de reprocesamiento
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content" id="custom-tabs-one-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="custom-tabs-one-grupoContable"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-one-grupoContable-tab"
                  >
                   <GrupoContable />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-one-ubicacionFisica"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-one-ubicacionFisica-tab"
                  >
                    <UbicacionFisica />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-one-tipoMovimiento"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-one-tipoMovimiento-tab"
                  >
                    <TipoMovimiento />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-one-tipoIngreso"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-one-tipoIngreso-tab"
                  >
                    <TipoIngreso />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-one-tipoActivo"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-one-tipoActivo-tab"
                  >
                    <TipoActivo />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-one-nombreBien"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-one-nombreBien-tab"
                  >
                    <NombreBien />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-one-material"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-one-material-tab"
                  >
                    <Material />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-one-marca"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-one-marca-tab"
                  >
                    <Marca />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-one-familiaActivo"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-one-familiaActivo-tab"
                  >
                    <FamiliaActivo />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-one-estadoFuncional"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-one-estadoFuncional-tab"
                  >
                    <EstadoFuncional />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-one-estadoFisico"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-one-estadoFisico-tab"
                  >
                    <EstadoFisico />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-one-color"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-one-color-tab"
                  >
                    <Color />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="custom-tabs-one-nivel"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-one-nivel-tab"
                  >
                    <NivelReprocesamiento />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer"></div>
        </div>
      </div>
    </section>
  </div>
  );
};

export default Categoria;
