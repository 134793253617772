import { api } from "./ApiConfiguracionEstadisticas"
export const EstadoTramiteApi =
{
    getEstadoTramite:function()
    {
        return api.get("estadotramite")
    },
    getEstadoTramiteById:function(id: number)
    {
        return api.get(`estadotramite/${id}`)
    },
    createEstadoTramite: function(data: any){
        return api.post("estadotramite", data);
    },
    updateEstadoTramite: function(id: number, data: any){
        return api.put(`estadotramite/${id}`, data);
    },
    deleteEstadoTramite: function(id: number){
        return api.delete(`estadotramite/${id}`);
    }
}
