import { api } from "./ConfiguracionGeneralApi"

export const AccionesApi = {

  createAcciones: function(data: any){
    return api.post("acciones", data);
  },
  getAcciones: function(){
    return api.get("acciones");
  },

  getAccionesId:function(idAccion: number)
  {
      return api.get(`accion/obtener/${idAccion}`)
  },
  getAccionesPorIdPerfil: function(idPerfil: number) {
    return api.get(`acciones/perfil/${idPerfil}`);
  },
  deleteAccionesPorIdPerfilYIdMenu: function(idPerfil: number, idMenu: number) {
    return api.delete(`acciones/borrar/${idPerfil}/${idMenu}`);
  },
  putAcciones:function(idAccion: number, data: any)
  {
    return api.put(`accion/actualiza/${idAccion}`, data)
  },
};

export default AccionesApi;