import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import AsignarMenu from './AsignarMenu';

interface AsignarMenuTableProps {
    data: AsignarMenu[];
    columns:  TableColumn<AsignarMenu>[];
    customStyles: Record<string, any>;
}
const AsignarMenuTable: React.FC<AsignarMenuTableProps> = ({ data, columns, customStyles }) => {
    return (
      <DataTable<AsignarMenu>
        columns={columns}
        data={data}
        pagination
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por página:',
          rangeSeparatorText: 'de'
        }}
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 20, 30]}
        customStyles={customStyles}
        noDataComponent={"No hay registros para mostrar."}
      />
    );
  };
export default AsignarMenuTable;