import { api } from "./ConfiguracionGeneralApi"

export const UsuarioApi = {
  loginByApi: async (email: string, password: string): Promise<string> => {
    try {
      const response = await api.post('usuario', {
        email,
        password,
      });
      return JSON.stringify(response.data.token); 
    } catch (error) {
      throw new Error('Credenciales inválidas. Inténtalo de nuevo.');
    }
  },
  createUsuario: function(data: any){
    return api.post("usuario/registro", data);
  },
  logUsuario: function(data: any){
    return api.post("usuario", data);
  },
  usuarioPorEmail:function(email: string)
  {
    return api.get(`usuario/buscarEmail/${email}`)
  },
  usuarioPreferredname:function(preferredname: string)
  {
    return api.get(`usuario/buscarPreferredname/${preferredname}`)
  },
  usuarioperfil: function(email: string)
  {
    return api.post(`usuario/perfil/${email}`)
  },
  usuarios:function()
  {
    return api.get('usuario/obtenerUsuarios')
  },
  getUsuarioId:function(idusuario: number)
  {
      return api.get(`usuario/${idusuario}`)
  },
 
  putUsuarios:function(idusuario: number, data: any)
  {
    return api.put(`usuario/actualizaperfil/${idusuario}`, data)
  },
  postTockenizacion:function(data: any)
  {
    return api.post(`usuario/tockenizacion/${data}`)
  },
};

export default UsuarioApi;