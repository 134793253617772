import React, { useState } from 'react';
import {
  DataGrid,
  GridLogicOperator,
  GridToolbar,
  GridColDef,
} from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, TextField, Theme } from '@mui/material';



interface DataItem {
  [key: string]: any;
}


interface TableProps<T extends DataItem> {
  data: T[];
  columns: GridColDef[];
}

const DataGridComponent = <T extends DataItem>({ data, columns }: TableProps<T>) => {
  const [filteredData, setFilteredData] = useState<T[]>(data);
  const [filterText, setFilterText] = useState('');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const handleFilterText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase().trim();
    setFilterText(value);
    filterData(value, startDate, endDate);
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setStartDate(value);
    filterData(filterText, value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEndDate(value);
    filterData(filterText, startDate, value);
  };

  const filterData = (text: string, start: string | null, end: string | null) => {
    const filteredItems = data.filter((item) => {
      const matchesText = Object.values(item).some((val) => val && val.toString().toLowerCase().includes(text));
      const dateColumn = item['fechacontable'] || item['fechareal'] || item['fecha'];
      const dateMatches =
        (!start || new Date(dateColumn) >= new Date(start)) &&
        (!end || new Date(dateColumn) <= new Date(end));
      return matchesText && dateMatches;
    });
    setFilteredData(filteredItems);
  };

  const rows = filteredData.map((item, index) => ({ id: index, ...item }));

  return (
    <div>
      <Box
        sx={{
          width: '100%',
          overflow: 'initial',
          '& .super-app-theme--header': {
            backgroundColor: 'rgba(0,73, 196, 0.21)',
          },
          '& .bg-categorizacion-column': {
            backgroundColor: '#f7f7f7',
          },
          '& .bg-ref2-column': {
            backgroundColor: 'beige',
          },
        }}>
        {/* Filtros */}
        <br />
        <br />
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Filtrar por texto"
            value={filterText}
            onChange={handleFilterText}
            variant="outlined"
            sx={{ mr: 2 }}
          />
          <TextField
            label="Fecha inicio"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={startDate || ''}
            onChange={handleStartDateChange}
            variant="outlined"
            sx={{ mr: 2 }}
          />
          <TextField
            label="Fecha fin"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={endDate || ''}
            onChange={handleEndDateChange}
            variant="outlined"
          />
        </Box>

        <DataGrid
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },

          }}
          rows={rows}
          editMode="row"
          autoHeight={true}
          columns={columns.map((column) => ({
            ...column,
            headerClassName: 'custom-header',
            resizable: true,
          }))}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            filterPanel: {
              // Force usage of "And" operator
              logicOperators: [GridLogicOperator.And],
              // Display columns by ascending alphabetical order
              columnsSort: 'asc',
              filterFormProps: {
                // Customize inputs by passing props
                logicOperatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                },
                columnInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                operatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                valueInputProps: {
                  InputComponentProps: {
                    variant: 'outlined',
                    size: 'small',
                  },
                },
                deleteIconProps: {
                  sx: {
                    '& .MuiSvgIcon-root': { color: '#d32f2f' },
                  },
                },

              },
              sx: {
                // Customize inputs using css selectors
                '& .MuiDataGrid-filterForm': { p: 2 },
                '& .MuiDataGrid-filterForm:nth-child(even)': {
                  backgroundColor: (theme: Theme) =>
                    theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                },
                '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                '& .MuiDataGrid-filterFormValueInput': { width: 200 },
              },
            },
          }}
          pageSizeOptions={[100]}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
    </div>
  );
};

export default DataGridComponent;
