import { api } from "./ConfiguracionBancoApi"
export const RegistroApi =
{
    getRegistro:function()
    {
        return api.get("registros")
    },
    getRegistro1:function()
    {
        return api.get("registros/registros")
    },
    getRegistro2:function()
    {
        return api.get("registros/actual")
    },
    getRegistroId:function(id: number)
    {
        return api.get(`registros/${id}`)
    },
    createRegistro: function(data: any){
        return api.post("registros", data);
    },
    updateRegistro: function(id: number, data: any){
        return api.put(`registros/${id}`, data);
    },
    deleteRegistro: function(id: number){
        return api.delete(`registro/${id}`);
    }
}