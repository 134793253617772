import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import NuevoProyectoMagaya from './NuevoProyectoMagaya';



interface NuevoProyectoMagayaTableProps {
  data: NuevoProyectoMagaya[];
  columns: TableColumn<NuevoProyectoMagaya>[];
  customStyles: Record<string, any>;
}

const NuevoProyectoMagayaTable: React.FC<NuevoProyectoMagayaTableProps> = ({ data, columns, customStyles }) => {
  return (
    <DataTable<NuevoProyectoMagaya>
      columns={columns}
      data={data}
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por página:',
        rangeSeparatorText: 'de'
      }}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[10, 20, 30]}
      customStyles={customStyles}
      noDataComponent={"No hay registros para mostrar."}
    />
  );
};

export default NuevoProyectoMagayaTable;