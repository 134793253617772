import { ConfiguracionApiActivosFijos } from "./ConfiguracionApiActivosFijos"

export const ColorApi =
{
    getColor:function()
    {
        return ConfiguracionApiActivosFijos.get("color")
    },
    getColorId:function(id: number)
    {
        return ConfiguracionApiActivosFijos.get(`color/${id}`)
    },
    createColor: function(data: any){
        return ConfiguracionApiActivosFijos.post("color", data);
    },
    updateColor: function(id: number, data: any){
        return ConfiguracionApiActivosFijos.put(`color/${id}`, data);
    },
    deleteColor: function(id: number){
        return ConfiguracionApiActivosFijos.delete(`color/${id}`);
    }
}