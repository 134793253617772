import { ConfiguracionApiActivosFijos } from "./ConfiguracionApiActivosFijos"

export const TipoActivoApi =
{
    getTipoActivo:function()
    {
        return ConfiguracionApiActivosFijos.get("tipoActivo")
    },
    getTipoActivoId:function(id: number)
    {
        return ConfiguracionApiActivosFijos.get(`tipoActivo/${id}`)
    },
    createTipoActivo: function(data: any){
        return ConfiguracionApiActivosFijos.post("tipoActivo", data);
    },
    updateTipoActivo: function(id: number, data: any){
        return ConfiguracionApiActivosFijos.put(`tipoActivo/${id}`, data);
    },
    deleteTipoActivo: function(id: number){
        return ConfiguracionApiActivosFijos.delete(`tipoActivo/${id}`);
    }
}