import api from "./ConfiguracionGeneralApi";

export const UsuarioPerfilApi =  {
    getUsuarioPerfilId:function(idperfilusuario: number)
    {
        return api.get(`usuariop/${idperfilusuario}`)
    },
    getUsuarioId:function(idUsuario: number)
    {
        return api.get(`usuariop/buscaUsuario/${idUsuario}`)
    },
    getUsuarioPerfil:function()
    {
        return api.get("usuariop")
    },
    getUsuarioMicrosoftId:function(idUsuariomicrosoft: number)
    {
        return api.get(`usuario/buscarusuariomicrosoft/${idUsuariomicrosoft}`)
    },
    createUsuarioPerfil: function(data: any){
        return api.post("usuariop/guardar", data);
    },
    
    updateUsuarioPerfil: function(idperfilusuario: number, data: any) {
        return api.put(`usuariop/${idperfilusuario}`, data);
    },
    
}